import {
  collection,
  doc,
  query,
  where,
  limit,
  writeBatch,
  increment,
} from 'firebase/firestore';
import React, { useState } from 'react';
import {
  useCollectionData,
  useCollectionDataOnce,
} from 'react-firebase-hooks/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Typography,
  Card,
  Checkbox,
  ListItemAvatar,
  ListItemText,
  Chip,
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { useSnackbar } from 'notistack';

import { UpdateFieldList } from 'components/UpdateFieldList';
import { useFirebase } from 'hooks/useFirebase';
import { useInviteUser } from 'hooks/useInviteUser';
import { useStore } from 'hooks/useStore';
import { setUserOrgDataAction } from 'store/actions';
import { OrganizationDocument } from 'store/models/organizations';
import { IUser } from 'store/app/state/index';
import {
  NotificationDocument,
  NOTIFICATION_TYPE,
} from 'store/models/notifications';

function AdminOrganizationDetail(): JSX.Element {
  const { dispatch } = useStore();
  const { organizationName } = useParams();
  const { db } = useFirebase();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [noOrgUserChecked, setNoOrgUserChecked] = useState<string[]>([]);
  const [noOrgUserCheckedNames, setNoOrgUserCheckedNames] = useState<string[]>(
    []
  );
  const { InviteUserButtonComponent, InviteUserDialogComponent } =
    useInviteUser();
  const [organization, organizationLoading, organizationError] =
    useCollectionDataOnce(
      query(
        collection(db, 'organizations'),
        where('name', '==', organizationName),
        limit(1)
      )
    );
  const organizationId = organization?.[0].id ?? '';

  const docRef = doc(db, `/organizations/${organizationId || null}`);
  const [
    orgData,
    orgDataLoading,
    orgDataError,
    _orgDataSnapshot,
    orgDataReload,
  ] = useDocumentDataOnce(docRef);

  const [pendingOrgUsers, pendingOrgUsersLoading, pendingOrgUsersError] =
    useCollectionData(
      query(
        collection(db, 'invites'),
        where('organization_id', '==', organizationId)
      )
    );

  const [orgUsers, orgUsersLoading, orgUsersError] = useCollectionData(
    query(
      collection(db, 'users'),
      where('organization_id', '==', organizationId)
    )
  );

  const [noOrgUsers, noOrgUsersLoading, noOrgUsersError] = useCollectionData(
    query(collection(db, 'users'), where('organization_id', '==', ''))
  );

  if (
    organizationLoading ||
    pendingOrgUsersLoading ||
    orgUsersLoading ||
    noOrgUsersLoading ||
    orgDataLoading
  ) {
    return <div>Loading...</div>;
  }
  if (
    organizationError ||
    pendingOrgUsersError ||
    orgUsersError ||
    noOrgUsersError ||
    orgDataError
  ) {
    return <div>Error!</div>;
  }

  const handleNoOrgUserToggle = (noOrgUser: IUser) => () => {
    const isIdIncluded =
      noOrgUser.id && noOrgUserChecked.includes(noOrgUser.id);

    if (isIdIncluded) {
      setNoOrgUserChecked((currentChecked) =>
        currentChecked.filter((currentId) => currentId !== noOrgUser.id)
      );
      setNoOrgUserCheckedNames(
        noOrgUserCheckedNames.filter((value) => value !== noOrgUser.name)
      );
    } else {
      setNoOrgUserChecked((currentChecked) => [
        ...currentChecked,
        noOrgUser.id ? noOrgUser.id : '',
      ]);
      setNoOrgUserCheckedNames((currentCheckedNames) => [
        ...currentCheckedNames,
        noOrgUser.name ? noOrgUser.name : '',
      ]);
    }
  };

  async function handleAddUsers(): Promise<void> {
    const batch = writeBatch(db);
    noOrgUserChecked.forEach((id, index) => {
      batch.update(doc(db, 'users', id), {
        organization_id: organizationId,
      });
      const newNotificationRef = doc(collection(db, 'notifications'));
      batch.set(newNotificationRef, {
        organization_id: organizationId,
        user_id: id,
        acknowledged_user_ids: [],
        notification_type: NOTIFICATION_TYPE.NEW_USER,
        metadata: { user_name: noOrgUserCheckedNames[index] },
        timestamp: new Date(),
        involved_user_ids: orgUsers
          ?.map((orgUser) => orgUser.id)
          .filter((orgUserId) => orgUserId !== id),
        id: newNotificationRef.id,
      } as NotificationDocument);
    });
    batch.update(docRef, {
      user_count: increment(noOrgUserChecked.length),
    });
    try {
      batch.commit();
      setNoOrgUserChecked([]);
      setNoOrgUserCheckedNames([]);
    } catch (e) {
      enqueueSnackbar('Error adding users', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      console.error('error: ', e);
    }
  }

  const handleNavigateToOrgDetail = async (): Promise<void> => {
    await dispatch(setUserOrgDataAction(orgData as OrganizationDocument));

    navigate(`/organizations/${organizationName}/details`);
  };

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: 300,
          minHeight: 350,
          maxHeight: 700,
          m: 5,
          float: 'left',
        }}
      >
        <Typography sx={{ textAlign: 'center', my: 2 }}>Users</Typography>
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          <List>
            {pendingOrgUsers?.map((pendingUser) => {
              return (
                <ListItem key={pendingUser.email}>
                  <ListItemAvatar>
                    <Avatar />
                  </ListItemAvatar>
                  <ListItemText
                    primary={pendingUser.name || pendingUser.email}
                  />
                  <Chip
                    label='Invited'
                    color='success'
                    variant='outlined'
                    sx={{ mt: 0.5 }}
                  />
                </ListItem>
              );
            })}
            {orgUsers?.map((orgUser) => {
              return (
                <ListItem key={orgUser.id}>
                  <ListItemAvatar>
                    <Avatar src={orgUser.avatarSrc} />
                  </ListItemAvatar>
                  <ListItemText primary={orgUser.name || orgUser.email} />
                </ListItem>
              );
            })}
          </List>
        </Box>
        <Box sx={{ display: 'flex', m: 2, justifyContent: 'flex-end' }}>
          <InviteUserButtonComponent />
        </Box>
      </Card>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: 300,
          minHeight: 350,
          maxHeight: 700,
          m: 5,
          float: 'left',
        }}
      >
        <Typography sx={{ textAlign: 'center', my: 2 }}>Add Users</Typography>
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {noOrgUsers?.map((noOrgUser) => {
              return (
                <ListItem
                  key={noOrgUser.id}
                  secondaryAction={
                    <Checkbox
                      edge='end'
                      onChange={handleNoOrgUserToggle(noOrgUser as IUser)}
                      checked={noOrgUserChecked.indexOf(noOrgUser.id) !== -1}
                    />
                  }
                >
                  <ListItemAvatar>
                    <Avatar src={noOrgUser.avatarSrc} />
                  </ListItemAvatar>
                  <ListItemText primary={noOrgUser.name || noOrgUser.email} />
                </ListItem>
              );
            })}
          </List>
        </Box>
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
          <Button
            sx={{ alignSelf: 'end', m: 2 }}
            variant='contained'
            onClick={handleAddUsers}
          >
            Add
          </Button>
        </Box>
      </Card>
      <UpdateFieldList
        orgData={orgData as OrganizationDocument}
        isWithDelete={true}
        field={'categories'}
        onReload={orgDataReload}
      />
      <Button
        onClick={handleNavigateToOrgDetail}
        variant='contained'
        sx={{ mt: 5, ml: 5 }}
      >
        Go To Organization Detail Page
      </Button>
      <InviteUserDialogComponent
        organization={organization?.[0] as OrganizationDocument}
      />
    </>
  );
}

export { AdminOrganizationDetail };
