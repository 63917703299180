import { CircularProgress, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { collection, doc, setDoc, Timestamp } from 'firebase/firestore';
import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { useAppBarHeight } from 'hooks/useAppBarHeight';
import { useFirebase } from 'hooks/useFirebase';
import { useStore } from 'hooks/useStore';
import { PendingVideoDocument, TRANSITION_STATE } from 'store/models/videos';

function CreatePendingVideo(): JSX.Element {
  const {
    state: { user, organization },
  } = useStore();
  const { db } = useFirebase();
  const { enqueueSnackbar } = useSnackbar();
  const { appBarHeight } = useAppBarHeight();
  const navigate = useNavigate();

  const collectionRef = collection(db, 'pending-videos');
  const docRef = doc(collectionRef);

  useEffect(() => {
    const instantiateDoc = async (): Promise<void> => {
      try {
        const payload: PendingVideoDocument = {
          id: docRef.id,
          organization_id: organization.id as string,
          status: TRANSITION_STATE.PREPARING,
          storage_paths: [],
          user_id: user.id as string,
          videos: {},
          retry_count: 0,
          created_at: Timestamp.now(),
          surgeons: [],
          categories: [],
        };
        await setDoc(docRef, payload);

        navigate(`/pending-videos/${docRef.id}/upload`);
      } catch (e) {
        enqueueSnackbar('Unable to instantiate container for pending videos', {
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        console.log('Unable to instantiate container for pending videos: ', e);
      }
    };
    instantiateDoc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const containerSx = {
    display: 'flex',
    width: '100%',
    height: `calc(100vh - ${appBarHeight}px)`,
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <Container sx={containerSx}>
      <CircularProgress size={75} variant='indeterminate' />
    </Container>
  );
}

export { CreatePendingVideo };
