type DefaultBreakPoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type IBreakPoints = { [key in DefaultBreakPoints]: any };
export const DRAWER: IBreakPoints = {
  xs: '0%',
  sm: '10%',
  md: '30%',
  lg: '20%',
  xl: '500',
};
// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1200px
// xl, extra-large: 1536px
export const NOT_DRAWER: IBreakPoints = {
  xs: `calc(100% - ${DRAWER.xs})px`,
  sm: `calc(100% - ${DRAWER.sm})px`,
  md: `calc(100% - ${DRAWER.md})px`,
  lg: `calc(100% - ${DRAWER.xs})px`,
  xl: `calc(100% - ${DRAWER.xl})px`,
};

export const BACKGROUND_COLOR_GREY = '#EEEEEE';
