import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { IUser } from 'store/app/state/index';
import { BACKGROUND_COLOR_GREY } from 'constants/styles';

interface IUserState {
  orgUser: IUser;
}

function UserDetail(): JSX.Element {
  const location = useLocation();

  const userState = location.state as IUserState;
  const user = userState.orgUser;

  const SubHeader = (
    <>
      <Typography>{user.email}</Typography>
      {user.is_admin && (
        <Chip
          label='admin'
          color='success'
          variant='outlined'
          sx={{ mt: 0.5 }}
        />
      )}
    </>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        background: BACKGROUND_COLOR_GREY,
        width: '100%',
        height: '100%',
      }}
    >
      <Card sx={{ width: 500, height: 'fit-content', mt: 2 }}>
        <CardHeader
          sx={{ pt: 3, pb: 0 }}
          avatar={user.avatarSrc ? <Avatar src={user.avatarSrc} /> : <Avatar />}
          title={<Typography>{user.name}</Typography>}
          subheader={SubHeader}
        />
        <CardContent>
          <Box component='form' noValidate autoComplete='off'>
            <TextField
              InputProps={{
                readOnly: true,
              }}
              label='Department'
              value={user.department}
              margin='normal'
              fullWidth
            />
            <br />
            <TextField
              InputProps={{
                readOnly: true,
              }}
              label='Occupation'
              value={user.occupation}
              margin='normal'
              fullWidth
            />
            <br />
            <TextField
              InputProps={{
                readOnly: true,
              }}
              label='Phone Number'
              value={user.phoneNumber}
              margin='normal'
              fullWidth
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export { UserDetail };
