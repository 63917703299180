import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  DocumentData,
  DocumentReference,
  getDocs,
  query,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useSnackbar } from 'notistack';

import { useFirebase } from 'hooks/useFirebase';
import { VideoDocument } from 'store/models/videos';
import { OrganizationDocument } from 'store/models/organizations';

interface UpdateFieldListDocument {
  // the organization data being used
  orgData: OrganizationDocument;
  // true if there should be a button to delete values
  // only true with categories field
  isWithDelete: boolean;
  // firestore video document to be updated
  video?: VideoDocument;
  // reference to firestore video document
  videoRef?: DocumentReference<DocumentData>;
  // which field to update
  field: 'categories' | 'surgeons';
  // function to reload orgData
  onReload: () => Promise<void>;
}
function UpdateFieldList({
  orgData,
  video,
  videoRef,
  isWithDelete,
  field,
  onReload,
}: UpdateFieldListDocument): JSX.Element {
  const { db } = useFirebase();
  const [listChecked, setListChecked] = useState<string[]>([]);
  const [filterText, setFilterText] = useState('');
  const [listItemText, setListItemText] = useState('');
  const [isAddListItemOpen, setIsAddListItemOpen] = useState(false);

  const [orgUsers] = useCollectionData(
    query(collection(db, 'users'), where('organization_id', '==', orgData.id))
  );

  const { enqueueSnackbar } = useSnackbar();

  const orgUserNames = orgUsers?.map((user) => user.name);
  const docRef = doc(db, `/organizations/${orgData.id || null}`);
  const filteredList =
    field === 'categories'
      ? orgData[field]?.filter((fieldValue: string) =>
          fieldValue.includes(filterText)
        )
      : orgUserNames?.filter((fieldValue: string) =>
          fieldValue.includes(filterText)
        );

  useEffect(() => {
    if (video) setListChecked(video.metadata[field]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video]);

  const handleFilterTextChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFilterText(e.target.value);
  };

  const handleListToggle = (id: string) => async () => {
    if (listChecked.includes(id)) {
      if (videoRef) {
        await updateDoc(videoRef, {
          [`metadata.${field}`]: arrayRemove(id),
        });
        enqueueSnackbar(`${field} removed`, {
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      }
      setListChecked((currentChecked) =>
        currentChecked.filter((currentId) => currentId !== id)
      );
    } else {
      if (videoRef) {
        await updateDoc(videoRef, {
          [`metadata.${field}`]: arrayUnion(id),
        });
        enqueueSnackbar(`${field} updated`, {
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      }
      setListChecked((currentChecked) => [...currentChecked, id]);
    }
  };

  const handleOpenAddListItem = (): void => {
    setIsAddListItemOpen(true);
  };

  const handleAddListItemAndClose = (): void => {
    if (docRef) {
      updateDoc(docRef, {
        [field]: arrayUnion(listItemText),
      });
      onReload();
    }
    if (videoRef)
      updateDoc(videoRef, {
        [`metadata.${field}`]: arrayUnion(listItemText),
      });
    if (!isWithDelete) setListChecked([...listChecked, listItemText]);
    setListItemText('');
    setIsAddListItemOpen(false);
  };

  const handleDeleteFromList = (): void => {
    listChecked.forEach(async (listItem) => {
      const batch = writeBatch(db);
      const querySnapshot = await getDocs(
        query(
          collection(db, 'videos'),
          where('organization_id', '==', orgData.id),
          where('metadata.categories', 'array-contains', listItem)
        )
      );
      querySnapshot.forEach((document) => {
        batch.update(doc(db, `/videos/${document.data().id}`), {
          [`metadata.${field}`]: arrayRemove(listItem),
        });
      });
      if (docRef)
        batch.update(docRef, {
          [field]: arrayRemove(listItem),
        });
      await batch.commit();
      onReload();
    });
    setListChecked([]);
  };

  const handleCloseAddListItem = (): void => {
    setIsAddListItemOpen(false);
    setListItemText('');
  };

  const handleListItemTextChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setListItemText(e.target.value);
  };

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: 300,
          minHeight: 350,
          maxHeight: 700,
          m: 5,
          float: 'left',
        }}
      >
        <Typography sx={{ textAlign: 'center', my: 2 }}>
          {field.charAt(0).toUpperCase() + field.slice(1)}
        </Typography>
        <Box sx={{ overflow: 'auto' }}>
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              ml: 2,
            }}
          >
            <TextField
              placeholder='Filter'
              value={filterText}
              onChange={handleFilterTextChange}
            />
            <Button
              sx={{ alignSelf: 'center', mx: 2 }}
              variant='contained'
              onClick={handleOpenAddListItem}
            >
              Create
            </Button>
          </Box>
          <List>
            {filteredList?.sort().map((listItem: string, index: number) => {
              return (
                <ListItem
                  key={index}
                  secondaryAction={
                    <Checkbox
                      edge='end'
                      onChange={handleListToggle(listItem)}
                      checked={listChecked?.indexOf(listItem) !== -1}
                    />
                  }
                >
                  <ListItemText primary={listItem} />
                </ListItem>
              );
            })}
          </List>
        </Box>
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
          {isWithDelete && (
            <Button
              sx={{ alignSelf: 'end', m: 2 }}
              variant='contained'
              onClick={handleDeleteFromList}
            >
              Delete
            </Button>
          )}
        </Box>
      </Card>
      <Dialog open={isAddListItemOpen} onClose={handleCloseAddListItem}>
        <DialogTitle>{`Create ${
          field.charAt(0).toUpperCase() + field.slice(1)
        }`}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            label={`Enter ${field.charAt(0).toUpperCase() + field.slice(1)}`}
            fullWidth
            variant='standard'
            value={listItemText}
            onChange={handleListItemTextChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddListItem}>Cancel</Button>
          <Button
            onClick={handleAddListItemAndClose}
            disabled={listItemText === ''}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export { UpdateFieldList };
