import styled from '@emotion/styled';
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { useStore } from 'hooks/useStore';
import { useFirebase } from 'hooks/useFirebase';

const LoginPageContainer = styled.div`
  width: 100%;
`;

function LoginPage(): JSX.Element {
  const {
    dispatch,
    state: { authenticated },
  } = useStore();
  const { auth } = useFirebase();

  useEffect(() => {
    // Firebase log-in widget
    const ui =
      firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
    ui.start('#firebaseui-auth-container', {
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ],
    });
  }, [auth, dispatch]);

  if (authenticated) {
    return <Navigate replace to='/' />;
  }

  return <LoginPageContainer id='firebaseui-auth-container' />;
}

export { LoginPage };
