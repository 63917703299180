import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import DownloadIcon from '@mui/icons-material/Download';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import {
  Avatar,
  Box,
  ButtonBase,
  IconButton,
  MenuItem,
  Stack,
  Typography,
  Popper,
  ClickAwayListener,
  Paper,
  MenuList,
  Grow,
  Slider,
} from '@mui/material';

import React, {
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactPlayer from 'react-player';

import { VideoDocument, ClipDocument } from 'store/models/videos';
import { formatSeconds } from 'store/shared/formatSeconds';
import { Thumbnail } from 'pages/Video/Thumbnail';
import { usePlayer } from 'hooks/usePlayer';

interface VideoPlayerType {
  bookmarks?: { comment: string; timestamp: number; userName: string }[];
  clip?: ClipDocument;
  initialTime?: number;
  onWatchFullVideo: () => void;
  previewThumbnails: string[];
  url: string;
  video: VideoDocument;
}

const PLAYBACK_RATES = [0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2];

// 0.5 = 12px;
const PADDING_LEFT = 12;

const sliderSx = {
  color: 'white',
  width: 1,
  height: '2px',
  '&:hover, &.Mui-focusVisible': {
    height: '3px',
  },
  '& .MuiSlider-thumb': {
    width: 10,
    height: 10,
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
    color: 'white',
    '&:before': {
      boxShadow: 'none',
      // '0 2px 12px 0 rgba(0,0,0,0.4)',
    },
    '&:hover, &.Mui-focusVisible': {
      boxShadow: 'none',
      // `0px 0px 0px 6px ${
      //   theme.palette.mode === 'dark'
      //     ? 'rgb(255 255 255 / 50%)'
      //     : 'rgb(0 0 0 / 16%)'
      // }`,
    },
    '&.Mui-active': {
      width: '16px',
      height: '16px',
    },
  },
  '& .MuiSlider-rail': {
    opacity: 0.28,
  },
};

function VideoPlayer({
  bookmarks = [],
  clip,
  initialTime,
  onWatchFullVideo,
  previewThumbnails = [],
  url,
  video,
}: VideoPlayerType): JSX.Element {
  const playerContext = usePlayer();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isPbMenuOpen, setIsPbMenuOpen] = useState(false);
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [progressHover, setProgressHover] = useState({
    isHovering: false,
    left: NaN,
    seconds: NaN,
  });
  const [bookmarkHover, setBookmarkHover] = useState({
    selectedTimestamp: NaN,
    hoveredTimestamp: NaN,
    isHovering: false,
  });
  const anchorRef = useRef<HTMLElement>(null);
  const containerRef = useRef<HTMLElement>(null);
  const playerRef = useRef<ReactPlayer>(null);
  const progressRef = useRef<any>(null);

  /** once media loaded, seek to initial timestamp */
  useEffect(() => {
    playerContext.isMediaLoaded &&
      initialTime &&
      playerContext.handleSeekCallback(initialTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerContext.isMediaLoaded]);

  /** setting playerRef into context */
  useEffect(() => {
    playerRef.current && playerContext.setPlayerRef(playerRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerRef.current]);

  /** pre-seeking into queried timestamp */
  useEffect(() => {
    if (clip && clip.metadata.startTime > 0 && playerContext.isMediaLoaded) {
      playerRef.current?.seekTo(clip.metadata.startTime);
    }
  }, [clip, playerContext.isMediaLoaded]);

  /** bookmarks information */
  const bookmarksHash = useMemo(() => {
    const hash = {} as { [seconds: number]: string };
    bookmarks.forEach((bookmark) => {
      const seconds = Math.round(bookmark.timestamp);
      hash[seconds] = bookmark.comment;
    });
    return hash;
  }, [bookmarks]);

  /** if seeked to bookmark, set bookmark state */
  useEffect(() => {
    const selectedSeconds = Math.round(playerContext.playerTime);
    if (bookmarksHash[selectedSeconds]) {
      setBookmarkHover({
        ...bookmarkHover,
        selectedTimestamp: selectedSeconds,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerContext.playerTime]);

  if (!url) {
    return <>Loading...</>;
  }
  // impossible situation
  if (!video.metadata?.duration) {
    return <></>;
  }

  const handleToggleFullScreen = (): void => {
    if (containerRef.current) {
      playerContext.handleToggleFullScreen(containerRef.current);
    }
  };

  const handleClickAway = (): void => {
    setIsMenuExpanded(false);
  };

  const handleExpandMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    setIsMenuExpanded(true);
  };

  const handleDownload = (): void => {
    setIsMenuExpanded(false);
    setAnchorEl(null);
  };

  const handlePlaybackMenu = (): void => {
    setIsPbMenuOpen(!isPbMenuOpen);
  };

  const hoverTimestamp =
    bookmarkHover.hoveredTimestamp || bookmarkHover.selectedTimestamp;

  const startTime = clip
    ? playerContext.playerTime - clip.metadata.startTime
    : playerContext.playerTime;

  const endTime = clip
    ? clip.metadata.endTime - clip.metadata.startTime
    : Number(video.metadata.duration);

  const timeDisplay = `${formatSeconds(startTime.toString())} / ${formatSeconds(
    endTime.toString()
  )}`;
  const hoverDisplay = formatSeconds(
    `${progressHover.seconds - (clip ? clip.metadata.startTime : 0)}`
  );
  const progressValue = clip
    ? (startTime / endTime) * 100
    : ((playerContext.playerTime || 0) / Number(video.metadata.duration)) * 100;

  const handleProgressClick = (event: React.MouseEvent<HTMLElement>): void => {
    const rects = progressRef.current.getClientRects()[0];
    const offset = rects.left + PADDING_LEFT;
    const cursorPosition = event.clientX;
    const ratio = (cursorPosition - offset) / (rects.width - PADDING_LEFT * 2);

    /** if clip mode, then need to proportionally seek to correct location */
    const newSeconds = clip
      ? clip.metadata.startTime +
        (clip.metadata.endTime - clip.metadata.startTime) * ratio
      : ratio * endTime;

    playerRef.current?.seekTo(newSeconds);
  };

  const handleProgressChange = (
    _event: Event,
    newValue: number | number[]
  ): void => {
    const ratio = (newValue as number) / 100;
    const newSeconds = clip
      ? clip.metadata.startTime +
        (clip.metadata.endTime - clip.metadata.startTime) * ratio
      : ratio * endTime;
    playerContext.setIsSeeking(true);
    playerContext.setPlayerTime(newSeconds);
  };

  const handleProgressCommitted = (
    _event: Event | SyntheticEvent<Element, Event>,
    _newValue: number | number[]
  ): void => {
    playerContext.playerRef?.seekTo(playerContext.playerTime);
    playerContext.setIsSeeking(false);
  };

  const handleProgressMove = (event: React.MouseEvent<HTMLElement>): void => {
    if (!progressRef.current) {
      return;
    }
    const rects = progressRef.current.getClientRects()[0];
    const offset = rects.left + PADDING_LEFT;
    const cursorPosition = event.clientX;
    const ratio = (cursorPosition - offset) / (rects.width - PADDING_LEFT * 2);

    /** if clip mode, then need to proportionally seek to correct location */
    const newSeconds = clip
      ? clip.metadata.startTime +
        (clip.metadata.endTime - clip.metadata.startTime) * ratio
      : ratio * endTime;

    const THUMBNAIL_SIZE = 150;
    const SLIDER_PADDING = 10;
    /** left/right bounds, otherwise image goes off video screen */
    let left = cursorPosition - rects.left - THUMBNAIL_SIZE / 2;
    left = Math.max(left, SLIDER_PADDING);
    left = Math.min(
      left,
      rects.width - PADDING_LEFT * 2 - THUMBNAIL_SIZE + SLIDER_PADDING
    );

    setProgressHover({
      isHovering: true,
      left,
      seconds: Math.min(
        Math.max(newSeconds, 0),
        Number(video.metadata?.duration)
      ), // don't want to overcross bounds
    });
    // playerRef.current?.seekTo(newSeconds);
  };

  const handleProgressLeave = (): void => {
    setProgressHover({ ...progressHover, isHovering: false });
  };

  const handleExitCaption = (): void => {
    setBookmarkHover({ ...bookmarkHover, selectedTimestamp: NaN });
  };

  const previewThumbnailSrc =
    previewThumbnails.length > 1
      ? previewThumbnails[Math.floor(progressHover.seconds / 30)]
      : '';

  const shouldShowCaptions = Boolean(
    bookmarksHash[hoverTimestamp] &&
      (bookmarkHover.selectedTimestamp || bookmarkHover.isHovering)
  );

  const marks = bookmarks
    .filter((bookmark) => {
      if (!clip) {
        return true;
      }
      const isWithinLeftBounds = bookmark.timestamp >= clip.metadata.startTime;
      const isWithinRightBounds = bookmark.timestamp <= clip.metadata.endTime;

      return isWithinLeftBounds && isWithinRightBounds;
    })
    .map((bookmark) => {
      const handleBookmarkEnter = (): void => {
        setBookmarkHover({
          ...bookmarkHover,
          hoveredTimestamp: Math.round(bookmark.timestamp),
          isHovering: true,
        });
      };
      const handleBookmarkLeave = (): void => {
        setBookmarkHover({
          ...bookmarkHover,
          hoveredTimestamp: NaN,
          isHovering: false,
        });
      };
      const handleBookmarkClick = (
        event: React.MouseEvent<HTMLElement>
      ): void => {
        event.stopPropagation();
        setBookmarkHover({
          ...bookmarkHover,
          selectedTimestamp: Math.round(bookmark.timestamp),
        });
        playerContext.handleSeekCallback(bookmark.timestamp);
      };
      const avatarSize =
        bookmarksHash[hoverTimestamp] === bookmark.comment ? 13 : 11;

      const value =
        ((bookmark.timestamp - (clip ? clip.metadata.startTime : 0)) /
          endTime) *
        100;

      return {
        value,
        label: (
          <ButtonBase
            disableRipple
            sx={{
              width: 20,
              height: 20,
              bottom: 12,
              position: 'relative',
            }}
            onMouseEnter={handleBookmarkEnter}
            onMouseLeave={handleBookmarkLeave}
            onClick={handleBookmarkClick}
          >
            <Avatar
              sx={{
                width: avatarSize,
                height: avatarSize,
                backgroundColor: 'white',
                border: `2px solid rgb(255 255 255)`,
              }}
            >
              <Typography sx={{ color: 'black', fontSize: 8 }}>
                {bookmark.userName.substr(0, 1)}
              </Typography>
            </Avatar>
          </ButtonBase>
        ),
      };
    });

  return (
    <Box
      sx={{
        position: 'relative',
        height: 'fit-content',
        display: 'flex',
      }}
      ref={containerRef}
    >
      <ReactPlayer
        className='react-player'
        height='auto'
        playing={playerContext.isPlaying}
        onPlay={playerContext.handlePlayPauseCallback}
        onPause={playerContext.handlePlayPauseCallback}
        onProgress={playerContext.handleProgressCallback}
        onReady={playerContext.handleReadyCallback}
        // onSeek={playerContext.handleSeekCallback}
        playbackRate={playerContext.playbackRate}
        progressInterval={500}
        ref={playerRef}
        url={url}
        width='100%'
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        {/* captions */}
        {shouldShowCaptions && (
          <Stack
            direction='row'
            sx={{
              position: 'absolute',
              bottom: 80,
              flex: 1,
              pl: 4,
              pr: 4,
              width: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              opacity: 0.55,
              borderRadius: '2px',
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <Typography
                sx={{
                  pl: 1,
                  pr: 1,
                  mt: 1,
                  ml: 1,
                  mr: 1,
                  backgroundColor: 'black',
                  border: '1px solid white',
                  textOverflow: 'ellipsis',
                  color: 'white',
                  textShadow: 'black 1px 1px 1px',
                }}
                dangerouslySetInnerHTML={{
                  __html: bookmarksHash[hoverTimestamp],
                }}
              />
              <IconButton
                onClick={handleExitCaption}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  color: 'white',
                  backgroundColor: 'rgba(0, 0, 0, 1)',
                  width: 20,
                  height: 20,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 1)',
                  },
                }}
              >
                <HighlightOffIcon fontSize='small' />
              </IconButton>
            </Box>
          </Stack>
        )}
        <Stack
          direction='column'
          sx={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
          }}
        >
          {/* player controls */}
          <Stack direction='row' justifyContent='space-between'>
            {/* video controls */}
            <Stack direction='row' spacing={1}>
              <IconButton
                aria-label='replay'
                size='medium'
                sx={{ color: 'white' }}
                onClick={playerContext.handlePlayPause}
              >
                {playerContext.isPlaying ? (
                  <PauseIcon fontSize='inherit' />
                ) : (
                  <PlayArrowIcon fontSize='inherit' />
                )}
              </IconButton>

              {clip && (
                <IconButton
                  aria-label='replay'
                  size='small'
                  sx={{ color: 'white', pointerEvents: 'none' }}
                >
                  <ContentCutIcon fontSize='inherit' />
                </IconButton>
              )}
              <Typography
                variant='subtitle2'
                sx={{
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {timeDisplay}
              </Typography>
              {clip && (
                <ButtonBase
                  disableRipple
                  sx={{ pr: 1 }}
                  onClick={onWatchFullVideo}
                >
                  <Typography
                    variant='subtitle2'
                    sx={{
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: 'strong',
                    }}
                  >
                    Watch full video
                  </Typography>
                </ButtonBase>
              )}
            </Stack>

            {/* CTA items */}
            <Stack direction='row' spacing={0} sx={{ mr: 0.75 }}>
              <IconButton
                aria-label='replay'
                size='medium'
                sx={{ color: 'white' }}
                onClick={handleToggleFullScreen}
              >
                {playerContext.isFullScreen ? (
                  <FullscreenExitIcon fontSize='inherit' />
                ) : (
                  <FullscreenIcon fontSize='inherit' />
                )}
              </IconButton>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <IconButton
                aria-label='expand-menu-button'
                aria-controls={isMenuExpanded ? 'expanded-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={isMenuExpanded ? 'true' : undefined}
                ref={anchorRef}
                size='medium'
                sx={{ color: 'white' }}
                onClick={handleExpandMenu}
              >
                <MoreVertIcon fontSize='inherit' />
              </IconButton>

              <Popper
                open={isMenuExpanded}
                anchorEl={anchorRef.current}
                role={undefined}
                placement='top-end'
                transition
                disablePortal
              >
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: 'right bottom',
                    }}
                  >
                    <Paper sx={{ borderRadius: 0 }}>
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <MenuList
                          autoFocusItem={Boolean(anchorEl)}
                          id='expanded-menu'
                          aria-labelledby='expand-menu-button'
                          sx={{
                            pt: 0.5,
                            pb: 0.5,

                            maxHeight: '250px',
                            overflow: 'scroll',
                          }}
                        >
                          {!isPbMenuOpen && (
                            <MenuItem onClick={handleDownload}>
                              <Stack
                                direction='row'
                                spacing={2}
                                alignItems='center'
                                justifyContent='center'
                              >
                                <DownloadIcon />
                                <Typography variant='body2'>
                                  Download
                                </Typography>
                              </Stack>
                            </MenuItem>
                          )}
                          {!isPbMenuOpen && (
                            <MenuItem onClick={handlePlaybackMenu}>
                              <Stack
                                direction='row'
                                spacing={2}
                                alignItems='center'
                                justifyContent='center'
                              >
                                <SlowMotionVideoIcon />
                                <Typography variant='body2'>
                                  Playback Speed
                                </Typography>
                              </Stack>
                            </MenuItem>
                          )}
                          {isPbMenuOpen && (
                            <MenuItem onClick={handlePlaybackMenu}>
                              <Stack
                                direction='row'
                                alignItems='center'
                                justifyContent='center'
                                spacing={1}
                                sx={{ width: '100%' }}
                              >
                                <ArrowBackIcon fontSize='small' />
                                <Box sx={{ flex: 1 }}>
                                  <Typography variant='body2'>
                                    Options
                                  </Typography>
                                </Box>
                                <Box sx={{ width: '20px' }} />
                              </Stack>
                            </MenuItem>
                          )}
                          {isPbMenuOpen &&
                            PLAYBACK_RATES.map((playbackRate, key) => {
                              const rateText =
                                playbackRate === 1.0
                                  ? 'Normal'
                                  : playbackRate.toString();
                              const onClick = (): void => {
                                setAnchorEl(null);
                                playerContext.setPlaybackRate(playbackRate);
                                /** timing issue */
                                setTimeout(() => setIsPbMenuOpen(false), 100);
                              };

                              return (
                                <MenuItem
                                  onClick={onClick}
                                  key={key}
                                  sx={{ width: '150px', pt: 1, pb: 1 }}
                                >
                                  <Stack
                                    direction='row'
                                    alignItems='center'
                                    justifyContent='center'
                                    spacing={1}
                                    sx={{ width: '100%' }}
                                  >
                                    <Box sx={{ width: '20px' }} />

                                    <Box sx={{ flex: 1 }}>
                                      <Typography variant='body2'>
                                        {rateText}
                                      </Typography>
                                    </Box>

                                    <Stack
                                      sx={{ width: '20px' }}
                                      alignItems='center'
                                      justifyContent='center'
                                    >
                                      {playerContext.playbackRate ===
                                        playbackRate && (
                                        <CheckIcon
                                          fontSize='small'
                                          sx={{ color: 'blue' }}
                                        />
                                      )}
                                    </Stack>
                                  </Stack>
                                </MenuItem>
                              );
                            })}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Stack>
          </Stack>

          {/* progress bar */}
          <Stack
            sx={{
              pl: 1.5,
              pr: 1.5,
              display: 'relative',
              justifyContent: 'flex-start',
              width: '100%',
              cursor: 'pointer',
              height: 45,
            }}
            ref={progressRef}
            alignItems='center'
            justifyContent='center'
            onClick={handleProgressClick}
          >
            {/* thumbnail preview */}
            {!isNaN(progressHover.seconds) && progressHover.isHovering && (
              <Box
                sx={{
                  position: 'absolute',
                  top: -90,
                  left: progressHover.left,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    border: '2px solid white',
                    borderRadius: 1,
                  }}
                >
                  <Thumbnail urlPath={previewThumbnailSrc} width={150} />
                </Box>
                <Typography
                  sx={{
                    // backgroundColor: 'white',
                    color: 'white',
                    textAlign: 'center',
                    textShadow: 'black 1px 1px 1px',
                  }}
                >
                  {hoverDisplay}
                </Typography>
              </Box>
            )}
            <Slider
              value={progressValue}
              onChange={handleProgressChange}
              onChangeCommitted={handleProgressCommitted}
              onMouseMove={handleProgressMove}
              onMouseLeave={handleProgressLeave}
              marks={marks}
              sx={sliderSx}
            />
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

export { VideoPlayer };
