import { IAction } from 'store/shared/types';
import { IUser } from 'store/app/state';
import { OrganizationDocument } from 'store/models/organizations';
import {
  SET_AUTH,
  SET_USER_DATA,
  LOGOUT,
  SET_USER_ORGANIZATION,
} from 'constants/actions';

export const setAuthAction = (payload: boolean): IAction => ({
  type: SET_AUTH,
  payload,
});

export const setUserDataAction = (payload: Partial<IUser>): IAction => ({
  type: SET_USER_DATA,
  payload,
});

export const setUserOrgDataAction = (
  payload: OrganizationDocument
): IAction => ({
  type: SET_USER_ORGANIZATION,
  payload,
});

export const logoutAction = (): IAction => ({
  type: LOGOUT,
});
