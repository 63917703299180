import Forward10Icon from '@mui/icons-material/Forward10';
import Replay10Icon from '@mui/icons-material/Replay10';
import {
  Box,
  IconButton,
  Slider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';

import { useThumbnail } from 'hooks/useThumbnail';
import { formatSeconds } from 'store/shared/formatSeconds';
import { usePlayer } from 'hooks/usePlayer';

const MIN_RANGE = 10;
const MAX_RANGE = 60;
const TIME_BUFFER = 1;
const THUMBNAIL_HEIGHT_PX = 55;
const THUMBNAIL_WIDTH_PX = 90;
const THUMBNAIL_MS = 30;

interface SandReelType {
  start: number;
  end: number;
  videoDuration: number;
  previewThumbnails: string[];
  videoTimestamp: number;
}

function SandReel({
  start,
  end,
  previewThumbnails,
  videoDuration,
  videoTimestamp,
}: SandReelType): JSX.Element {
  const containerRef = useRef<HTMLElement>(null);
  const markerRef = useRef<HTMLElement>(null);

  /**
   * for darkening/lighteninig the clip
   * need to handle 3 situations
   * moment when the clip enters the start variable
   * moment when the clip ends at the end variable
   *
   * first i can cover all the tiles that are invovled
   */

  const startClip = start / THUMBNAIL_MS;
  const startClipIndex = Math.floor(startClip);
  const startClipIndexRatio = startClip - Math.floor(startClip);
  const startClipTranslateX = startClipIndexRatio * THUMBNAIL_WIDTH_PX;
  const endClip = end / THUMBNAIL_MS;
  const endClipIndex = Math.floor(endClip);
  const endClipIndexRatio = endClip - Math.floor(endClip);
  const endClipTranslateX = endClipIndexRatio * THUMBNAIL_WIDTH_PX;

  // total time in the clip reel
  const totalClipDuration = previewThumbnails.length * THUMBNAIL_MS;
  // what portion of the video you are in
  const totalRatio = videoTimestamp / totalClipDuration;
  // total px in the clip reel
  const totalWidth = previewThumbnails.length * THUMBNAIL_WIDTH_PX;
  const marker = totalRatio * totalWidth;

  /** handles Clipbar scrolling in and out of focus. */
  useEffect(() => {
    if (containerRef.current && markerRef.current) {
      const rects = containerRef.current.getBoundingClientRect();
      const BUFFER_GAP = 20;
      const startPosition = (start / videoDuration) * rects.width;
      const endPosition = (end / videoDuration) * rects.width;

      // if marker is close left to the edge don't do it
      if (containerRef.current.scrollLeft <= startPosition + BUFFER_GAP) {
        containerRef.current.scrollLeft = Math.max(
          startPosition - BUFFER_GAP,
          0
        );
        // if market is close right
      } else if (containerRef.current.scrollLeft >= endPosition - BUFFER_GAP) {
        containerRef.current.scrollLeft = Math.min(
          startPosition + BUFFER_GAP,
          rects.width
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end]);

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        paddingTop: '3px',
      }}
    >
      {previewThumbnails.map((src, key) => {
        /**
         * is start clip
         * is in middle
         * is end clip
         */
        const extraProps: any = {};
        const isStartReel = key === startClipIndex;
        const isEndReel = key === endClipIndex;
        const isBetweenReel = key > startClipIndex && key < endClipIndex;
        const isBothEndsReel = isStartReel && isEndReel;

        if (isBothEndsReel) {
          extraProps.left = 0;
          extraProps.width = startClipTranslateX;
          extraProps.width2 = THUMBNAIL_WIDTH_PX - endClipTranslateX;
          extraProps.left2 = endClipTranslateX;

          extraProps.leftFocus = startClipTranslateX;
          extraProps.widthFocus = endClipTranslateX - startClipTranslateX;
        } else if (isStartReel) {
          extraProps.left = 0;
          extraProps.width = startClipTranslateX;
          extraProps.leftFocus = startClipTranslateX;
          extraProps.widthFocus = THUMBNAIL_WIDTH_PX - startClipTranslateX;
        } else if (isEndReel) {
          extraProps.width = THUMBNAIL_WIDTH_PX - endClipTranslateX;
          extraProps.left = endClipTranslateX;
          extraProps.leftFocus = 0;
          extraProps.widthFocus = endClipTranslateX;
        } else if (isBetweenReel) {
          extraProps.backgroundColor = 'transparent';
          extraProps.leftFocus = 0;
          extraProps.widthFocus = THUMBNAIL_WIDTH_PX;
        }

        return <SandImage key={key} src={src} {...extraProps} />;
      })}
      <Box
        ref={markerRef}
        sx={{
          position: 'absolute',
          width: '4px',
          height: THUMBNAIL_HEIGHT_PX,
          backgroundColor: '#ff4e45',
          borderRadius: '2px',
          pointerEvents: 'none',
          left: marker,
        }}
      />
    </Box>
  );
}

/**
 * image,
 * covered up,
 */
interface SandImageType {
  /** default 'rgba(0, 0,0, 0.8)' */
  backgroundColor?: string;
  /** default 0.8 */
  left?: number;
  left2?: number;
  opacity?: number;
  leftFocus?: number;
  width?: number;
  width2?: number;
  widthFocus?: number;
  src: string;
}

function SandImage({
  backgroundColor = 'rgba(0, 0,0, 0.8)',
  left = 0,
  left2,
  leftFocus = 0,
  opacity = 0.8,
  width = THUMBNAIL_WIDTH_PX,
  width2,
  widthFocus = 0,
  src,
}: SandImageType): JSX.Element {
  const thumbnail = useThumbnail(src, {
    height: THUMBNAIL_HEIGHT_PX,
    width: THUMBNAIL_WIDTH_PX,
    aspectRatio: { x: THUMBNAIL_WIDTH_PX, y: THUMBNAIL_HEIGHT_PX },
  });

  const backgroundSx = {
    pointerEvents: 'none',
    position: 'absolute',
    backgroundColor,
    opacity,
    top: 0,
    left,
    width,
    height: thumbnail.height,
  };

  const focusSx = {
    pointerEvents: 'none',
    position: 'absolute',
    backgroundColor: 'transparent',
    top: 0,
    transform: 'translateY(-3px)',
    left: leftFocus,
    width: widthFocus,
    height: thumbnail.height + 6,
    borderTop: '3px',
    borderBottom: '3px',
    borderLeft: '0px',
    borderRight: '0px',
    borderStyle: 'solid',
    borderColor: 'green',
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={focusSx} />
      <Box sx={backgroundSx} />
      {width2 && left2 && (
        <Box sx={{ ...backgroundSx, left: left2, width: width2 }} />
      )}
      <img
        src={thumbnail.thumbnailSrc}
        height={thumbnail.height}
        width={thumbnail.width}
      />
    </Box>
  );
}

interface SandBoxType {
  end: number;
  previewThumbnails?: string[];
  setStart: React.Dispatch<React.SetStateAction<number>>;
  setEnd: React.Dispatch<React.SetStateAction<number>>;
  start: number;
  videoDuration: number;
  videoTimestamp: number;
}
function SandBox({
  end,
  previewThumbnails = [],
  setEnd,
  setStart,
  start,
  videoDuration,
  videoTimestamp,
}: SandBoxType): JSX.Element {
  const playerContext = usePlayer();
  const playerRef = usePlayer().playerRef as ReactPlayer;

  useEffect(() => {
    // if it's still in the same range, let playback continue as is
    if (videoTimestamp > start && videoTimestamp < end) {
      // do nothing
    }

    // if video seeks past range, then seek back to the beginning
    if (videoTimestamp < start || videoTimestamp > end) {
      playerContext.handleSeekCallback(start);
    }
    // if video has seeked outside of range, reset everything
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoTimestamp]);

  const handleChange = async (
    _event: Event,
    newValue: number | number[],
    activeThumb: number
  ): Promise<void> => {
    if (!Array.isArray(newValue)) {
      return;
    }

    let [newStart, newEnd] = newValue;
    let range = newEnd - newStart;
    // accounting for min and max range
    range = Math.max(MIN_RANGE, range);
    range = Math.min(MAX_RANGE, range);

    if (activeThumb === 0) {
      newStart = Math.min(newStart, videoDuration - MIN_RANGE);
      newEnd = Math.min(videoDuration, newStart + range);
    } else {
      // 1
      newEnd = Math.max(newEnd, MIN_RANGE);
      newStart = Math.max(0, newEnd - range);
    }

    await Promise.all([
      setStart(newStart),
      setEnd(newEnd),
      playerRef.seekTo(activeThumb === 0 ? newStart : newEnd - TIME_BUFFER),
    ]);
  };

  const handleBack10 = async (): Promise<void> => {
    const newStart = Math.max(start - 10, 0);
    let newEnd = end - 10;
    const newTimestamp = Math.max(videoTimestamp - 10, 0);

    /** accounting for the ranges */
    const timeDiff = newEnd - newStart;
    if (timeDiff < MIN_RANGE) {
      newEnd = newStart + MIN_RANGE;
    } else if (timeDiff > MAX_RANGE) {
      newEnd = newStart + MAX_RANGE;
    }

    await Promise.all([
      setStart(newStart),
      setEnd(newEnd),
      playerRef.seekTo(newTimestamp),
    ]);
  };

  const handleForward10 = async (): Promise<void> => {
    let newStart = start + 10;
    const newEnd = Math.min(end + 10, videoDuration);
    const newTimestamp = Math.min(videoTimestamp + 10, videoDuration);

    /** accounting for the ranges */
    const timeDiff = newEnd - newStart;
    if (timeDiff < MIN_RANGE) {
      newStart = newEnd - MIN_RANGE;
    } else if (timeDiff > MAX_RANGE) {
      newStart = newEnd - MAX_RANGE;
    }

    await Promise.all([
      setStart(newStart),
      setEnd(newEnd),
      playerRef.seekTo(newTimestamp),
    ]);
  };

  const timeEntrySx = {
    borderRadius: '2px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'rgb(170, 170, 170)',
    pt: 1,
    pb: 1,
    pl: 1.5,
    pr: 1.5,
  };

  const rangeText = `${(end - start).toFixed(1)} seconds`;

  return (
    <Stack direction='column'>
      <Stack direction='row' justifyContent='center' spacing={1}>
        <Tooltip title='Rewind 10' placement='top'>
          <IconButton onClick={handleBack10}>
            <Replay10Icon />
          </IconButton>
        </Tooltip>
        <Typography sx={timeEntrySx}>
          {formatSeconds(start.toString())}
        </Typography>
        <Stack justifyContent='center' alignItems='center'>
          <Typography>-</Typography>
        </Stack>
        <Typography sx={timeEntrySx}>
          {formatSeconds(end.toString())}
        </Typography>
        <Tooltip title='Forward 10' placement='top'>
          <IconButton onClick={handleForward10}>
            <Forward10Icon />
          </IconButton>
        </Tooltip>
      </Stack>
      <Box>
        <Slider
          getAriaLabel={() => 'timelineSlider'}
          min={0}
          max={videoDuration}
          onChange={handleChange}
          value={[start, end]}
          valueLabelDisplay='auto'
          disableSwap
        />
      </Box>

      <SandReel
        start={start}
        end={end}
        videoDuration={videoDuration}
        previewThumbnails={previewThumbnails}
        videoTimestamp={videoTimestamp}
      />

      <Stack direction='row' justifyContent='center'>
        <Typography>{rangeText}</Typography>
      </Stack>
    </Stack>
  );
}

export { SandBox };
