import { CircularProgress, Container } from '@mui/material';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { useStore } from 'hooks/useStore';
import { useAppBarHeight } from 'hooks/useAppBarHeight';

interface LandingType {
  isAuthCheckReady: boolean;
  isUserDataReady: boolean;
  isOrgDataReady: boolean;
}

function Landing({
  isAuthCheckReady,
  isUserDataReady,
  isOrgDataReady,
}: LandingType): JSX.Element {
  const {
    state: { authenticated, user, organization },
  } = useStore();
  const { appBarHeight } = useAppBarHeight();

  const containerSx = {
    display: 'flex',
    width: '100%',
    height: `calc(100vh - ${appBarHeight}px)`,
    alignItems: 'center',
    justifyContent: 'center',
  };

  // authentication process is done and user is not authed
  if (isAuthCheckReady && !authenticated) {
    return <Navigate replace to='/login' />;
  }

  if (!isUserDataReady || !isOrgDataReady) {
    return (
      <Container sx={containerSx}>
        <CircularProgress size={75} variant='indeterminate' />
      </Container>
    );
  }

  if (user.is_admin) {
    return <Navigate replace to='/admin/dashboard' />;
  }
  // user is not admin

  // user has not been assigned an org
  if (!user.is_admin && !(user.organization_id || organization.id)) {
    return (
      <Container sx={containerSx}>
        You have not been assigned to an organization. Please contact your
        administrator to get set up.
      </Container>
    );
  }

  return (
    <Navigate replace to={`/organizations/${organization.name}/details`} />
  );
}

export { Landing };
