import { Container, Box } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import React from 'react';

import { ProcessedVideoTable } from 'pages/Admin/ProcessVideos/ProcessedVideoTable';

// import { PendingVideoTable } from './PendingVideoTable';
// import { ProcessedVideoTable } from './ProcessedVideoTable';

function MyVideoCollection(): JSX.Element {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        background: '#EEEEEE',
        overflow: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Container maxWidth='md'>
        <TableContainer
          component={Paper}
          sx={{ mt: 2, '&:last-child div': { mt: 0 } }}
        >
          <ProcessedVideoTable isAdmin={false} />
        </TableContainer>
      </Container>
    </Box>
  );
}

export { MyVideoCollection };
