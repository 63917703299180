import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';
import {
  Box,
  IconButton,
  Typography,
  Stack,
  LinearProgress,
} from '@mui/material';

import { PendingVideoMapType } from 'store/models/videos';

import { Thumbnail } from './Thumbnail';

interface VideoCardType {
  video: PendingVideoMapType;
  handleDeleteVideo: (uuid: string) => Promise<void>;
  handleOrderChange: (order: number, dir: number) => Promise<void>;
}

function VideoCard({
  video,
  handleDeleteVideo,
  handleOrderChange,
}: VideoCardType): JSX.Element {
  return (
    <Stack direction='row' sx={{ width: 1 }} spacing={2}>
      {/* order container */}
      <Box sx={{}}>
        <IconButton onClick={() => handleOrderChange(video.upload_order, -1)}>
          <KeyboardArrowUpIcon />
        </IconButton>
        <Stack direction='column' justifyContent='center' alignItems='center'>
          <Typography>
            {Number(video.upload_order) === 0
              ? 'First'
              : `${video.upload_order + 1}`}
          </Typography>
        </Stack>
        <IconButton onClick={() => handleOrderChange(video.upload_order, 1)}>
          <KeyboardArrowDownIcon />
        </IconButton>
      </Box>
      <Thumbnail urlPath={video?.thumbnails?.[0] || ''} />
      {/* info container */}
      <Stack spacing={1} justifyContent='space-between' sx={{ flex: 'auto' }}>
        <Typography sx={{ overflow: 'hidden' }}>{video.file_name}</Typography>
        <LinearProgress
          sx={{
            height: 10,
            borderRadius: 2,
            backgroundColor: 'primary.success',
          }}
          variant='determinate'
          value={100}
        />
      </Stack>
      <Stack
        direction='row'
        justifyContent='center'
        alignItems='center'
        sx={{}}
      >
        {video.storage_path && (
          <IconButton onClick={() => handleDeleteVideo(video.uuid)}>
            <HighlightOffIcon />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
}

export { VideoCard };
