import {
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  arrayRemove,
  arrayUnion,
  doc,
  setDoc,
  updateDoc,
  where,
  query,
  collection,
} from 'firebase/firestore';
import {
  ref,
  uploadBytesResumable,
  UploadTaskSnapshot,
  StorageError,
  deleteObject,
  UploadTask,
} from 'firebase/storage';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import merge from 'lodash.merge';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useDocumentData,
  useCollectionData,
} from 'react-firebase-hooks/firestore';
import { useSnackbar } from 'notistack';

import { PENDING_SURGERIES_UPLOAD_PATH } from 'constants/paths';
import { BACKGROUND_COLOR_GREY } from 'constants/styles';
import { useFirebase } from 'hooks/useFirebase';
import { TRANSITION_STATE, PendingVideoDocument } from 'store/models/videos';
import { useStore } from 'hooks/useStore';

import { VideoProgressCard } from './VideoProgressCard';
import { VideoCard } from './VideoCard';
import { ProgressType } from './types';

export const FILE_TYPES = ['mp4', 'mov', 'quicktime', 'webm', 'ogg'];

interface ProgressHashType {
  [uuid: string]: ProgressType;
}

interface TaskRefType {
  uuid: string;
  task: UploadTask;
}
function VideoUpload(): JSX.Element {
  const { pendingVideoId } = useParams();
  const {
    state: { user, organization },
  } = useStore();
  const { db, storage } = useFirebase();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const taskRefs = useRef<TaskRefType[]>([]);

  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [progressHash, setProgressHash] = useState<ProgressHashType>({});
  const [lastRender, setLastRender] = useState(Date.now());

  const docRef = doc(db, `pending-videos/${pendingVideoId}`);
  const [pendingVideo, _loading, _error, pendingVideoSnapshot] =
    useDocumentData(docRef);
  const [orgUsers] = useCollectionData(
    query(
      collection(db, 'users'),
      where('organization_id', '==', organization.id)
    )
  );

  const [categoryClicked, setCategoryClicked] = useState<string[]>([]);
  const [surgeonClicked, setSurgeonClicked] = useState<string[]>([]);
  const [isCreateCategoryOpen, setIsCreateCategoryOpen] = useState(false);
  const [categoryText, setCategoryText] = useState('');
  const [surgeons, setSurgeons] = useState<string[]>([]);
  const [isTitleValidated, setIsTitleValidated] = useState(true);
  const [isDescriptionValidated, setIsDescriptionValidated] = useState(true);

  useEffect(() => {
    if (orgUsers) {
      setSurgeons(orgUsers?.map((orgUser) => orgUser.name));
    }
  }, [orgUsers]);

  const handleOpenCreateCategory = (): void => {
    setIsCreateCategoryOpen(true);
  };

  const handleCloseCreateCategory = (): void => {
    setIsCreateCategoryOpen(false);
    setCategoryText('');
  };

  const handleCreateCategoryAndClose = (): void => {
    if (user?.organization_id)
      updateDoc(doc(db, `/organizations/${organization.id}`), {
        categories: arrayUnion(categoryText),
      });
    setCategoryClicked([...categoryClicked, categoryText]);
    setIsCreateCategoryOpen(false);
    setCategoryText('');
  };

  const handleCategoryTextChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setCategoryText(e.target.value);
  };

  const handleCategoryToggle = (id: string) => () => {
    const isIdIncluded = categoryClicked.includes(id);

    if (isIdIncluded) {
      setCategoryClicked((currentClicked) =>
        currentClicked.filter((currentId) => currentId !== id)
      );
    } else {
      setCategoryClicked((currentClicked) => [...currentClicked, id]);
    }
  };

  const handleSurgeonToggle = (id: string) => () => {
    const isIdIncluded = surgeonClicked.includes(id);

    if (isIdIncluded) {
      setSurgeonClicked((currentClicked) =>
        currentClicked.filter((currentId) => currentId !== id)
      );
    } else {
      setSurgeonClicked((currentClicked) => [...currentClicked, id]);
    }
  };

  const onProgress =
    (uuid: string, fileName: string, order: number) =>
    async (snapshot: UploadTaskSnapshot): Promise<void> => {
      const bytesTransferred = snapshot.bytesTransferred;
      const bytes = snapshot.totalBytes;

      await setProgressHash((prevState) =>
        merge(prevState, {
          [uuid]: {
            progress: {
              bytesTransferred,
            },
          },
        })
      );
      if (bytesTransferred >= bytes) {
        await onSuccess(uuid, fileName, order);
      }
      await setLastRender(Date.now());
    };
  const onError =
    (uuid: string) =>
    async (error: StorageError): Promise<void> => {
      console.log('error on upload.uuid: ', uuid);
      console.log('error uploading: ', error);
      await setProgressHash((prevState) =>
        merge(prevState, {
          [uuid]: {
            progress: {
              error: error.serverResponse,
              errorCode: error.code,
            },
          },
        })
      );
      setLastRender(Date.now());
    };
  const onSuccess = async (
    uuid: string,
    fileName: string,
    uploadOrder: number
  ): Promise<void> => {
    try {
      const taskRef = taskRefs.current.find((ref) => ref.uuid === uuid);
      if (!taskRef) {
        return;
      }
      const { task } = taskRef;
      // clean up
      await setProgressHash((prevState) => {
        delete prevState[uuid];
        return prevState;
      });
      // not fast enough, need to hack
      await setLastRender(Date.now());
      // update firebase doc
      await setDoc(
        docRef,
        {
          storage_paths: arrayUnion(task.snapshot.ref.fullPath),
          videos: {
            [uuid]: {
              file_name: fileName,
              storage_path: task.snapshot.ref.fullPath,
              upload_order: uploadOrder,
              uuid,
            },
          },
        } as any,
        { merge: true }
      );
    } catch (e) {
      enqueueSnackbar('Error uploading video', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      console.log('error uploading video: ', e);
    }
  };

  const handleDeleteVideo = async (deleteId: string): Promise<void> => {
    try {
      const videos = (pendingVideo as PendingVideoDocument).videos;

      const videoToDelete = videos[deleteId];
      if (!videoToDelete) {
        return;
      }

      const deleteRef = ref(storage, videoToDelete.storage_path);
      await deleteObject(deleteRef);

      const updatedVideos: PendingVideoDocument['videos'] = {};
      Object.entries(videos)
        .filter(([videoIndex]) => videoIndex !== deleteId)
        .sort(
          ([_a, video], [_b, nextVideo]) =>
            video.upload_order - nextVideo.upload_order
        )
        .forEach(([videoIndex, video], uploadOrderIndex) => {
          updatedVideos[videoIndex] = {
            ...video,
            upload_order: uploadOrderIndex,
          };
        });

      // delete entry and update the upload_order
      await updateDoc(docRef, {
        storage_paths: arrayRemove(videoToDelete.storage_path),
        videos: updatedVideos,
      });
    } catch (e) {
      enqueueSnackbar('Error deleting video', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      console.log(e);
    }
  };

  const handleOrderChange = async (
    uploadOrder: number,
    direction: number
  ): Promise<void> => {
    const videos = Object.values((pendingVideo as PendingVideoDocument).videos);

    // getting the file keys
    const videoUUID = videos.find(
      (video) => video.upload_order === uploadOrder
    )?.uuid;
    const nextVideoUUID = videos.find(
      (video) => video.upload_order === uploadOrder + direction
    )?.uuid;

    if (!videoUUID || !nextVideoUUID) {
      return;
    }

    // reordering storage paths
    const storagePaths = videos
      .map((currVideo) => {
        if (currVideo.uuid === videoUUID) {
          return { ...currVideo, upload_order: uploadOrder + direction };
        }
        if (currVideo.uuid === nextVideoUUID) {
          return { ...currVideo, upload_order: uploadOrder };
        }
        return currVideo;
      })
      .sort((currVideo, nextVideo) => {
        return currVideo.upload_order - nextVideo.upload_order;
      })
      .map(({ storage_path }) => storage_path);

    await setDoc(
      docRef,
      {
        storage_paths: storagePaths,
        videos: {
          [videoUUID]: {
            ...pendingVideo?.videos?.[videoUUID],
            upload_order: uploadOrder + direction,
          },
          [nextVideoUUID]: {
            ...pendingVideo?.videos?.[nextVideoUUID],
            upload_order: uploadOrder,
          },
        },
      } as any,
      { merge: true }
    );
  };

  /**
   * everytime a video is uploaded, we set a worker to begin the
   * upload process to Storage. once storage is complete, we
   * create a pending-video document to attach it to an existing entity,
   * where we can attach associated thumbnails plus video stiching to create
   * a proper video document later on.
   */
  const handleFileChange = async (uploadedFiles: FileList): Promise<void> => {
    let newProgressHash = progressHash;
    // value needs to be captured once rather than be dynamic
    const lastIndex = await (pendingVideoSnapshot?.get('storage_paths') || [])
      .length;
    const uploadedFilesArr = Array.from({ length: uploadedFiles.length }).map(
      (_, index) => uploadedFiles[index]
    );
    uploadedFilesArr.forEach((file, fileIndex) => {
      const uuid = crypto.randomUUID();
      const timestamp = new Date().getTime();
      const fileName = `${timestamp}_${file.name}`;
      const storageRef = ref(
        storage,
        `${PENDING_SURGERIES_UPLOAD_PATH}/${fileName}`
      );
      const task = uploadBytesResumable(storageRef, file); // worker
      task.on(
        'state_changed',
        onProgress(uuid, fileName, lastIndex + fileIndex),
        onError(uuid)
      );
      taskRefs.current = [...taskRefs.current, { uuid, task }];
      newProgressHash = {
        ...newProgressHash,
        [uuid]: {
          video: { uuid, fileName },
          progress: { bytes: file.size, bytesTransferred: 0 },
        },
      };
    });
    await setProgressHash(newProgressHash);
  };

  const handleSubmitUpload = async (): Promise<void> => {
    if (title && description) {
      try {
        /** sorting in case they go out of order */
        const video = pendingVideo as PendingVideoDocument;
        const videos = Object.values(video.videos);
        const storagePaths = video.storage_paths.sort((path, nextPath) => {
          const uploadOrder =
            videos.find(({ storage_path }) => storage_path === path)
              ?.upload_order || 0;

          const nextUploadOrder =
            videos.find(({ storage_path }) => storage_path === nextPath)
              ?.upload_order || 0;

          return uploadOrder - nextUploadOrder;
        });

        await setDoc(
          docRef,
          {
            categories: categoryClicked,
            description,
            status: TRANSITION_STATE.PROCESSING,
            storage_paths: storagePaths,
            surgeons: surgeonClicked,
            title,
          },
          { merge: true }
        );
        enqueueSnackbar('Video created', {
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        navigate('/videos');
      } catch (e) {
        console.log('error submitting upload: ', e);
        enqueueSnackbar('Error creating video', {
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      }
    } else {
      title ? setIsTitleValidated(true) : setIsTitleValidated(false);
      description
        ? setIsDescriptionValidated(true)
        : setIsDescriptionValidated(false);
    }
  };

  // needs to refresh after progress gets updated
  const preVideoStack = useMemo(
    () => Object.values(progressHash),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastRender]
  );
  const videoStack = useMemo(() => {
    const videos = Object.values(
      (pendingVideo?.videos || {}) as PendingVideoDocument['videos']
    );
    return videos.sort(
      (video, nextVideo) => video.upload_order - nextVideo.upload_order
    );
  }, [pendingVideo]);

  const isFormComplete =
    pendingVideo?.storage_paths.length !== 0 && preVideoStack.length === 0;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          background: BACKGROUND_COLOR_GREY,
          width: '100%',
          minHeight: '100%',
          height: 'auto',
        }}
      >
        <Card sx={{ mb: 2, mt: 2, p: 2, width: 800, height: 'fit-content' }}>
          <Box
            component='form'
            sx={{
              '& > :not(style)': { m: 1 },
              display: 'flex',
              flexDirection: 'column',
            }}
            noValidate
            autoComplete='off'
          >
            <TextField
              required
              error={!isTitleValidated}
              label='Title'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <br />
            <TextField
              required
              error={!isDescriptionValidated}
              label='Description'
              value={description}
              multiline
              maxRows={5}
              onChange={(e) => setDescription(e.target.value)}
            />
            <Box sx={{ maxHeight: 152 }}>
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
              >
                <Typography sx={{ mt: 0.5, fontWeight: 'medium' }}>
                  Categories:
                </Typography>
                <Button
                  variant='contained'
                  onClick={handleOpenCreateCategory}
                  disabled={!docRef}
                >
                  Create
                </Button>
              </Box>
              {organization.categories && (
                <>
                  <Box
                    sx={{
                      maxHeight: 128,
                      overflow: 'auto',
                    }}
                  >
                    {organization.categories.sort().map((category: string) => {
                      return (
                        <Chip
                          key={category}
                          label={category}
                          onClick={handleCategoryToggle(category)}
                          variant={
                            categoryClicked.indexOf(category) !== -1
                              ? 'filled'
                              : 'outlined'
                          }
                          sx={{ mr: 1, mb: 1 }}
                        />
                      );
                    })}
                  </Box>
                </>
              )}
            </Box>
            <br />
            <Box sx={{ maxHeight: 152 }}>
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
              >
                <Typography sx={{ mt: 0.5, fontWeight: 'medium' }}>
                  Surgeons:
                </Typography>
              </Box>
              {surgeons && (
                <>
                  <Box
                    sx={{
                      maxHeight: 128,
                      overflow: 'auto',
                    }}
                  >
                    {surgeons.sort().map((surgeon, index) => {
                      return (
                        <Chip
                          key={index}
                          label={surgeon}
                          onClick={handleSurgeonToggle(surgeon)}
                          variant={
                            surgeonClicked.indexOf(surgeon) !== -1
                              ? 'filled'
                              : 'outlined'
                          }
                          sx={{ mr: 1, mb: 1 }}
                        />
                      );
                    })}
                  </Box>
                </>
              )}
            </Box>
            <br />
            <FileUploader
              name='file'
              handleChange={handleFileChange}
              types={FILE_TYPES}
              multiple
            />

            <Stack
              direction='row'
              spacing={2}
              sx={{ justifyContent: 'flex-end' }}
            >
              <Button
                disabled={!isFormComplete}
                variant='contained'
                onClick={handleSubmitUpload}
                sx={{ float: 'right' }}
              >
                Submit Upload
              </Button>
            </Stack>

            <Stack spacing={2}>
              {preVideoStack.map((preVideo, key) => (
                <VideoProgressCard
                  key={`pending-${key}-${preVideo.video.uuid}`}
                  video={preVideo.video}
                  progress={preVideo.progress}
                />
              ))}
              {videoStack.map((video, key) => (
                <VideoCard
                  key={`${key}-${video.uuid}`}
                  video={video}
                  handleDeleteVideo={handleDeleteVideo}
                  handleOrderChange={handleOrderChange}
                />
              ))}
            </Stack>
          </Box>
        </Card>
      </Box>
      <Dialog open={isCreateCategoryOpen} onClose={handleCloseCreateCategory}>
        <DialogTitle>Create Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            label='Enter Category'
            fullWidth
            variant='standard'
            value={categoryText}
            onChange={handleCategoryTextChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateCategory}>Cancel</Button>
          <Button
            onClick={handleCreateCategoryAndClose}
            disabled={categoryText === ''}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export { VideoUpload };
