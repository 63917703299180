import { collection, orderBy, query, where } from 'firebase/firestore';
import React from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Link } from 'react-router-dom';
import { Box, Typography, Button, Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { useFirebase } from 'hooks/useFirebase';
import { useStore } from 'hooks/useStore';
import { useThumbnail } from 'hooks/useThumbnail';
import { VideoDocument } from 'store/models/videos';
import { useInviteUser } from 'hooks/useInviteUser';
import { OrganizationDocument } from 'store/models/organizations';
import { IUser, USER_ROLE_TYPE } from 'store/app/state/index';
import { CommentForm } from 'components/comments/CommentForm';
import { NotificationDocument } from 'store/models/notifications';
import { CommentDocument } from 'store/models/comments';

import { NewsfeedList } from './NewsfeedList';

function OrganizationDetail(): JSX.Element {
  const {
    state: { user, organization },
  } = useStore();
  const { db } = useFirebase();

  const { InviteUserButtonComponent, InviteUserDialogComponent } =
    useInviteUser();

  const { thumbnailSrc } = useThumbnail(
    organization.image_path ? organization.image_path : '',
    {
      height: 100,
    }
  );

  const [orgUsers, orgUsersLoading, orgUsersError] = useCollectionData(
    query(
      collection(db, 'users'),
      where('organization_id', '==', organization.id),
      orderBy('name')
    )
  );

  const queryRef = query(
    collection(db, 'videos'),
    where('organization_id', '==', organization.id),
    where('is_archived', '==', false),
    orderBy('created_at', 'desc')
  );

  const [videos] = useCollectionData(queryRef);

  const [rootComments = [], _rootCommentsLoading, _rootCommentsError] =
    useCollectionData(
      query(
        collection(db, 'comments'),
        where('parentId', '==', null),
        where('page', '==', `organization/${organization.name}`)
      )
    );

  const [notifications] = useCollectionData(
    query(
      collection(db, 'notifications'),
      where('organization_id', '==', organization.id),
      where('notification_type', 'in', ['video-processed', 'new-user'])
    )
  );

  if (orgUsersLoading) {
    return <div>Loading...</div>;
  }

  if (orgUsersError) {
    return <div>Error!</div>;
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        background: '#EEEEEE',
        overflow: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          background: 'white',
          width: '100%',
          height: 'fit-content',
          pt: 3,
          pr: 2,
          pl: 2,
          pb: 3,
        }}
      >
        <Box sx={{ alignSelf: 'center' }}>
          <img alt='company logo' src={thumbnailSrc} width={100} height={100} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            pt: 0,
            pb: 1,
            pr: 2,
            pl: 2,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <Typography variant='h4' sx={{ color: 'text.primary' }}>
                {organization.name}
              </Typography>
              {organization.description && (
                <Typography variant='h6' sx={{ color: 'text.primary' }}>
                  {organization.description}
                </Typography>
              )}
              <Typography variant='h6' sx={{ color: 'text.secondary' }}>
                {`${organization.user_count} Members`}
              </Typography>
            </Box>
            <Box>
              <Box sx={{ display: 'flex' }}>
                <InviteUserButtonComponent />
                {(user.role === USER_ROLE_TYPE.PROGRAM_MANAGER ||
                  user.is_admin) && (
                  <Button
                    component={Link}
                    to={`/organizations/${organization.name}/edit`}
                    sx={{ ml: 1 }}
                    startIcon={<EditIcon />}
                  >
                    Edit
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Stack
        direction='column'
        alignItems='center'
        sx={{
          width: '100%',
          minWidth: 600,
        }}
      >
        <Box sx={{ pt: 2 }}>
          <CommentForm
            page={`organization/${organization.name}`}
            mentionableUsers={orgUsers as IUser[]}
            linkableVideos={videos as VideoDocument[]}
          />
          <NewsfeedList
            comments={rootComments as CommentDocument[]}
            notifications={notifications as NotificationDocument[]}
            mentionableUsers={orgUsers as IUser[]}
            linkableVideos={videos as VideoDocument[]}
          />
        </Box>
      </Stack>
      <InviteUserDialogComponent
        organization={organization as OrganizationDocument}
      />
    </Box>
  );
}

export { OrganizationDetail };
