import { doc } from 'firebase/firestore';
import React, { useCallback } from 'react';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { useResizeDetector } from 'react-resize-detector';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Stack, Avatar } from '@mui/material';
import moment from 'moment';

import { useFirebase } from 'hooks/useFirebase';
import { useThumbnail } from 'hooks/useThumbnail';
import { NotificationDocument } from 'store/models/notifications';

/** in order to scale image the size of the box that contains it */
function PreviewImage({
  thumbnailSrc,
}: {
  thumbnailSrc?: string;
}): JSX.Element {
  const DEFAULT_WIDTH = 568;
  const thumbnail = useThumbnail(thumbnailSrc || '', { width: DEFAULT_WIDTH });
  const resize = useResizeDetector({
    handleHeight: false,
    onResize: useCallback((width?: number) => {
      thumbnail.resize(width || DEFAULT_WIDTH);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  });

  return (
    <Box sx={{ width: 1 }} ref={resize.ref}>
      <img
        src={thumbnail.thumbnailSrc}
        width={thumbnail.width}
        height={thumbnail.height}
        alt='thumbnail'
      />
    </Box>
  );
}

function NewsfeedVideoListItem({
  newsfeedItem,
}: {
  newsfeedItem: NotificationDocument;
}): JSX.Element {
  const { db } = useFirebase();
  const navigate = useNavigate();
  const [user] = useDocumentDataOnce(doc(db, `/users/${newsfeedItem.user_id}`));
  const [video] = useDocumentDataOnce(
    doc(db, `/videos/${newsfeedItem.metadata?.video_id}`)
  );

  const handleGoToVideoClick = (): void => {
    navigate(`/videos/${newsfeedItem.metadata?.video_id}`);
  };

  const commentCountText = `${video?.comment_count} comments`;

  return (
    <Stack spacing={0.5}>
      <Box sx={{ display: 'flex' }}>
        <Avatar src={user?.avatarSrc || ''} />
        <Stack sx={{ ml: 1 }}>
          <Typography variant='subtitle2'>{user?.name}</Typography>
          <Typography
            sx={{ color: 'text.secondary' }}
            variant='caption'
            gutterBottom
          >
            {moment((newsfeedItem.timestamp as any).toDate()).calendar()}
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography>
          {`uploaded a video titled ${video?.metadata.title}`}
        </Typography>
      </Box>

      {video && <PreviewImage thumbnailSrc={video.thumbnails?.[0]} />}
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='overline'>{commentCountText}</Typography>
        <Button onClick={handleGoToVideoClick}>Go to Video</Button>
      </Stack>
    </Stack>
  );
}

export { NewsfeedVideoListItem };
