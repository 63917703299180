/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, ReactNode, useContext, useState } from 'react';
import screenfull from 'screenfull';

import ReactPlayer from 'react-player';

interface ProgressCallbackType {
  playedSeconds: number;
  loadedSeconds: number;
}

export interface PlayerContextType {
  loadedTime: number;
  playbackRate: number;
  playerRef: ReactPlayer | null;
  playerTime: number;
  isMediaLoaded: boolean;
  isPlaying: boolean;
  isFullScreen: boolean;
  isSeeking: boolean;
  setIsSeeking: React.Dispatch<React.SetStateAction<boolean>>;
  setPlaybackRate: React.Dispatch<React.SetStateAction<number>>;
  setPlayerTime: React.Dispatch<React.SetStateAction<number>>;
  setPlayerRef: React.Dispatch<React.SetStateAction<ReactPlayer | null>>;
  // setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  // setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  handlePlayPause: () => void;
  handleToggleFullScreen: (element: HTMLElement) => void;
  handleSeekCallback: (seconds: number) => void;
  handlePlayPauseCallback: () => void;
  handleProgressCallback: (state: ProgressCallbackType) => void;
  handleReadyCallback: () => void;
}

const PlayerContext = createContext<PlayerContextType>({
  loadedTime: NaN,
  playbackRate: NaN,
  playerRef: null,
  playerTime: NaN,
  isMediaLoaded: false,
  isPlaying: false,
  isFullScreen: false,
  isSeeking: false,
  setIsSeeking: () => {},
  setPlaybackRate: () => {},
  setPlayerTime: () => {},
  setPlayerRef: () => {},
  // setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  // setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  handlePlayPause: () => {},
  handleToggleFullScreen: () => {},
  handleSeekCallback: () => {},
  handlePlayPauseCallback: () => {},
  handleProgressCallback: () => {},
  handleReadyCallback: () => {},
});

interface AuxProps {
  children: ReactNode;
}
function PlayerProvider({ children }: AuxProps): JSX.Element {
  const [playerRef, setPlayerRef] = useState<ReactPlayer | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [playerTime, setPlayerTime] = useState(0);
  const [loadedTime, setLoadedTime] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1.0);
  const [isMediaLoaded, setIsMediaLoaded] = useState(false);
  const [isSeeking, setIsSeeking] = useState(false);

  const handlePlayPause = (): void => {
    setIsPlaying(!isPlaying);
  };

  const handleToggleFullScreen = (element: HTMLElement): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    screenfull.toggle(element);
    setIsFullScreen(!isFullScreen);
  };

  const handleSeekCallback = (seconds: number): void => {
    if (!isSeeking) {
      setPlayerTime(seconds);
      playerRef?.seekTo(seconds);
    }
  };

  const handleProgressCallback = (state: ProgressCallbackType): void => {
    setPlayerTime(state.playedSeconds);
    setLoadedTime(state.loadedSeconds);
  };

  const handleReadyCallback = (): void => {
    setIsMediaLoaded(true);
  };

  const handlePlayPauseCallback = (): void => {
    return;
  };

  const value = {
    loadedTime,
    playbackRate,
    playerRef,
    playerTime,
    isPlaying,
    isMediaLoaded,
    isFullScreen,
    isSeeking,
    setPlayerRef,
    setIsSeeking,
    setPlaybackRate,
    setPlayerTime,
    // setIsPlaying,
    // setIsFullScreen,
    handlePlayPause,
    handleToggleFullScreen,
    handleSeekCallback,
    handleProgressCallback,
    handlePlayPauseCallback,
    handleReadyCallback,
  };

  return (
    <PlayerContext.Provider value={value}>{children}</PlayerContext.Provider>
  );
}

function usePlayer(): PlayerContextType {
  const context = useContext(PlayerContext);

  if (context === undefined) {
    throw new Error('usePlayer must be used within a PlayerProvider');
  }

  return context;
}

export { PlayerProvider, usePlayer };
