import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ReplayIcon from '@mui/icons-material/Replay';
import {
  Box,
  Button,
  Chip,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  query,
  collection,
  // orderBy,
  setDoc,
  doc,
  where,
} from 'firebase/firestore';
import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useSnackbar } from 'notistack';

import { useFirebase } from 'hooks/useFirebase';
import { PendingVideoDocument, TRANSITION_STATE } from 'store/models/videos';
import { useThumbnail } from 'hooks/useThumbnail';

type StatusColorType =
  | 'info'
  | 'warning'
  | 'error'
  | 'success'
  | 'default'
  | 'primary'
  | 'secondary';

function PendingVideoRow({
  id,
  isSelected,
  error_messages = [],
  retry_count,
  setSelectedIndex,
  status,
  storage_paths,
  title,
  videos,
}: PendingVideoDocument & {
  isSelected: boolean;
  setSelectedIndex: React.Dispatch<React.SetStateAction<string>>;
}): JSX.Element {
  const { db } = useFirebase();
  const { enqueueSnackbar } = useSnackbar();

  const previewImagePath = Object.values(videos)?.[0]?.thumbnails[0];
  const { width, height, thumbnailSrc } = useThumbnail(previewImagePath, {
    width: 200,
  });

  const handleRetry = async (): Promise<void> => {
    try {
      const docRef = doc(db, `pending-videos/${id}`);
      await setDoc(
        docRef,
        { status: TRANSITION_STATE.PROCESSING, retry_count: retry_count + 1 },
        { merge: true }
      );
    } catch (e) {
      enqueueSnackbar('Error retrying video', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      console.log('error retrying video: ', e);
    }
  };

  const rowSx = {
    ...(status === TRANSITION_STATE.PROCESSING
      ? {
          pointerEvents: 'none',
          opacity: 0.5,
        }
      : {}),
    '&:last-child td, &:last-child th': { border: 0 },
  };

  const statusColor = {
    [TRANSITION_STATE.PREPARING]: 'info',
    [TRANSITION_STATE.PROCESSING]: 'warning',
    [TRANSITION_STATE.FAILED]: 'error',
    [TRANSITION_STATE.COMPLETED]: 'success',
  }[status] as StatusColorType;

  return (
    <>
      <TableRow key={id} sx={rowSx}>
        <TableCell align='right' sx={{ textAlign: 'center' }}>
          {error_messages.length > 0 && (
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => setSelectedIndex(isSelected ? '' : id)}
            >
              {isSelected ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell align='right' sx={{ textAlign: 'center' }}>
          <img src={thumbnailSrc} width={width} height={height} />
        </TableCell>
        <TableCell align='right' sx={{ textAlign: 'center' }}>
          {/* <Link to={`/admin/dashboard/pending-videos/${id}`}> */}
          <Typography variant='body1'>{title}</Typography>
          {/* </Link> */}
        </TableCell>
        <TableCell align='right' sx={{ textAlign: 'center' }}>
          <Typography variant='body1'>{storage_paths.length}</Typography>
        </TableCell>
        <TableCell align='right' sx={{ textAlign: 'center' }}>
          <Chip color={statusColor} label={status} />
        </TableCell>
        <TableCell align='right' sx={{ textAlign: 'center' }}>
          <Button
            variant='contained'
            endIcon={<ReplayIcon />}
            onClick={handleRetry}
            disabled={status !== TRANSITION_STATE.FAILED}
          >
            Retry
          </Button>
        </TableCell>
      </TableRow>
      {isSelected && (
        <TableRow key={`${id}-expanded`}>
          <TableCell colSpan={6}>
            <Collapse in={isSelected} timeout='auto' unmountOnExit>
              <Box>
                <Typography variant='h6' gutterBottom component='div'>
                  Error Messages
                </Typography>
                <Table size='small' aria-label='error-messages'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Retry Count</TableCell>
                      <TableCell>Message</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {error_messages.map((error, index) => (
                      <TableRow key={index}>
                        <TableCell
                          component='th'
                          scope='row'
                          sx={{ textAlign: 'center', verticalAlign: 'top' }}
                        >
                          {index}
                        </TableCell>
                        <TableCell>
                          <code>{error}</code>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

function PendingVideoTable(): JSX.Element {
  const { db } = useFirebase();
  const [selectedIndex, setSelectedIndex] = useState('');

  const queryRef = query(
    collection(db, 'pending-videos'),
    where('status', 'not-in', [
      TRANSITION_STATE.PREPARING,
      TRANSITION_STATE.COMPLETED,
    ])
    // orderBy('created_at', 'desc')
  );
  const [pendingVideos] = useCollectionData(queryRef);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell align='center'>Title</TableCell>
            <TableCell align='center'>Videos</TableCell>
            <TableCell align='center'>Status</TableCell>
            <TableCell align='center'>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(pendingVideos || []).map((pendingVideo) => (
            <PendingVideoRow
              key={pendingVideo.id}
              {...(pendingVideo as PendingVideoDocument)}
              isSelected={selectedIndex === pendingVideo.id}
              setSelectedIndex={setSelectedIndex}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export { PendingVideoTable };
