export enum NOTIFICATION_TYPE {
  VIDEO_PROCESSED = 'video-processed',
  RELATED_VIDEO = 'related-video',
  ORG_POST = 'org-post',
  NEW_USER = 'new-user',
}

export interface NotificationDocument {
  organization_id: string | null;
  user_id: string | null;
  acknowledged_user_ids: string[];
  involved_user_ids?: string[];
  notification_type: NOTIFICATION_TYPE;
  metadata?: {
    video_title?: string;
    video_id?: string;
    user_name?: string | null;
  };
  timestamp: Date;
  id: string;
}
