import DownloadIcon from '@mui/icons-material/Download';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { query, collection, orderBy, where } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import React from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useSnackbar } from 'notistack';

import { useFirebase } from 'hooks/useFirebase';
import { useStore } from 'hooks/useStore';
import { useThumbnail } from 'hooks/useThumbnail';
import { VideoDocument } from 'store/models/videos';

interface ProcessedVideoRowType {
  video: VideoDocument;
  isAdmin: boolean;
}

function ProcessedVideoRow({
  video,
  isAdmin,
}: ProcessedVideoRowType): JSX.Element {
  const { storage } = useFirebase();
  const { thumbnailSrc, width, height } = useThumbnail(
    video.thumbnail || video.thumbnails?.[0] || '',
    { width: 200 }
  );
  const { enqueueSnackbar } = useSnackbar();

  const handleDownload = async (): Promise<void> => {
    enqueueSnackbar('Download started', {
      variant: 'info',
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
    });
    const storageRef = await ref(storage, video.storage_path);
    const downloadUrl = await getDownloadURL(storageRef);

    // convert it to blob so that it can download without opeening
    const response = await fetch(downloadUrl);
    const blob = await response.blob();
    const url = URL.createObjectURL(new Blob([blob]));

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', storageRef.name);
    link.setAttribute('target', '_self');
    link.click();
  };

  return (
    <TableRow
      key={video.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component='th' scope='row'>
        <img src={thumbnailSrc} width={width} height={height} />
      </TableCell>
      <TableCell align='right' sx={{ textAlign: 'center' }}>
        <Link
          to={
            isAdmin
              ? `/admin/dashboard/pending-videos/${video.id}`
              : `/videos/my-videos/${video.id}`
          }
        >
          <Typography variant='body1'>{video.metadata.title}</Typography>
        </Link>
      </TableCell>
      <TableCell align='right' sx={{ textAlign: 'center' }}>
        <Typography variant='body1'>
          {video.metadata.duration || 'pending'}
        </Typography>
      </TableCell>
      <TableCell align='right' sx={{ textAlign: 'center' }}>
        <Typography variant='body1'>
          {video.metadata.size || 'pending'}
        </Typography>
      </TableCell>
      <TableCell align='right' sx={{ textAlign: 'center' }}>
        <Button
          variant='contained'
          endIcon={<DownloadIcon />}
          onClick={handleDownload}
        >
          Download
        </Button>
      </TableCell>
    </TableRow>
  );
}

function ProcessedVideoTable({ isAdmin }: { isAdmin: boolean }): JSX.Element {
  const {
    state: { user },
  } = useStore();
  const { db } = useFirebase();
  const queryRef = isAdmin
    ? query(collection(db, 'videos'), orderBy('created_at', 'desc'))
    : query(
        collection(db, 'videos'),
        where('user_id', '==', user.id),
        orderBy('created_at', 'desc')
      );
  const [videos] = useCollectionData(queryRef);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align='center'>Title</TableCell>
            <TableCell align='center'>Length (s)</TableCell>
            <TableCell align='center'>Size</TableCell>
            <TableCell align='center'>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(videos || []).map((video) => (
            <ProcessedVideoRow
              key={video.id}
              isAdmin={isAdmin}
              video={video as VideoDocument}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export { ProcessedVideoTable };
