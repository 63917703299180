import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';

import { FirebaseProvider } from 'hooks/useFirebase';
import { StoreProvider } from 'hooks/useStore';

import reportWebVitals from './reportWebVitals';
import { App } from './App';
import { theme } from './theme';
import './index.css';

// const composeProviders = (...Providers) => (Child) => (props) => (
//   Providers.reduce((acc, Provider) => (
//     <Provider>
//       {acc}
//     </Provider>
//   ), <Child {...props} />)
// );

// const WrappedApp = composeProviders(
//   FirebaseProvider,
//   StoreProvider,
// )(App);

ReactDOM.render(
  <React.StrictMode>
    <FirebaseProvider>
      <StoreProvider>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <App />
          </SnackbarProvider>
        </ThemeProvider>
      </StoreProvider>
    </FirebaseProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
