import { doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import React, { useState } from 'react';
import {
  Box,
  Button,
  Stack,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { useFirebase } from 'hooks/useFirebase';
import { LOGO_UPLOAD_PATH } from 'constants/paths';
import { useStore } from 'hooks/useStore';

// Not sure if this is all the image files
const FILE_TYPES = ['jpeg', 'png', 'tiff', 'gif', 'svg'];

function EditOrganizationLogo(): JSX.Element {
  const {
    state: { organization },
  } = useStore();
  const { db, storage } = useFirebase();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [file, setFile] = useState<File>();
  const [isImageCropOpen, setIsImageCropOpen] = useState(false);

  const [croppedImageUrl, setCroppedImageUrl] = useState<string>('');
  const [crop, setCrop] = useState<Crop>();
  const [image, setImage] = useState<HTMLImageElement>();
  const [cropImage, setCropImage] = useState<Blob>();

  const handleCloseImageCrop = (): void => {
    setIsImageCropOpen(false);
  };

  const onFileChange = (file: File): void => {
    setFile(file);
    setIsImageCropOpen(true);
  };

  const getCroppedImage = (
    image: HTMLImageElement,
    crop: Crop
  ): Promise<any> => {
    const imageCanvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    imageCanvas.width = crop.width;
    imageCanvas.height = crop.height;
    const imgCx = imageCanvas.getContext('2d');
    imgCx?.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    return new Promise((reject, _resolve) => {
      imageCanvas.toBlob((blob: Blob | null) => {
        if (!blob) {
          reject(new Error('image canvas is empty'));
          return;
        }
        const imageURL = window.URL.createObjectURL(blob);
        setCroppedImageUrl(imageURL);
        setCropImage(blob);
      }, 'image1/jpeg');
    });
  };

  const handleUserCrop = async (crop: Crop): Promise<void> => {
    if (image && crop.width && crop.height) {
      try {
        await getCroppedImage(image, crop);
      } catch (e) {
        enqueueSnackbar('Error cropping image', {
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        console.log(e);
      }
    }
  };

  const imageCropComplete = (crop: Crop): Promise<void> => {
    return handleUserCrop(crop);
  };

  const handleSubmitOrg = (): void => {
    if (!file) return;
    const timestamp = new Date().getTime();
    const storageRef = ref(
      storage,
      `${LOGO_UPLOAD_PATH}/${timestamp}_${file.name}`
    );
    if (!cropImage) return;
    uploadBytes(storageRef, cropImage)
      .then((snapshot) => {
        const docRef = doc(db, `/organizations/${organization.id || null}`);
        updateDoc(docRef, {
          image_path: snapshot.metadata.fullPath,
        });
        navigate(`/organizations/${organization.name}/edit/`);
      })
      .catch((e) => {
        enqueueSnackbar('Error submitting new logo', {
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        console.log('error: ', e);
      });
  };

  const handleImageLoad = (
    e: React.SyntheticEvent<HTMLImageElement, Event>
  ): void => {
    setImage(e.target as HTMLImageElement);
  };

  const isFormComplete = file;

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Card sx={{ maxWidth: 500, mt: 2 }}>
          <CardHeader
            sx={{ pt: 3, pb: 0, textAlign: 'center' }}
            title={<Typography>Update Logo</Typography>}
          />
          <CardContent>
            <Box component='form' noValidate autoComplete='off'>
              <FileUploader
                name='file'
                handleChange={onFileChange}
                types={FILE_TYPES}
              />
              <Stack
                direction='row'
                spacing={2}
                sx={{ display: 'flex', mt: 2 }}
              >
                {croppedImageUrl && (
                  <img
                    alt='Crop'
                    width={200}
                    height={200}
                    src={croppedImageUrl}
                  />
                )}

                <Box
                  sx={{ width: 1, display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Button
                    variant='contained'
                    onClick={handleSubmitOrg}
                    disabled={!isFormComplete}
                    sx={{ alignSelf: 'flex-end', mt: 2, mb: 2, mr: 1 }}
                  >
                    Update
                  </Button>
                </Box>
              </Stack>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Dialog open={isImageCropOpen} onClose={handleCloseImageCrop}>
        <DialogTitle>Crop Image</DialogTitle>
        <DialogContent>
          {file && (
            <ReactCrop
              crop={crop}
              onChange={setCrop}
              aspect={1}
              onComplete={imageCropComplete}
            >
              <img
                src={URL.createObjectURL(file)}
                alt='Upload preview'
                onLoad={handleImageLoad}
              />
            </ReactCrop>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImageCrop}>Cancel</Button>
          <Button onClick={handleCloseImageCrop} disabled={!croppedImageUrl}>
            Crop
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export { EditOrganizationLogo };
