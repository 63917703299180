import React, { useState } from 'react';
import { Box, ButtonBase, Card, Typography } from '@mui/material';

import { useThumbnail } from 'hooks/useThumbnail';

interface Props {
  onSetThumbnail: (urlPath: string) => Promise<void>;
  thumbnails: string[];
  thumbnail?: string;
}

interface ThumbnailItemType {
  urlPath: string;
  isSelected: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

function ThumbnailItem({
  urlPath,
  isSelected,
  onClick,
}: ThumbnailItemType): JSX.Element {
  const { thumbnailSrc } = useThumbnail(urlPath);

  const selectedSx = {
    border: 5,
    borderColor: '#66bb6a',
    boxSizing: 'border-box',
  };

  return (
    <ButtonBase
      sx={isSelected ? selectedSx : {}}
      component='button'
      onClick={onClick}
      focusRipple
    >
      <img src={thumbnailSrc} width={360} height={200} />
    </ButtonBase>
  );
}

function SelectThumbnailCard({
  thumbnail: selectedThumbnail,
  thumbnails = [],
  onSetThumbnail,
}: Props): JSX.Element {
  const [selectIndex, setSelectedIndex] = useState(() =>
    thumbnails.findIndex((thumbnail) => selectedThumbnail === thumbnail)
  );

  const handleSelectThumbnail =
    (urlPath: string, index: number) => async (): Promise<void> => {
      await setSelectedIndex(index);
      await onSetThumbnail(urlPath);
    };

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minHeight: 200,
        mt: 3,
        pt: 1.5,
        pb: 2,
        pl: 3,
        pr: 3,
      }}
    >
      <Typography variant='h5' sx={{ mb: 0.5 }}>
        <strong>Select Thumbnail ({thumbnails.length})</strong>
      </Typography>

      <Box sx={{ display: 'flex', overflow: 'auto' }}>
        {thumbnails.map((urlPath: string, index: number) => {
          return (
            <ThumbnailItem
              key={index}
              onClick={handleSelectThumbnail(urlPath, index)}
              isSelected={selectIndex === index}
              urlPath={urlPath}
            />
          );
        })}
      </Box>
    </Card>
  );
}

export { SelectThumbnailCard };
