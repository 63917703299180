import React from 'react';
import { Card } from '@mui/material';
import ListItem from '@mui/material/ListItem';

import { VideoDocument } from 'store/models/videos';
import { IUser } from 'store/app/state/index';
import { NotificationDocument } from 'store/models/notifications';
import { CommentDocument } from 'store/models/comments';

import { NewsfeedListItem } from './NewsfeedListItem';

interface NewsfeedListType {
  notifications?: NotificationDocument[];
  comments?: CommentDocument[];
  /* the users that can be tagged in this comment collection */
  mentionableUsers?: IUser[];
  /* array of videoDocuments that a user can create a link for */
  linkableVideos?: VideoDocument[];
}

function NewsfeedList({
  notifications = [],
  comments = [],
  mentionableUsers,
  linkableVideos,
}: NewsfeedListType): JSX.Element {
  const newsfeedItems = [...comments, ...notifications];
  const sortedNewsfeedItems = newsfeedItems.sort((x, y) => {
    return (y.timestamp as any).seconds - (x.timestamp as any).seconds;
  });

  return (
    <>
      {(sortedNewsfeedItems || []).map((newsfeedItem: any) => (
        <ListItem sx={{ p: 0, mt: 1 }} key={newsfeedItem.id}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              pt: 1.25,
              pb: 1,
              pl: 1.25,
              pr: 1.25,
              width: '100%',
              position: 'relative',
            }}
          >
            <NewsfeedListItem
              newsfeedItem={newsfeedItem}
              mentionableUsers={mentionableUsers as IUser[]}
              linkableVideos={linkableVideos}
            />
          </Card>
        </ListItem>
      ))}
    </>
  );
}

export { NewsfeedList };
