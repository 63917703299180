import {
  Avatar,
  AvatarGroup,
  Box,
  ButtonBase,
  Chip,
  LinearProgress,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import { useThumbnail } from 'hooks/useThumbnail';
import {
  VideoDocument,
  VIDEO_LIST_TYPE,
  PendingVideoDocument,
} from 'store/models/videos';
import { formatSeconds } from 'store/shared/formatSeconds';

interface VideoListItemType {
  variant: VIDEO_LIST_TYPE;
  data: VideoDocument | PendingVideoDocument;
}

function VideoListItem({ data, variant }: VideoListItemType): JSX.Element {
  /**
   * these are all props that are fed from different schemas of data,
   * so we use switch/case to populate them accordingly.
   */
  let video: VideoDocument; // to avoid declaration error;
  let width = 360;
  let height = 200;
  let duration = undefined;
  let src = '';
  let titleText = '';
  let descriptionText = '';
  let people: string[] = [];
  let categories: string[] = [];
  const containerSx: SxProps<Theme> = {
    width: 1,
    maxHeight: 200,
    textAlign: 'start',
  };
  let containerProps: any = { focusRipple: true };

  switch (variant) {
    case VIDEO_LIST_TYPE.ORG_DETAIL:
      width = 168;
      height = 93;
      break;
    case VIDEO_LIST_TYPE.VIDEO_LINK:
      width = 180;
      height = 100;

      video = data as VideoDocument;
      src = video.thumbnail || video.thumbnails?.[0] || '';

      titleText = video.metadata.title;
      descriptionText = video.metadata.description;

      containerSx.textAlign = 'center';

      break;
    case VIDEO_LIST_TYPE.PENDING:
      // eslint-disable-next-line no-case-declarations
      const pendingVideo = data as PendingVideoDocument;
      // eslint-disable-next-line no-case-declarations
      const miniVideos = Object.values(pendingVideo.videos);
      src = miniVideos?.[0]?.thumbnails?.[0];

      titleText = pendingVideo.title || '';
      descriptionText = pendingVideo.description || '';
      people = pendingVideo.surgeons;

      containerSx.opacity = '0.5';
      containerSx.pointerEvents = 'none';

      break;
    default: // STANDARD
      video = data as VideoDocument;
      src = video.thumbnail || video.thumbnails?.[0] || '';

      titleText = video.metadata.title;
      descriptionText = video.metadata.description;
      people = video.metadata.surgeons;
      categories = video.metadata.categories;
      duration = video.metadata.duration;

      containerProps = {
        component: Link,
        to: `/videos/${video.id}`,
      };
      break;
  }

  return (
    <VideoListItemComponent
      categories={categories}
      containerProps={containerProps}
      containerSx={containerSx}
      created_at={data.created_at}
      description={descriptionText}
      people={people}
      thumbnail={{ width, height, src, duration }}
      title={titleText}
      isPending={variant === VIDEO_LIST_TYPE.PENDING}
    />
  );
}

/**
 * presentational component only. no logic
 */
interface VideoListItemComponentType {
  thumbnail: {
    height: number;
    src: string;
    width: number;
    duration?: string;
  };
  title: string;
  description: string;
  people: string[];
  categories: string[];
  created_at: Timestamp;
  containerProps: any;
  containerSx: any;
  isPending?: boolean;
}
function VideoListItemComponent({
  thumbnail: { height, src, width, duration },
  title,
  description,
  created_at,
  containerProps,
  containerSx,
  isPending = false,
  categories,
  people,
}: VideoListItemComponentType): JSX.Element {
  const { thumbnailSrc } = useThumbnail(src, { width, height });

  return (
    <ButtonBase {...containerProps} sx={containerSx}>
      <Stack direction='row' sx={{ width: 1 }}>
        {/* thumbnail container */}
        <Box sx={{ position: 'relative', width, height }}>
          <img
            src={thumbnailSrc}
            width={width}
            height={height}
            alt='thumbnail'
          />
          {duration && (
            <Box
              sx={{
                backgroundColor: 'black',
                color: 'white',
                position: 'absolute',
                right: 7.5,
                bottom: 7.5,
                pl: '3px',
                pr: '3px',
              }}
            >
              {formatSeconds(duration)}
            </Box>
          )}
        </Box>
        {/* info container */}
        <Stack
          direction='column'
          alignItems='flex-start'
          sx={{ width: 1, overflow: 'hidden' }}
        >
          {title && (
            <Typography
              variant='h6'
              noWrap
              sx={{ width: 1, textOverflow: 'ellipsis', p: 2, pt: 0, pb: 0 }}
            >
              {title}
            </Typography>
          )}
          {created_at && (
            <Typography
              sx={{
                width: 1,
                color: 'text.secondary',
                textOverflow: 'ellipsis',
                p: 2,
                pt: 0,
                pb: 0,
              }}
              noWrap
            >
              {moment(created_at.toDate()).calendar()}
            </Typography>
          )}
          {people.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: 2,
                pt: 0,
                pb: 0,
                mt: 1,
                mb: 1,
                overflow: 'auto',
              }}
            >
              <AvatarGroup>
                {people.map((person) => {
                  return (
                    <Avatar
                      key={person}
                      color='primary'
                      sx={{ mr: 1, width: 30, height: 30 }}
                    >
                      {person.slice(0, 1)}
                    </Avatar>
                  );
                })}
              </AvatarGroup>
            </Box>
          )}
          {description && (
            <Typography
              sx={{
                color: 'text.secondary',
                textOverflow: 'ellipsis',
                p: 2,
                pt: 0,
                pb: 0,
                flex: 1,
                width: 1,
              }}
              gutterBottom
              noWrap
            >
              {description}
            </Typography>
          )}
          {categories.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: 2,
                pt: 0,
                pb: 0,
                mt: 1,
                mb: 1,
                overflow: 'auto',
              }}
            >
              {categories.sort().map((category) => {
                return (
                  <Chip
                    key={category}
                    label={category}
                    color='primary'
                    variant='outlined'
                    size='small'
                    sx={{ mr: 1, borderRadius: '0px' }}
                  />
                );
              })}
            </Box>
          )}

          {isPending && <LinearProgress sx={{ ml: 2, width: '100%' }} />}
        </Stack>
      </Stack>
    </ButtonBase>
  );
}

export { VideoListItem };
