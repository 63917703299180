// https://firebase.google.com/docs/web/setup#available-libraries
import { firebaseapp } from './firebase';
import firebase from 'firebase/compat/app';
import { getAuth, connectAuthEmulator, Auth } from 'firebase/auth';
import {
  getFirestore,
  connectFirestoreEmulator,
  Firestore,
} from 'firebase/firestore';
import {
  getStorage,
  connectStorageEmulator,
  FirebaseStorage,
} from 'firebase/storage';
import React, { ReactNode, createContext, useContext } from 'react';

interface IFirebaseContext {
  app?: firebase.app.App;
  auth: Auth;
  db: Firestore;
  storage: FirebaseStorage;
}

const FirebaseContext = createContext<IFirebaseContext>({
  auth: getAuth(),
  db: getFirestore(),
  storage: getStorage(),
});

function FirebaseProvider({ children }: { children: ReactNode }): JSX.Element {
  const app = firebaseapp;
  const auth = getAuth(app);
  const db = getFirestore(app);
  const storage = getStorage(app);

  if (process.env.REACT_APP_ENV === 'development') {
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectFirestoreEmulator(db, 'localhost', 8080);
    connectStorageEmulator(storage, 'localhost', 9199);
  }

  return (
    <FirebaseContext.Provider value={{ app, auth, db, storage }}>
      {children}
    </FirebaseContext.Provider>
  );
}

function useFirebase(): IFirebaseContext {
  const context = useContext(FirebaseContext);

  if (context === undefined) {
    throw new Error('useFirebase must be used within a FirebaseProvider');
  }

  return context;
}

export { FirebaseProvider, useFirebase };
