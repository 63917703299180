import { useState, useEffect } from 'react';
import { useFirebase } from 'hooks/useFirebase';
import { getDownloadURL, ref } from 'firebase/storage';

export const getThumbnail = (width: number, height: number): string => {
  return `https://dummyimage.com/${width}x${height}/000/fff`;
};

interface OptionsType {
  height?: number;
  width?: number;
  // automatically calculates height based on ratio; optimal ratio is 9 / 5
  aspectRatio?: { x: number; y: number };
}

interface useThumbnailType {
  thumbnailSrc: string;
  isLoading: boolean;
  width: number;
  height: number;
  resize: (width?: number, height?: number) => void;
}

function performCalcs(
  width = 360,
  height?: number,
  ratio = { x: 360, y: 200 }
): { width: number; height: number } {
  const calcedHeight = height || Math.round((width * ratio.y) / ratio.x);

  return { width, height: calcedHeight };
}

function useThumbnail(
  urlPath: string,
  options: OptionsType = {}
): useThumbnailType {
  const [isLoading, setIsLoading] = useState(false);
  const { storage } = useFirebase();
  const [calcs, setCalcs] = useState(
    performCalcs(options.width, options.height, options.aspectRatio)
  );
  const [thumbnailSrc, setThumbnailSrc] = useState(
    getThumbnail(calcs.width, calcs.height)
  );

  useEffect(() => {
    async function fetchUrl(): Promise<void> {
      if (urlPath) {
        setIsLoading(true);
        const storageRef = ref(storage, urlPath);
        const result = await getDownloadURL(storageRef);
        setThumbnailSrc(result);
        setIsLoading(false);
      }
    }
    fetchUrl();
  }, [storage, urlPath]);

  return {
    thumbnailSrc,
    isLoading,
    width: calcs.width,
    height: calcs.height,
    resize: (width, height) => {
      const newCalcs = performCalcs(width, height);
      setCalcs({
        width: newCalcs.width,
        height: newCalcs.height,
      });
    },
  };
}

export { useThumbnail };
