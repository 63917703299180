import { Timestamp, FieldValue } from 'firebase/firestore';

export type FORMAT_TYPE =
  | 'video/mp4'
  | 'video/mov'
  | 'video/webm'
  | 'video/quicktime'
  | 'video/ogg';

export enum VIDEO_TYPE {
  HIGHLIGHT = 'highlight',
  MAIN = 'main',
}

export enum TRANSITION_STATE {
  PREPARING = 'preparing',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum VIDEO_LIST_TYPE {
  PENDING = 'pending',
  STANDARD = 'standard',
  ORG_DETAIL = 'org-detail',
  VIDEO_LINK = 'video-link',
}

export interface PendingVideoMapType {
  /** document identifier */
  id: string;
  file_name: string;
  /** bucket path to retrieve video */
  storage_path: string;
  /** bucket paths to retrieve thumbnail */
  thumbnails: string[];
  /** order in which the video should be stiched */
  upload_order: number;
  uuid: string;
}

export interface PendingVideoDocument {
  /** document identifier */
  id: string;
  /** video belongs to which user */
  user_id: string;
  /** video belongs to which organization */
  organization_id: string;
  status: TRANSITION_STATE;
  /** metadata information */
  title?: string;
  description?: string;
  /** list of video_paths so it's easily queryable */
  storage_paths: string[];
  videos: {
    [uuid: string]: PendingVideoMapType;
  };
  /** no. of time video failed to be stiched */
  retry_count: number;
  /** no. of error messages */
  error_messages?: string[];
  created_at: Timestamp;
  surgeons: string[];
  categories: string[];
}

export interface ClipDocument {
  /** document identifier */
  id: string;
  /** video belongs to which user */
  user_id: string;
  /** video belongs to which organization */
  organization_id: string;
  /** clips belongs to which master video */
  video_id: string;
  metadata: {
    description: string;
    startTime: number;
    endTime: number;
  };
  /** has download been requested */
  is_download_requested: boolean;
  /** bucket path to retrieve video */
  storage_path?: string;
  created_at: FieldValue;
}

export interface VideoDocument {
  /** document identifier */
  id: string;
  /** user which uploaded the video */
  user_id: string;
  /** video belongs to which organization */
  organization_id: string;
  /** if it belongs to another video */
  video_id?: string;
  /** standard video or archived */
  is_archived: boolean;
  /** highlight or main */
  video_type: VIDEO_TYPE;
  /** video format */
  format_type: FORMAT_TYPE;
  /** more details regarding video */
  metadata: {
    description: string;
    duration?: string;
    thumbnail_path?: string;
    size?: string;
    title: string;
    surgeons: string[];
    categories: string[];
  };
  preview_thumbnails?: string[];
  thumbnails?: string[];
  thumbnail?: string;
  /** bucket path to retrieve video */
  storage_path: string;
  /** number of video, updated in backend */
  comment_count: number;
  /** time of created */
  created_at: Timestamp;
  /** last updated */
  updated_at: Timestamp;
}
