import SortIcon from '@mui/icons-material/Sort';
import {
  Box,
  Button,
  ButtonBase,
  Chip,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { collection, orderBy, query, where, doc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import {
  useCollectionDataOnce,
  useCollectionData,
  useDocumentDataOnce,
} from 'react-firebase-hooks/firestore';
import { Link } from 'react-router-dom';

import { useFirebase } from 'hooks/useFirebase';
import { useStore } from 'hooks/useStore';
import {
  VideoDocument,
  TRANSITION_STATE,
  VIDEO_LIST_TYPE,
} from 'store/models/videos';

import { VideoListItem } from './VideoListItem';

function VideoCollection(): JSX.Element {
  const {
    state: { organization },
  } = useStore();
  const { db } = useFirebase();

  const pendingQueryRef = query(
    collection(db, 'pending-videos'),
    where('organization_id', '==', organization.id),
    where('status', '==', TRANSITION_STATE.PROCESSING)
  );
  const queryRef = query(
    collection(db, 'videos'),
    where('organization_id', '==', organization.id),
    where('is_archived', '==', false),
    orderBy('created_at', 'desc')
  );
  const [pendingVideos] = useCollectionData(pendingQueryRef);
  const [videos] = useCollectionData(queryRef);

  const [orgData] = useDocumentDataOnce(
    organization.id ? doc(db, `/organizations/${organization.id}`) : null
  );
  const [orgUsers] = useCollectionDataOnce(
    query(
      collection(db, 'users'),
      where('organization_id', '==', organization.id)
    )
  );

  const [categoriesClicked, setCategoriesClicked] = useState<string[]>([]);
  const [surgeonsClicked, setSurgeonsClicked] = useState<string[]>([]);

  const [surgeons, setSurgeons] = useState<string[]>([]);

  const [isFilterToggled, setIsFilterToggled] = useState(false);

  useEffect(() => {
    if (orgUsers)
      setSurgeons(
        orgUsers?.map((orgUser) => orgUser.name).concat(orgData?.surgeons)
      );
  }, [orgUsers, orgData]);

  const handleCategoriesToggle = (id: string) => () => {
    const isIdIncluded = categoriesClicked.includes(id);

    if (isIdIncluded) {
      setCategoriesClicked((currentClicked) =>
        currentClicked.filter((currentId) => currentId !== id)
      );
    } else {
      setCategoriesClicked((currentClicked) => [...currentClicked, id]);
    }
  };

  const handleSurgeonsToggle = (id: string) => () => {
    const isIdIncluded = surgeonsClicked.includes(id);

    if (isIdIncluded) {
      setSurgeonsClicked((currentClicked) =>
        currentClicked.filter((currentId) => currentId !== id)
      );
    } else {
      setSurgeonsClicked((currentClicked) => [...currentClicked, id]);
    }
  };

  return (
    <Container maxWidth='md'>
      <Stack direction='row' justifyContent='space-between' sx={{ mt: 2 }}>
        <Button
          onClick={() => setIsFilterToggled(!isFilterToggled)}
          startIcon={<SortIcon />}
        >
          Filters
        </Button>

        <ButtonBase component={Link} to='/videos/my-videos'>
          <Typography variant='button' sx={{ pt: 0.5, pb: 0.5, pl: 1, pr: 1 }}>
            MY VIDEOS
          </Typography>
        </ButtonBase>
      </Stack>

      {isFilterToggled && (
        <Stack direction='row' spacing={3} sx={{ mb: 1, mt: 2 }}>
          <Stack
            direction='column'
            spacing={0.5}
            sx={{ overflow: 'auto', width: '20%' }}
          >
            <Typography variant='overline'>CATEGORIES</Typography>
            <Divider sx={{ mb: 1, mt: 1 }} />
            {(organization.categories || []).sort().map((category) => {
              return (
                <Box key={category}>
                  <Chip
                    label={category}
                    onClick={handleCategoriesToggle(category)}
                    sx={{ borderColor: 'transparent', borderRadius: '2px' }}
                    variant={
                      categoriesClicked.indexOf(category) !== -1
                        ? 'filled'
                        : 'outlined'
                    }
                  />
                </Box>
              );
            })}
          </Stack>
          <Stack
            direction='column'
            spacing={0.5}
            sx={{ overflow: 'auto', width: '20%' }}
          >
            <Typography variant='overline'>SURGEONS</Typography>
            <Divider sx={{ mb: 1, mt: 1 }} />
            {(surgeons || []).sort().map((surgeon, index) => {
              return (
                <Box key={index}>
                  <Chip
                    label={surgeon}
                    onClick={handleSurgeonsToggle(surgeon)}
                    sx={{ borderColor: 'transparent', borderRadius: '2px' }}
                    variant={
                      surgeonsClicked.indexOf(surgeon) !== -1
                        ? 'filled'
                        : 'outlined'
                    }
                  />
                </Box>
              );
            })}
          </Stack>
        </Stack>
      )}

      <Divider sx={{ mb: 2, mt: 1 }} />

      <Stack direction='column' spacing={2}>
        {(pendingVideos || []).map((pendingVideo: any) => {
          return (
            <VideoListItem
              key={pendingVideo.id}
              data={pendingVideo}
              variant={VIDEO_LIST_TYPE.PENDING}
            />
          );
        })}
        {(videos || []).map((video) => {
          if (
            categoriesClicked?.every((val) =>
              video?.metadata?.categories?.includes(val)
            ) &&
            (surgeonsClicked?.length === 0 ||
              surgeonsClicked?.some((val) =>
                video?.metadata?.surgeons?.includes(val)
              ))
          )
            return (
              <VideoListItem
                key={video.id}
                data={video as VideoDocument}
                variant={VIDEO_LIST_TYPE.STANDARD}
              />
            );
        })}
      </Stack>
    </Container>
  );
}

export { VideoCollection };
