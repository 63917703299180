import React, { useState } from 'react';
import { Box, Button, Card, TextField } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import { useSnackbar } from 'notistack';

import { FILE_TYPES } from 'pages/Video/VideoUpload';

const containerSx = {
  display: 'flex',
  minWidth: 600,
  mt: 3,
  ml: 1.5,
  pt: 2,
  pb: 2,
  pl: 3,
  pr: 4,
};

interface Props {
  onAddAsset: (title: string, description: string, file: File) => void;
  isAssetLoading: boolean;
}

function CreateHighlightCard({
  onAddAsset,
  isAssetLoading,
}: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState<File>();

  const handleFileChange = (file: File): void => {
    setFile(file);
  };

  const handleCreateHighlight = async (): Promise<void> => {
    if (!file) return;
    try {
      await onAddAsset(title, description, file);

      setTitle('');
      setDescription('');
      setFile(undefined);
    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar('Error creating highlight', {
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        console.log(e.message);
      } else {
        enqueueSnackbar('Unexpected error', {
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        console.log('Unexpected error', e);
      }
    }
  };

  const isFormComplete = title && description && file;

  return (
    <Card sx={containerSx}>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        <TextField
          label='title'
          value={title}
          margin='normal'
          fullWidth
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />

        <TextField
          label='description'
          value={description}
          margin='normal'
          fullWidth
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />

        <FileUploader
          name='file'
          types={FILE_TYPES}
          handleChange={handleFileChange}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
        <img
          src='https://dummyimage.com/300x166/000/fff'
          width={300}
          height={166}
        />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            pt: 2,
            pb: 2,
          }}
        >
          <Button
            variant='contained'
            onClick={handleCreateHighlight}
            disabled={isAssetLoading || !isFormComplete}
          >
            + Add Highlight
          </Button>
        </Box>
      </Box>
    </Card>
  );
}

export { CreateHighlightCard };
