import { Container, Stack, Chip, Box } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import React, { useState } from 'react';

import { PendingVideoTable } from './PendingVideoTable';
import { ProcessedVideoTable } from './ProcessedVideoTable';

function VideoCollection(): JSX.Element {
  const [isPending, setIsPending] = useState(false);

  const PENDING_LABEL = 'pending';
  const PROCESSED_LABEL = 'processed';

  const activeSx = { color: 'primary', background: 'white' };
  const inactiveSx = { color: 'white', background: '#a6a6a6' };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        background: '#EEEEEE',
        overflow: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Container maxWidth='md'>
        <Stack direction='row' spacing={2} sx={{ mt: 2 }}>
          <Chip
            sx={isPending ? activeSx : inactiveSx}
            label={PENDING_LABEL}
            clickable={!isPending}
            onClick={() => !isPending && setIsPending(true)}
          />
          <Chip
            sx={!isPending ? activeSx : inactiveSx}
            label={PROCESSED_LABEL}
            clickable={isPending}
            onClick={() => isPending && setIsPending(false)}
          />
        </Stack>
        <TableContainer
          component={Paper}
          sx={{ mt: 2, '&:last-child div': { mt: 0 } }}
        >
          {isPending && <PendingVideoTable />}
          {!isPending && <ProcessedVideoTable isAdmin={true} />}
        </TableContainer>
      </Container>
    </Box>
  );
}

export { VideoCollection };
