import { SET_AUTH, SET_USER_DATA, LOGOUT } from 'constants/actions';
import { IAction } from 'store/shared/types';
import { IState, initialState } from 'store/app/state';
import { SET_USER_ORGANIZATION } from 'constants/actions';

export function storeReducer(
  state = initialState,
  { type, payload }: IAction
): IState {
  switch (type) {
    case SET_AUTH:
      return {
        ...state,
        authenticated: payload,
      };
    case SET_USER_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      };

    case SET_USER_ORGANIZATION:
      return {
        ...state,
        organization: payload,
      };
    case LOGOUT:
      return {
        ...state,
        authenticated: false,
        user: { ...initialState.user },
      };
    default:
      return state;
  }
}
