import { Box, Typography, Stack, LinearProgress } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';

import { Thumbnail } from './Thumbnail';
import { ProgressType } from './types';

function VideoProgressCard({ video, progress }: ProgressType): JSX.Element {
  const progressVal = Math.round(
    (progress.bytesTransferred / progress.bytes) * 100
  );
  const progressLabel =
    progressVal === 100
      ? 'Uploading...'
      : `${progress.bytesTransferred} / ${progress.bytes}`;

  return (
    <Box sx={{ display: 'flex', width: '100%', opacity: 0.7 }}>
      <Box sx={{ flex: 1, pr: 2 }}>
        <KeyboardArrowUpIcon />
        <Box sx={{ flex: 1 }}>
          <Typography>{`${' '}`}</Typography>
        </Box>
        <KeyboardArrowDownIcon />
      </Box>
      <Thumbnail />
      <Stack spacing={1} sx={{ width: '80%', p: 2 }}>
        <Typography sx={{ overflow: 'auto' }} noWrap>
          {video.fileName}
        </Typography>
        <LinearProgress
          sx={{
            height: 10,
            borderRadius: 2,
            backgroundColor: 'primary.success',
          }}
          variant='determinate'
          value={progressVal}
        />
        <Typography sx={{ overflow: 'auto' }} noWrap>
          {progressLabel}
        </Typography>
      </Stack>
      <Box sx={{ flex: 1 }}>
        <Typography>{`${' '}`}</Typography>
      </Box>
    </Box>
  );
}

export { VideoProgressCard };
