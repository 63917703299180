import React, { useState } from 'react';
import { Box, Dialog, Grid } from '@mui/material';
import { VideoDocument } from 'store/models/videos';

import { IUser } from 'store/app/state/index';
import { Comment } from 'components/comments/Comment';
import {
  NotificationDocument,
  NOTIFICATION_TYPE,
} from 'store/models/notifications';
import { CommentDocument } from 'store/models/comments';

import { NewsfeedNewUserListItem } from './NewsfeedNewUserListItem';
import { NewsfeedVideoListItem } from './NewsfeedVideoListItem';

interface NewsfeedListItemType {
  newsfeedItem: NotificationDocument | CommentDocument;
  /* the users that can be tagged in this comment collection */
  mentionableUsers?: IUser[];
  /* array of videoDocuments that a user can create a link for */
  linkableVideos?: VideoDocument[];
}

function NewsfeedListItem({
  newsfeedItem,
  mentionableUsers,
  linkableVideos,
}: NewsfeedListItemType): JSX.Element {
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [modalSrc, setModalSrc] = useState('');

  const showPreview = (imageUrl: string): void => {
    setModalSrc(imageUrl);
    setPreviewOpen(true);
  };

  const closePreview = (): void => {
    setPreviewOpen(false);
    setModalSrc('');
  };
  const instanceOfNotificationDocument = (
    object: any
  ): object is NotificationDocument => {
    return (object as NotificationDocument).notification_type !== undefined;
  };
  if (instanceOfNotificationDocument(newsfeedItem)) {
    if (newsfeedItem.notification_type === NOTIFICATION_TYPE.NEW_USER) {
      return <NewsfeedNewUserListItem newsfeedItem={newsfeedItem} />;
    }
    if (newsfeedItem.notification_type === NOTIFICATION_TYPE.VIDEO_PROCESSED) {
      return <NewsfeedVideoListItem newsfeedItem={newsfeedItem} />;
    }
  } else {
    return (
      <>
        <Comment
          comment={newsfeedItem}
          showPreview={showPreview}
          mentionableUsers={mentionableUsers as IUser[]}
          linkableVideos={linkableVideos}
        />
        <Dialog open={isPreviewOpen} onClose={closePreview} maxWidth='lg'>
          <Grid container alignItems='center' justifyContent='center'>
            {modalSrc && (
              <Grid item xs={12}>
                <Box component='img' alt='image-placeholder' src={modalSrc} />
              </Grid>
            )}
          </Grid>
        </Dialog>
      </>
    );
  }
  return <></>;
}

export { NewsfeedListItem };
