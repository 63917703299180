import {
  Box,
  Card,
  Container,
  Dialog,
  List,
  ListItem,
  ListSubheader,
  Grid,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { collection, query, orderBy, where } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import { useFirebase } from 'hooks/useFirebase';
import { CommentDocument, COMMENT_VARIANT } from 'store/models/comments';
import { VideoDocument } from 'store/models/videos';
import { IUser } from 'store/app/state/index';

import { Comment } from './Comment';
import { CommentForm } from './CommentForm';

interface CommentCollectionType {
  /* if query data was passed by parent*/
  comments?: CommentDocument[];
  /* can posts be replied to with comments */
  isReplyDisabled?: boolean;
  /* the users that can be tagged in this comment collection */
  mentionableUsers?: IUser[];
  /* array of videoDocuments that a user can create a link for */
  linkableVideos?: VideoDocument[];
  /* callback invoked whenever a comment has been submitted */
  onSubmitCompleted?: () => void;
  /**
   * when variant === timestamp
   * callback invoked whenever a video has been seeked
   */
  onSeek?: (number: number) => void;
  /* the page which comments reside in */
  page: string;
  /* styling to fit purpose */
  variant?: COMMENT_VARIANT;
  /**
   * when variant === timestamp
   * time of video which is being referenced
   */
  videoTimestamp?: number | null;
  previewThumbnails?: string[];
  /* id of user who created video */
  videoAuthorId?: string;
  /* id of video where this comment collection resides */
  videoId?: string;
  /* title of video where this comment collection resides */
  videoTitle?: string;
}

interface VideoTimestampWrapper {
  onSeek?: (number: number) => void;
  videoTimestamp: number;
  children: JSX.Element;
}

const ENGAGEMENT_PANEL_SCRIM_INDEX = 600;

function WithOnClick({
  onSeek,
  videoTimestamp,
  children,
}: VideoTimestampWrapper): JSX.Element {
  return videoTimestamp === null ? (
    children
  ) : (
    <Box
      sx={{ width: '100%' }}
      style={{ cursor: 'pointer' }}
      onClick={() => onSeek && onSeek(videoTimestamp)}
    >
      {children}
    </Box>
  );
}

function CommentCollection({
  comments,
  onSeek,
  isReplyDisabled = false,
  onSubmitCompleted,
  mentionableUsers,
  linkableVideos,
  page,
  variant = COMMENT_VARIANT.DEFAULT,
  videoTimestamp = null,
  videoAuthorId,
  videoId,
  videoTitle,
}: CommentCollectionType): JSX.Element {
  const { db } = useFirebase();
  const [rootComments = [], _rootCommentsLoading, _rootCommentsError] =
    useCollectionData(
      comments !== null
        ? query(
            collection(db, 'comments'),
            where('parentId', '==', null),
            where('page', '==', page),
            orderBy('timestamp', 'desc')
          )
        : null
    );

  const finalComments = useMemo(
    () => (comments !== null ? comments : rootComments),
    [comments, rootComments]
  );

  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [modalSrc, setModalSrc] = useState('');

  const showPreview = (imageUrl: string): void => {
    setModalSrc(imageUrl);
    setPreviewOpen(true);
  };

  const closePreview = (): void => {
    setPreviewOpen(false);
    setModalSrc('');
  };

  const timestampModeSx = {
    borderRadius: '2px',
    borderColor: '#0088ee',
  };

  const involvedUserIds = videoAuthorId && [videoAuthorId];

  const addInvolvedUserId = (id: string): void => {
    if (involvedUserIds && involvedUserIds.indexOf(id) === -1)
      involvedUserIds?.push(id);
  };

  return (
    <>
      <Container
        disableGutters
        sx={{
          display: 'flex',
          alignContent: 'center',
          flexDirection: 'column',
          maxWidth: 768,
          height: 'inherit',
        }}
      >
        <List
          sx={{
            position: 'relative',
            height: 'inherit',
            overflow: 'scroll',
            pt: 0,
          }}
        >
          <ListSubheader
            sx={{
              p: 0,
              lineHeight: 'initial',
              overflow: 'visible',
              zIndex: ENGAGEMENT_PANEL_SCRIM_INDEX + 1,
              ...(videoTimestamp !== null && timestampModeSx),
            }}
          >
            <CommentForm
              onSubmitCompleted={onSubmitCompleted}
              mentionableUsers={mentionableUsers as IUser[]}
              page={page}
              showPreview={showPreview}
              variant={variant}
              linkableVideos={linkableVideos}
              videoTimestamp={videoTimestamp}
              {...(involvedUserIds && { involvedUserIds: involvedUserIds })}
              videoId={videoId}
              videoTitle={videoTitle}
            />
          </ListSubheader>
          {finalComments?.map((rootComment) => {
            addInvolvedUserId(rootComment.user_id);
            return (
              <ListItem sx={{ p: 0, mt: 1 }} key={rootComment.id}>
                <WithOnClick
                  onSeek={onSeek}
                  videoTimestamp={rootComment.videoTimestamp}
                >
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      pt: 1,
                      pb: 1,
                      pl: 2,
                      pr: 2,
                      width: '100%',
                      borderColor:
                        rootComment.videoTimestamp === null
                          ? undefined
                          : '#0088ee',
                      position: 'relative',
                    }}
                  >
                    <Comment
                      comment={rootComment as CommentDocument}
                      isReplyDisabled={isReplyDisabled}
                      showPreview={showPreview}
                      mentionableUsers={mentionableUsers as IUser[]}
                      videoTimestamp={rootComment.videoTimestamp}
                      variant={variant}
                      linkableVideos={linkableVideos}
                    />
                  </Card>
                </WithOnClick>
              </ListItem>
            );
          })}
        </List>
      </Container>
      <Dialog open={isPreviewOpen} onClose={closePreview} maxWidth='lg'>
        <Grid container alignItems='center' justifyContent='center'>
          {modalSrc && (
            <Grid item xs={12}>
              <Box component='img' alt='image-placeholder' src={modalSrc} />
            </Grid>
          )}
        </Grid>
      </Dialog>
    </>
  );
}

export { CommentCollection };
