import {
  Box,
  Badge,
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import React, { useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import {
  arrayUnion,
  collection,
  doc,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { useFirebase } from 'hooks/useFirebase';
import { useStore } from 'hooks/useStore';
import { NOTIFICATION_TYPE } from 'store/models/notifications';
import { NotificationDocument } from '../store/models/notifications';

function NotificationListItem({
  notification,
}: {
  notification: NotificationDocument;
}): JSX.Element {
  const { db } = useFirebase();
  const {
    state: { user, organization },
  } = useStore();
  const navigate = useNavigate();

  const [isViewed, setIsViewed] = useState(
    user.id ? notification.acknowledged_user_ids.includes(user.id) : false
  );

  const notificationMessage =
    notification.notification_type === NOTIFICATION_TYPE.VIDEO_PROCESSED
      ? `Your video, ${notification.metadata?.video_title}, has been processed and can be viewed.`
      : notification.notification_type === NOTIFICATION_TYPE.RELATED_VIDEO
      ? `${notification.metadata?.user_name} has made a comment on the video, ${notification.metadata?.video_title}.`
      : notification.notification_type === NOTIFICATION_TYPE.ORG_POST
      ? `${notification.metadata?.user_name} has made a post in your organization.`
      : `${notification.metadata?.user_name} has joined your organization.`;

  const to =
    notification.notification_type === NOTIFICATION_TYPE.VIDEO_PROCESSED ||
    notification.notification_type === NOTIFICATION_TYPE.RELATED_VIDEO
      ? `/videos/${notification.metadata?.video_id}`
      : `/organizations/${organization.name}/details`;

  const handleNotificationClick = async (): Promise<void> => {
    const notificationRef = doc(db, 'notifications', notification.id);
    setIsViewed(true);
    updateDoc(notificationRef, {
      acknowledged_user_ids: arrayUnion(user.id),
    });
    navigate(to);
  };

  return (
    <ListItem disablePadding sx={{ mt: 1 }}>
      <ListItemButton selected={!isViewed} onClick={handleNotificationClick}>
        <ListItemText
          primary={notificationMessage}
          secondary={
            <Typography
              sx={{ color: 'text.secondary' }}
              variant='caption'
              gutterBottom
            >
              {moment((notification?.timestamp as any).toDate()).calendar()}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}

function Notifications(): JSX.Element {
  const { db } = useFirebase();
  const {
    state: { user },
  } = useStore();
  const [notifications] = useCollectionData(
    query(
      collection(db, 'notifications'),
      where('involved_user_ids', 'array-contains', user.id),
      orderBy('timestamp', 'desc')
    )
  );

  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  const handleNotificationsClick = (): void => {
    setIsNotificationsOpen(!isNotificationsOpen);
  };

  return (
    <Box
      onClick={handleNotificationsClick}
      sx={{ p: 2, position: 'relative' }}
      style={{ cursor: 'pointer' }}
    >
      <Badge
        {...(user.notification_count !== 0 && {
          badgeContent: user.notification_count,
        })}
        color='error'
      >
        <NotificationsIcon />
      </Badge>
      {isNotificationsOpen && (
        <Card
          sx={{
            width: 500,
            height: 500,
            zIndex: 1,
            overflow: 'auto',
            position: 'absolute',
            top: 50,
            right: 10,
          }}
        >
          <List subheader={<ListSubheader>Notifications</ListSubheader>}>
            {(notifications || []).map((notification, index) => {
              return (
                <NotificationListItem
                  key={index}
                  notification={notification as NotificationDocument}
                />
              );
            })}
          </List>
        </Card>
      )}
    </Box>
  );
}

export { Notifications };
