import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Button, Box, Container, Typography } from '@mui/material';

import { useStore } from 'hooks/useStore';

function AdminDashboard(): JSX.Element {
  const {
    state: { user },
  } = useStore();

  const navigate = useNavigate();

  const handleNavToOrganizations = (): void => {
    navigate('/admin/dashboard/organizations');
  };

  const handleNavToPendingVideos = (): void => {
    navigate('/admin/dashboard/pending-videos');
  };

  if (!user.is_admin) {
    return <Navigate replace to='/' />;
  }

  return (
    <Container maxWidth='xs' sx={{ mt: 5 }}>
      <Typography variant='h4' sx={{ width: '100%', m: 3 }}>
        Admin Dashboard
      </Typography>
      <Box sx={{ display: 'flex' }}>
        <Button
          variant='contained'
          onClick={handleNavToOrganizations}
          sx={{ mr: 2 }}
        >
          Organizations
        </Button>

        <Button variant='contained' onClick={handleNavToPendingVideos}>
          Videos
        </Button>
      </Box>
    </Container>
  );
}

export { AdminDashboard };
