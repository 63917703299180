import LoadingButton from '@mui/lab/LoadingButton';
import { Container, Card, Stack, Typography, Button } from '@mui/material';
import React from 'react';
import { ClipDocument } from 'store/models/videos';

const ENGAGEMENT_PANEL_SCRIM_INDEX = 600;

const containerSx = {
  display: 'flex',
  alignContent: 'center',
  flexDirection: 'column',
  maxWidth: 768,
  height: 'fit-content',
  pt: 0.5,
  pb: 0.5,
  pr: 0.5,
  pl: 2,
  overflow: 'visible',
  zIndex: ENGAGEMENT_PANEL_SCRIM_INDEX + 1,
};

interface ClipDetailType {
  clip: ClipDocument;
  onDownloadVideo: () => void;
  onWatchFullVideo: () => void;
}

function ClipDetail({
  clip,
  onDownloadVideo,
  onWatchFullVideo,
}: ClipDetailType): JSX.Element {
  const isDownloading = clip.is_download_requested && !clip.storage_path;

  // console.log(`
  //   isDownloading: ${isDownloading}
  //   clip.is_download_requested: ${clip.is_download_requested}
  //   clip.storage_path: ${clip.storage_path}
  // `);
  return (
    <Container component={Card} disableGutters sx={containerSx}>
      <Stack direction='column' spacing={1}>
        {/* title */}
        <Stack direction='row' justifyContent='space-between'>
          <Typography variant='overline'>Clip Info</Typography>
          {/* <IconButton></IconButton> */}
        </Stack>
        {/* body */}
        <Stack direction='row'>
          <Typography>{clip.metadata.description}</Typography>
        </Stack>
        {/* CTA */}
        <Stack direction='row' justifyContent='flex-end' spacing={1}>
          <LoadingButton
            loading={isDownloading}
            loadingIndicator='Generating...'
            onClick={onDownloadVideo}
          >
            Download
          </LoadingButton>
          <Button variant='contained' onClick={onWatchFullVideo}>
            Watch Full
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}

export { ClipDetail };
