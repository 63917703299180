import { ref, uploadBytes } from '@firebase/storage';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { collection, doc, setDoc, Timestamp } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState, useRef, useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import './CommentForm.css';
import 'quill-mention';
import 'quill-mention/dist/quill.mention.css';

import { IMAGE_UPLOAD_PATH } from 'constants/paths';
import { useFirebase } from 'hooks/useFirebase';
import { useStore } from 'hooks/useStore';
import { VideoListItem } from 'pages/Video/VideoListItem';
import { IUser } from 'store/app/state/index';
import { CommentDocument, COMMENT_VARIANT } from 'store/models/comments';
import { VideoDocument, VIDEO_LIST_TYPE } from 'store/models/videos';
import { NotificationDocument } from 'store/models/notifications';
import { NOTIFICATION_TYPE } from 'store/models/notifications';
import { formatSeconds } from 'store/shared/formatSeconds';

import { ImageCarousel } from './ImageCarousel';

interface CommentFormType {
  /* different styling */
  containerComponent?: 'Paper' | 'Card';
  /* prop drills from containerComponent */
  containerProps?: any;
  mentionableUsers?: IUser[] | undefined;
  /* callback invoked when form is closed */
  onCloseForm?: () => void;
  /* callback invoked whenever a comment has been submitted */
  onSubmitCompleted?: () => void;
  page?: string;
  parentId?: string | null;
  showPreview?: (imageUrl: string) => void;
  variant?: COMMENT_VARIANT;
  videoTimestamp?: number | null;
  linkableVideos?: VideoDocument[] | undefined;
  involvedUserIds?: string[];
  /* id of video where this comment collection resides */
  videoId?: string;
  /* title of video where this comment collection resides */
  videoTitle?: string;
  /* is this comment form in a reply */
  isReplying?: boolean;
}

function CommentForm({
  containerComponent = 'Card',
  containerProps = {},
  mentionableUsers,
  onSubmitCompleted,
  onCloseForm,
  page,
  parentId = null,
  showPreview,
  variant = COMMENT_VARIANT.DEFAULT,
  videoTimestamp = null,
  linkableVideos,
  involvedUserIds,
  videoId,
  videoTitle,
  isReplying = false,
}: CommentFormType): JSX.Element {
  const { db, storage } = useFirebase();
  const {
    state: { user, organization },
  } = useStore();
  const { enqueueSnackbar } = useSnackbar();
  const [imagePaths, setImagePaths] = useState<string[]>([]);
  const [comment, setComment] = useState('');
  const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);
  const [timeText, setTimeText] = useState('0:00');
  const [time, setTime] = useState(0);
  const [isStartAtChecked, setIsStartAtChecked] = useState(false);
  const [isTimeWithHours, setIsTimeWithHours] = useState(true);
  const [isTimeWithoutHours, setIsTimeWithoutHours] = useState(true);
  const [videoIdAndTitle, setVideoIdAndTitle] = useState('');
  const [selection, setSelection] = useState<number | undefined>(0);
  const [videoSelection, setVideoSelection] = useState<number>();
  const [videoFilterText, setVideoFilterText] = useState('');

  const quillRef = useRef<ReactQuill>(null);

  // check timeText format and setTime and disable add link button if needed
  useEffect(() => {
    // check if the time is in X:XX format and set time
    if (/^([0-5][0-9]|[0-9]):([0-5][0-9])?$/.test(timeText))
      setTime(
        60 * parseInt(timeText.substring(0, timeText.indexOf(':')), 10) +
          parseInt(
            timeText.substring(timeText.indexOf(':') + 1, timeText.length),
            10
          )
      );
    // check if the time is in X:XX:XX format and set time
    if (/^(([1-9][0-9]+)|([1-9])):([0-5][0-9]):([0-5][0-9])?$/.test(timeText))
      setTime(
        3600 * parseInt(timeText.substring(0, timeText.indexOf(':')), 10) +
          60 *
            parseInt(
              timeText.substring(
                timeText.indexOf(':') + 1,
                timeText.indexOf(':', timeText.indexOf(':') + 1)
              ),
              10
            ) +
          parseInt(
            timeText.substring(
              timeText.indexOf(':', timeText.indexOf(':') + 1) + 1,
              timeText.length
            ),
            10
          )
      );
    setIsTimeWithoutHours(/^([0-5][0-9]|[0-9]):([0-5][0-9])?$/.test(timeText));
    setIsTimeWithHours(
      /^(([1-9][0-9]+)|([1-9])):([0-5][0-9]):([0-5][0-9])?$/.test(timeText)
    );
  }, [timeText]);

  const filteredInvolvedUserIds = mentionableUsers
    ? mentionableUsers
        .map((mentionableUser) => {
          if (mentionableUser.id) return mentionableUser.id;
          else return '';
        })
        .filter((id) => id !== user.id)
    : involvedUserIds
    ? involvedUserIds.filter((id) => id !== user.id)
    : [];

  const Link = Quill.import('formats/link');
  Link.sanitize = function (url: string) {
    // prefix default protocol.
    let protocol = url.slice(0, url.indexOf(':'));
    if (this.PROTOCOL_WHITELIST.indexOf(protocol) === -1) {
      url = 'http://' + url;
    }
    // Link._sanitize function
    const anchor = document.createElement('a');
    anchor.href = url;
    protocol = anchor.href.slice(0, anchor.href.indexOf(':'));
    return this.PROTOCOL_WHITELIST.indexOf(protocol) > -1
      ? url
      : this.SANITIZED_URL;
  };
  Quill.register(Link, true);

  const modules = useMemo(() => {
    if ([COMMENT_VARIANT.SIMPLE, COMMENT_VARIANT.TIMESTAMP].includes(variant)) {
      return { toolbar: false };
    }
    let allPeople: any[] = [];
    async function suggestPeople(
      searchTerm: string
    ): Promise<{ id: number; value: string }[]> {
      if (mentionableUsers) {
        allPeople = mentionableUsers.map((user, index) => {
          return {
            id: index + 1,
            value: user.name,
          };
        });
      }
      return allPeople.filter((person) => person.value.includes(searchTerm));
    }
    return {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ align: [] }],
          ['link', 'image'],
          ['clean'],
          [{ color: [] }],
          ['video'],
        ],
        handlers: {
          image: imageHandler,
          video: videoLinkHandler,
        },
      },
      mention: {
        allowedChars: /^[A-Za-z\s]*$/,
        mentionDenotationChars: ['@'],
        source: async function (searchTerm: string, renderList: any) {
          const matchedPeople = await suggestPeople(searchTerm);
          renderList(matchedPeople);
        },
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentionableUsers, variant]);

  const handleAddComment = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    const commentsCollection =
      parentId == null
        ? collection(db, 'comments')
        : collection(db, 'comments', parentId, 'comments');

    const newCommentRef = doc(commentsCollection);
    const commentData: CommentDocument = {
      comment,
      user_id: user.id,
      userName: user.name,
      userAvatar: user.avatarSrc,
      id: newCommentRef.id,
      parentId,
      timestamp: Timestamp.fromDate(new Date()),
      image_paths: imagePaths,
      videoTimestamp: variant === 'timestamp' ? videoTimestamp : null,
      child_comment_count: 0,
    };
    if (page != null) {
      commentData.page = page;
    }
    setDoc(newCommentRef, commentData);
    if (parentId == null) {
      const newNotificationRef = doc(collection(db, 'notifications'));
      const notificationData: NotificationDocument = {
        organization_id: organization.id,
        user_id: user.id,
        acknowledged_user_ids: [],
        involved_user_ids: filteredInvolvedUserIds,
        notification_type:
          variant === COMMENT_VARIANT.DEFAULT
            ? NOTIFICATION_TYPE.ORG_POST
            : NOTIFICATION_TYPE.RELATED_VIDEO,
        metadata:
          variant === COMMENT_VARIANT.DEFAULT
            ? { user_name: user.name }
            : {
                user_name: user.name,
                video_id: videoId,
                video_title: videoTitle,
              },
        timestamp: new Date(),
        id: newNotificationRef.id,
      };
      setDoc(newNotificationRef, notificationData);
    }
    setComment('');
    quillRef.current?.getEditor().setText('');
    setImagePaths([]);
    onSubmitCompleted?.();
    onCloseForm?.();
  };

  function handleAddImage(url: string): void {
    setImagePaths((prevState) => [...prevState, url]);
  }

  function imageHandler(): void {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      if (input == null || !input?.files) {
        return;
      }
      const file: any = input.files[0];
      const formData = new FormData();

      formData.append('image', file);

      const timestamp = new Date().getTime();

      const storageRef = ref(
        storage,
        `${IMAGE_UPLOAD_PATH}/${timestamp}_${file.name}`
      );
      try {
        const snapshot = await uploadBytes(storageRef, file);
        handleAddImage(snapshot.metadata.fullPath);
      } catch (error) {
        enqueueSnackbar('Error adding image', {
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        console.log(error);
      }
    };
  }

  function videoLinkHandler(): void {
    setSelection(quillRef.current?.getEditor().getSelection()?.index);
    setIsVideoDialogOpen(true);
  }

  async function handleCreateVideoLinkAndClose(): Promise<void> {
    setIsVideoDialogOpen(false);
    const delta = {
      ops: [
        ...(comment !== '' && selection ? [{ retain: selection }] : []),
        {
          ...(isStartAtChecked
            ? {
                insert:
                  videoIdAndTitle.substring(
                    videoIdAndTitle.indexOf(',') + 1,
                    videoIdAndTitle.length
                  ) +
                  '@' +
                  timeText +
                  '',
                attributes: {
                  link: `${
                    process.env.REACT_APP_PLATFORM_URL
                  }/videos/${videoIdAndTitle.substring(
                    0,
                    videoIdAndTitle.indexOf(',')
                  )}?t=${time}`,
                },
              }
            : {
                insert: videoIdAndTitle.substring(
                  videoIdAndTitle.indexOf(',') + 1,
                  videoIdAndTitle.length
                ),
                attributes: {
                  link: `${
                    process.env.REACT_APP_PLATFORM_URL
                  }/videos/${videoIdAndTitle.substring(
                    0,
                    videoIdAndTitle.indexOf(',')
                  )}`,
                },
              }),
        },
      ],
    };
    await quillRef.current?.getEditor().updateContents(delta as any);
    quillRef.current
      ?.getEditor()
      .setSelection(
        selection
          ? selection +
              (videoIdAndTitle.length - videoIdAndTitle.indexOf(',') - 1)
          : videoIdAndTitle.length - videoIdAndTitle.indexOf(',') - 1,
        0
      );
    setVideoIdAndTitle('');
    setTimeText('0:00');
    setTime(0);
    setIsTimeWithHours(true);
    setIsTimeWithoutHours(true);
    setIsStartAtChecked(false);
  }

  const handleCloseVideoDialog = (): void => {
    setIsVideoDialogOpen(false);
  };

  const handleTimeTextChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setTimeText(e.target.value);
  };

  const handleTimeTextOnBlur = (): void => {
    if (!isTimeWithoutHours && !isTimeWithHours) {
      setTimeText('0:00');
    }
  };

  const handleIsStartAtCheckedToggle = (): void => {
    setIsStartAtChecked(!isStartAtChecked);
  };

  const handleSetVideoLinkStates = (
    id: string,
    title: string,
    index: number
  ): void => {
    setVideoIdAndTitle(id + ',' + title);
    setVideoSelection(index);
  };

  const commentHeaderText = {
    [COMMENT_VARIANT.SIMPLE]: 'Write a comment',
    [COMMENT_VARIANT.DEFAULT]: 'Write a comment',
    [COMMENT_VARIANT.TIMESTAMP]: 'Reference a timestamp',
  }[variant];

  const submitText = parentId ? 'COMMENT' : 'POST';
  const ContainerComponent = (
    containerComponent === 'Paper' ? Paper : Card
  ) as React.ElementType;

  return (
    <>
      <ContainerComponent
        sx={{
          pt: 0.5,
          pb: 0.5,
          pr: 2,
          pl: 2,
          overflow: 'visible',
          width: '100%',
        }}
        {...containerProps}
      >
        <Stack direction='column' spacing={1}>
          <Stack direction='row'>
            <Typography variant='overline'>{commentHeaderText}</Typography>
          </Stack>
          <Stack direction='row'>
            {!isReplying && (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box>
                  <Avatar
                    src={user.avatarSrc || ''}
                    alt={user.name?.slice(0, 1)}
                  />
                </Box>
                {videoTimestamp !== null &&
                  variant === COMMENT_VARIANT.TIMESTAMP && (
                    <Typography sx={{ mt: 2, color: '#0074cc' }} align='center'>
                      {formatSeconds(videoTimestamp.toString())}
                    </Typography>
                  )}
              </Box>
            )}
            <Box sx={{ flex: 1, width: '70%', pl: 2 }}>
              <ReactQuill
                modules={modules}
                onChange={setComment}
                ref={quillRef}
                theme='snow'
                value={comment}
              />
              {imagePaths.length > 0 && (
                <ImageCarousel
                  imagePaths={imagePaths}
                  showPreview={showPreview}
                />
              )}
            </Box>
          </Stack>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={
                (!comment || comment === '<p><br></p>') &&
                imagePaths.length == 0
              }
              variant='contained'
              onClick={handleAddComment}
              sx={{ my: 1 }}
            >
              {submitText}
            </Button>
          </Box>
        </Stack>
      </ContainerComponent>
      <Dialog open={isVideoDialogOpen} onClose={handleCloseVideoDialog}>
        <DialogTitle>Add Video Link</DialogTitle>

        <DialogContent sx={{ py: 0, px: 1, width: 500, height: 450 }}>
          <Stack justifyContent='space-between'>
            <TextField
              placeholder='Filter'
              value={videoFilterText}
              onChange={(e) => setVideoFilterText(e.target.value)}
              sx={{ width: '100%', mb: 1 }}
            />
            <Stack sx={{ overflow: 'auto', height: 320 }} spacing={1}>
              {(linkableVideos || [])
                .filter((video) =>
                  video.metadata.title
                    .toLowerCase()
                    .includes(videoFilterText.toLowerCase())
                )
                .map((video, index) => {
                  const onClick = (): void => {
                    handleSetVideoLinkStates(
                      video.id,
                      video.metadata.title,
                      index
                    );
                  };
                  const buttonSx =
                    index === videoSelection
                      ? {
                          borderColor: '#000000',
                          border: 1,
                        }
                      : {};
                  return (
                    <Box key={video.id} onClick={onClick} sx={buttonSx}>
                      <VideoListItem
                        data={video}
                        variant={VIDEO_LIST_TYPE.VIDEO_LINK}
                      />
                    </Box>
                  );
                })}
            </Stack>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: 1,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  onChange={handleIsStartAtCheckedToggle}
                  checked={isStartAtChecked}
                />
                <Typography>Start at</Typography>
                <Box sx={{ width: 60, ml: 1 }}>
                  <TextField
                    InputProps={{
                      inputProps: {
                        style: { textAlign: 'center', padding: 0 },
                      },
                    }}
                    size='small'
                    variant='standard'
                    value={timeText}
                    disabled={!isStartAtChecked}
                    onChange={handleTimeTextChange}
                    onBlur={handleTimeTextOnBlur}
                  />
                </Box>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Button onClick={handleCloseVideoDialog}>Cancel</Button>
                <Button
                  onClick={handleCreateVideoLinkAndClose}
                  disabled={
                    videoIdAndTitle === '' ||
                    (!isTimeWithHours && !isTimeWithoutHours)
                  }
                >
                  Add Link
                </Button>
              </Box>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}

export { CommentForm };
