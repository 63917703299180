import React from 'react';

import { useThumbnail } from 'hooks/useThumbnail';

interface ThumbnailType {
  urlPath?: string;
  width?: number;
}

function Thumbnail({ urlPath = '', width = 200 }: ThumbnailType): JSX.Element {
  const thumbnail = useThumbnail(urlPath, { width });

  return (
    <img
      src={thumbnail.thumbnailSrc}
      width={thumbnail.width}
      height={thumbnail.height}
    />
  );
}

export { Thumbnail };
