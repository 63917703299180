import { OrganizationDocument } from 'store/models/organizations';

export enum USER_ROLE_TYPE {
  MEMBER = 'member',
  PROGRAM_MANAGER = 'program_manager',
}

export interface IUser {
  id: string | null;
  name: string | null;
  email: string | null;
  avatarSrc: string | null;
  organization_id: string;
  department: string;
  occupation: string;
  phoneNumber: string;
  is_admin: boolean | null;
  role: USER_ROLE_TYPE;
  notification_count: number;
}

export interface IState {
  organization: OrganizationDocument;
  authenticated: boolean;
  user: IUser;
}

export const initialState: IState = {
  organization: {
    id: null,
    name: '',
    image_path: null,
    user_count: null,
    categories: [],
    description: '',
  },
  authenticated: false,
  user: {
    id: null,
    name: '',
    email: '',
    avatarSrc: '',
    organization_id: '',
    department: '',
    occupation: '',
    phoneNumber: '',
    is_admin: null,
    role: USER_ROLE_TYPE.MEMBER,
    notification_count: 0,
  },
};
