import { Timestamp } from 'firebase/firestore';

export interface CommentDocument {
  comment: string;
  user_id: string | null;
  userName: string | null;
  userAvatar: string | null;
  id: string;
  parentId: string | null;
  timestamp: Timestamp;
  page?: string;
  image_paths?: string[];
  videoTimestamp: number | null;
  /** backend update */
  child_comment_count: number;
}

export enum COMMENT_VARIANT {
  DEFAULT = 'default',
  SIMPLE = 'simple',
  TIMESTAMP = 'timestamp',
}
