import {
  Container,
  Box,
  Card,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Chip,
  Stack,
  ButtonBase,
} from '@mui/material';
import { doc, updateDoc, query, collection, where } from 'firebase/firestore';
import React, { useState } from 'react';
import {
  useCollectionData,
  useDocumentDataOnce,
} from 'react-firebase-hooks/firestore';
import { Link, Navigate } from 'react-router-dom';

import { BACKGROUND_COLOR_GREY } from 'constants/styles';
import { useStore } from 'hooks/useStore';
import { useFirebase } from 'hooks/useFirebase';
import { useInviteUser } from 'hooks/useInviteUser';
import { UpdateFieldList } from 'components/UpdateFieldList';
import { OrganizationDocument } from 'store/models/organizations';
import { useThumbnail } from 'hooks/useThumbnail';

// mini highlights form to upload
// upload happens instantly, it is shaded when it's processing
// list of highlights is constant query
function EditOrganizationDetail(): JSX.Element {
  const {
    state: { user, organization },
  } = useStore();
  const { db } = useFirebase();
  const [description, setDescription] = useState(organization.description);

  const logoImage = useThumbnail(organization.image_path || '', {
    height: 100,
  });

  const { InviteUserButtonComponent, InviteUserDialogComponent } =
    useInviteUser();

  const docRef = doc(db, `/organizations/${organization.id || null}`);
  const [
    orgData,
    orgDataLoading,
    orgDataError,
    _orgDataSnapshot,
    orgDataReload,
  ] = useDocumentDataOnce(docRef);

  const [pendingOrgUsers, pendingOrgUsersLoading, pendingOrgUsersError] =
    useCollectionData(
      query(
        collection(db, 'invites'),
        where('organization_id', '==', organization.id)
      )
    );

  const [orgUsers, orgUsersLoading, orgUsersError] = useCollectionData(
    query(
      collection(db, 'users'),
      where('organization_id', '==', organization.id)
    )
  );

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setDescription(e.target.value);
    updateDoc(docRef, {
      description: e.target.value,
    });
  };

  if (user.role !== 'program_manager' && !user.is_admin) {
    return <Navigate replace to='/' />;
  }

  if (pendingOrgUsersLoading || orgUsersLoading || orgDataLoading) {
    return <div>Loading...</div>;
  }
  if (pendingOrgUsersError || orgUsersError || orgDataError) {
    return <div>Error!</div>;
  }

  return (
    <>
      <Box
        sx={{
          background: BACKGROUND_COLOR_GREY,
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Container maxWidth='xl'>
          <Card
            sx={{
              display: 'flex',
              width: '90%',
              pt: 2,
              pb: 2,
              pl: 3,
              pr: 4,
              mt: 2,
              ml: 5,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                background: 'white',
                width: '100%',
                height: 'fit-content',
                pt: 3,
                pr: 2,
                pl: 2,
                pb: 3,
              }}
            >
              <ButtonBase
                component={Link}
                to={`/organizations/${organization.name}/edit/logo`}
              >
                <img
                  alt='company logo'
                  src={logoImage.thumbnailSrc}
                  width={100}
                  height={100}
                />
              </ButtonBase>
              <Stack sx={{ ml: 2, width: '85%' }}>
                <Typography variant='h4' sx={{ color: 'text.primary', mb: 1 }}>
                  {organization.name}
                </Typography>
                <TextField
                  label='Description'
                  fullWidth
                  variant='standard'
                  value={description}
                  onChange={handleDescriptionChange}
                />
                <Typography variant='h6' sx={{ color: 'text.secondary' }}>
                  {`${organization.user_count} Members`}
                </Typography>
              </Stack>
            </Box>
          </Card>
          <Box sx={{ display: 'flex' }}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: 300,
                minHeight: 350,
                maxHeight: 700,
                m: 5,
                float: 'left',
              }}
            >
              <Typography sx={{ textAlign: 'center', my: 2 }}>Users</Typography>
              <Box sx={{ flex: 1, overflow: 'auto' }}>
                <List>
                  {pendingOrgUsers?.map((pendingUser) => {
                    return (
                      <ListItem key={pendingUser.email}>
                        <ListItemAvatar>
                          <Avatar />
                        </ListItemAvatar>
                        <ListItemText
                          primary={pendingUser.name || pendingUser.email}
                        />
                        <Chip
                          label='Invited'
                          color='success'
                          variant='outlined'
                          sx={{ mt: 0.5 }}
                        />
                      </ListItem>
                    );
                  })}
                  {orgUsers?.map((orgUser) => {
                    return (
                      <ListItem key={orgUser.id}>
                        <ListItemAvatar>
                          <Avatar src={orgUser.avatarSrc} />
                        </ListItemAvatar>
                        <ListItemText primary={orgUser.name || orgUser.email} />
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
              <Box sx={{ display: 'flex', m: 2, justifyContent: 'flex-end' }}>
                <InviteUserButtonComponent />
              </Box>
            </Card>
            <UpdateFieldList
              orgData={orgData as OrganizationDocument}
              isWithDelete={true}
              field={'categories'}
              onReload={orgDataReload}
            />
          </Box>
        </Container>
      </Box>
      <InviteUserDialogComponent
        organization={organization as OrganizationDocument}
      />
    </>
  );
}

export { EditOrganizationDetail };
