import { doc } from 'firebase/firestore';
import React from 'react';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { Box, Typography, Stack, Avatar } from '@mui/material';

import { useFirebase } from 'hooks/useFirebase';
import { NotificationDocument } from 'store/models/notifications';
import moment from 'moment';

function NewsfeedNewUserListItem({
  newsfeedItem,
}: {
  newsfeedItem: NotificationDocument;
}): JSX.Element {
  const { db } = useFirebase();

  const [user] = useDocumentDataOnce(doc(db, `/users/${newsfeedItem.user_id}`));

  return (
    <Box sx={{ display: 'flex' }}>
      <Avatar src={user?.avatarSrc || ''} />
      <Stack sx={{ ml: 1 }}>
        <Typography variant='subtitle2'>{`${user?.name} has joined the organization.`}</Typography>
        <Typography
          sx={{ color: 'text.secondary' }}
          variant='caption'
          gutterBottom
        >
          {moment((newsfeedItem.timestamp as any).toDate()).calendar()}
        </Typography>
      </Stack>
    </Box>
  );
}

export { NewsfeedNewUserListItem };
