import React from 'react';
import { collection } from 'firebase/firestore';
import { useCollectionOnce } from 'react-firebase-hooks/firestore';
import { Link, useNavigate } from 'react-router-dom';
import {
  Container,
  Divider,
  Button,
  Box,
  ButtonBase,
  Typography,
  Card,
} from '@mui/material';

import { useFirebase } from 'hooks/useFirebase';
import { OrganizationDocument } from 'store/models/organizations';
import { useThumbnail } from 'hooks/useThumbnail';

function OrganizationListItem({
  name,
  image_path: imagePath,
  user_count: userCount,
}: OrganizationDocument): JSX.Element {
  const { thumbnailSrc } = useThumbnail(imagePath ? imagePath : '', {
    height: 100,
  });

  const image = thumbnailSrc.length > 0 && (
    <img src={thumbnailSrc} height={100} alt='Company logo' />
  );

  return (
    <ButtonBase
      component={Link}
      to={name}
      sx={{ flex: '1 0 auto', maxWidth: 1, display: 'block', m: 4 }}
      focusRipple
    >
      <Card sx={{ display: 'flex', maxHeight: 140, flex: 1, maxWidth: 1 }}>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            my: 2,
            overflow: 'hidden',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {image}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flex: 2,
            m: 2,
            overflow: 'hidden',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant='h6'
            sx={{ flexWrap: 'wrap', p: 2, pt: 0, pb: 0 }}
          >
            {name}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            m: 2,
            overflow: 'hidden',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            noWrap
            sx={{ textOverflow: 'ellipsis', p: 2, pt: 0, pb: 0 }}
          >
            {`${userCount} Members`}
          </Typography>
        </Box>
      </Card>
    </ButtonBase>
  );
}

function OrganizationCollection(): JSX.Element {
  const { db } = useFirebase();

  const [organizations] = useCollectionOnce(collection(db, 'organizations'));

  const navigate = useNavigate();

  const handleCreateClick = (): void => {
    navigate('/admin/dashboard/organizations/create');
  };

  return (
    <Container maxWidth='md'>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 2, pr: 2 }}>
        <Button variant='contained' onClick={handleCreateClick}>
          Add Organization
        </Button>
      </Box>

      <Divider sx={{ m: 2 }} />

      <Box>
        {organizations && (
          <>
            {organizations.docs.map((organizations) => {
              const data = organizations.data();
              return (
                <OrganizationListItem
                  {...(data as OrganizationDocument)}
                  key={organizations.id}
                  id={organizations.id}
                />
              );
            })}
          </>
        )}
      </Box>
    </Container>
  );
}

export { OrganizationCollection };
