import React, { createContext, ReactNode, useContext, useReducer } from 'react';
import { storeReducer } from 'store/app/reducers';
import { IState, initialState } from 'store/app/state';
import { IAction } from 'store/shared/types';

interface IStoreContext {
  dispatch: React.Dispatch<IAction>;
  state: IState;
}

const StoreContext = createContext<IStoreContext>({
  dispatch: () => null,
  state: initialState,
});

interface AuxProps {
  children: ReactNode;
}
function StoreProvider({ children }: AuxProps): JSX.Element {
  const [state, dispatch] = useReducer(storeReducer, initialState);

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
}

function useStore(): IStoreContext {
  const context = useContext<IStoreContext>(StoreContext);

  if (context === undefined) {
    throw new Error('useStore must be used within a StoreProvider');
  }

  return context;
}

export { StoreProvider, useStore };
