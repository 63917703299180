import {
  Container,
  Box,
  Card,
  Button,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  Timestamp,
  serverTimestamp,
} from 'firebase/firestore';
import { uploadBytes, ref, UploadTaskSnapshot } from 'firebase/storage';
import React, { useState } from 'react';
import {
  useCollectionData,
  useDocumentData,
  useDocumentDataOnce,
} from 'react-firebase-hooks/firestore';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { BACKGROUND_COLOR_GREY } from 'constants/styles';
import { useStore } from 'hooks/useStore';
import { useFirebase } from 'hooks/useFirebase';
import {
  TRANSITION_STATE,
  VideoDocument,
  VIDEO_TYPE,
} from 'store/models/videos';
import { useThumbnail } from 'hooks/useThumbnail';
import { UpdateFieldList } from 'components/UpdateFieldList';
import { OrganizationDocument } from 'store/models/organizations';
import { USER_ROLE_TYPE } from 'store/app/state';

import { CreateHighlightCard } from './CreateHighlightCard';
import { SelectThumbnailCard } from './SelectThumbnailCard';

const disabledSx = {
  pointerEvents: 'none',
  opacity: 0.5,
};

function HighlightListItem({
  thumbnail,
  thumbnails = [],
}: VideoDocument): JSX.Element {
  const { thumbnailSrc } = useThumbnail(thumbnail || thumbnails[0]);

  return <img src={thumbnailSrc} width={360} height={200} />;
}

// mini highlights form to upload
// upload happens instantly, it is shaded when it's processing
// list of highlights is constant query
function ProcessVideo(): JSX.Element {
  const {
    state: { user, organization },
  } = useStore();
  const { db, storage } = useFirebase();
  const { videoId } = useParams();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const docRef = user?.organization_id
    ? doc(db, `/organizations/${organization.id}`)
    : undefined;
  const [
    orgData,
    _orgDataLoading,
    _orgDataError,
    _orgDataSnapshot,
    orgDataReload,
  ] = useDocumentDataOnce(docRef);

  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [isProcessLoading, setIsProcessLoading] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const videoRef = doc(db, `/videos/${videoId}`);
  const [video] = useDocumentData(videoRef);

  const { thumbnailSrc } = useThumbnail(
    video?.thumbnail || video?.thumbnails?.[0]
  );

  const [highlights] = useCollectionData(
    collection(db, `/videos/${videoId}/highlights`)
  );

  const handleTransitionVideo = async (): Promise<void> => {
    try {
      await setIsProcessLoading(true);

      await updateDoc(videoRef, {
        ...video,
        metadata: {
          ...(video?.metadata || {}),
          title: title || video?.metadata.title,
          description: description || video?.metadata.description,
        },
        updated_at: serverTimestamp(),
      });
      enqueueSnackbar('Video updated', {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar('Error updating video', {
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        console.log(e.message);
      } else {
        enqueueSnackbar('Unexpected error', {
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        console.log('Unexpected error', e);
      }
    }

    // it processes too fast and we need a transitionary period
    setTimeout(() => {
      setIsProcessLoading(false);
      navigate(-1);
    }, 1000);
  };

  const handleSetThumbnail = async (storagePath: string): Promise<void> => {
    await updateDoc(videoRef, 'thumbnail', storagePath);
  };

  const handleAddAsset = async (
    title: string,
    description: string,
    file: File
  ): Promise<void> => {
    if (!user.id) return;

    try {
      await setIsUploadLoading(true);
      const timestamp = new Date().getTime();
      const storageRef = ref(
        storage,
        `highlights/${videoId}/${timestamp}_${file.name}`
      );

      const snapshot = (await uploadBytes(
        storageRef,
        file
      )) as UploadTaskSnapshot;
      const collectionRef = collection(db, `videos/${videoId}/highlights`);
      const docRef = doc(collectionRef);

      const payload: VideoDocument = {
        id: docRef.id,
        storage_path: snapshot.metadata.fullPath,
        user_id: user.id,
        video_type: VIDEO_TYPE.HIGHLIGHT,
        transition_state: TRANSITION_STATE.COMPLETED,
        metadata: { description, title, categories: [], surgeons: [] },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        format_type: snapshot.metadata.contentType,
        created_at: Timestamp.fromDate(new Date(snapshot.metadata.timeCreated)),
        updated_at: Timestamp.fromDate(new Date(snapshot.metadata.updated)),
      };
      await setDoc(docRef, payload);

      console.log('onSuccess');
    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar('Error adding asset', {
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        console.log(e.message);
      } else {
        enqueueSnackbar('Unexpected error', {
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        console.log('Unexpected error', e);
      }
    }
    setIsUploadLoading(false);
  };

  if (!video) {
    return <>Loading...</>;
  }

  return (
    <Container
      maxWidth='xl'
      sx={
        isProcessLoading
          ? disabledSx
          : { background: BACKGROUND_COLOR_GREY, overflow: 'auto' }
      }
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          pt: 2,
          pr: 2,
        }}
      >
        <Button
          variant='contained'
          onClick={handleTransitionVideo}
          disabled={isUploadLoading}
        >
          UPDATE
        </Button>
      </Box>

      <Divider sx={{ m: 2 }} />

      <Card
        sx={{
          display: 'flex',
          width: '100%',
          pt: 2,
          pb: 2,
          pl: 3,
          pr: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}
        >
          <Typography variant='h5' noWrap sx={{}}>
            <strong>{title || video?.metadata?.title}</strong>
          </Typography>
          <TextField
            label='title'
            defaultValue={video?.metadata?.title}
            margin='normal'
            fullWidth
            onChange={(e) => setTitle(e.target.value)}
          />

          <TextField
            label='description'
            defaultValue={video?.metadata?.description}
            margin='normal'
            multiline
            maxRows={5}
            fullWidth
            onChange={(e) => setDescription(e.target.value)}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ml: 3,
          }}
        >
          <img src={thumbnailSrc} width={300} height={166} alt='image' />
        </Box>
      </Card>

      <Box sx={{ display: 'flex' }}>
        {(user.role === USER_ROLE_TYPE.PROGRAM_MANAGER || user.is_admin) && (
          <SelectThumbnailCard
            thumbnail={video?.thumbnail}
            thumbnails={video?.thumbnails || []}
            onSetThumbnail={handleSetThumbnail}
          />
        )}

        {user.is_admin && (
          <Card
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              minHeight: 200,
              ml: 1.5,
              mt: 3,
              pt: 1.5,
              pb: 2,
              pl: 3,
              pr: 4,
            }}
          >
            <Typography variant='h5' sx={{ mb: 0.5 }}>
              <strong>
                Additional Assets {highlights && `(${highlights.length})`}
              </strong>
            </Typography>

            <Box sx={{ display: 'flex', overflow: 'auto' }}>
              {(highlights || []).map((highlight) => {
                return (
                  <HighlightListItem
                    {...(highlight as VideoDocument)}
                    key={highlight.id}
                    id={highlight.id}
                  />
                );
              })}
            </Box>
          </Card>
        )}
      </Box>
      {user.is_admin && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CreateHighlightCard
            onAddAsset={handleAddAsset}
            isAssetLoading={isUploadLoading}
          />
        </Box>
      )}
      <Box sx={{ display: 'flex' }}>
        <UpdateFieldList
          orgData={orgData as OrganizationDocument}
          isWithDelete={false}
          video={video as VideoDocument}
          videoRef={videoRef}
          field={'categories'}
          onReload={orgDataReload}
        />
        <UpdateFieldList
          orgData={orgData as OrganizationDocument}
          isWithDelete={false}
          video={video as VideoDocument}
          videoRef={videoRef}
          field={'surgeons'}
          onReload={orgDataReload}
        />
      </Box>
    </Container>
  );
}

export { ProcessVideo };
