import { doc, updateDoc } from 'firebase/firestore';
import React, { useState, useEffect } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import {
  Box,
  Typography,
  Button,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Chip,
  TextField,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

import { BACKGROUND_COLOR_GREY } from 'constants/styles';
import { useFirebase } from 'hooks/useFirebase';
import { useStore } from 'hooks/useStore';
import { setUserDataAction } from 'store/actions';
import { IUser } from 'store/app/state';

function UserInfoDetails(): JSX.Element {
  const { db } = useFirebase();
  const {
    state: { user },
    dispatch,
  } = useStore();

  const userRef = doc(db, `/users/${user.id}`);
  const [value, loading] = useDocumentData(userRef);

  const [department, setDepartment] = useState(user.department);
  const [occupation, setOccupation] = useState(user.occupation);
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);
  const [changed, setChanged] = useState(new Set<string>());

  const [readOnly, setReadOnly] = useState(true);
  const onClickEdit = (): void => {
    setReadOnly(!readOnly);
  };

  const inputProps = {
    readOnly: true,
  };

  useEffect(() => {
    if (value) {
      dispatch(setUserDataAction(value as IUser));
    }
  }, [dispatch, value]);

  useEffect(() => {
    setDepartment(user.department);
    setOccupation(user.occupation);
    setPhoneNumber(user.phoneNumber);
  }, [user]);

  if (loading) {
    return <>loading...</>;
  }

  const saveInfoOnClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    onClickEdit();
    const values: {
      [key: string]: string;
    } = {
      department: department,
      occupation: occupation,
      phoneNumber: phoneNumber,
    };
    const updated: {
      [key: string]: string;
    } = {};
    for (const name of Array.from(changed)) {
      updated[name] = values[name];
    }
    updateDoc(userRef, updated);
  };

  const SubHeader = (
    <>
      <Typography>{user.email}</Typography>
      {user.is_admin && (
        <Chip
          label='admin'
          color='success'
          variant='outlined'
          sx={{ mt: 0.5 }}
        />
      )}
    </>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        background: BACKGROUND_COLOR_GREY,
        width: '100%',
        height: '100%',
      }}
    >
      <Card sx={{ width: 500, height: 'fit-content', mt: 2 }}>
        <IconButton
          onClick={onClickEdit}
          sx={{ float: 'right', mt: 0.5, mr: 0.5 }}
        >
          {readOnly ? (
            <EditIcon fontSize='small' />
          ) : (
            <CloseIcon fontSize='small' />
          )}
        </IconButton>

        <CardHeader
          sx={{ pt: 3, pb: 0 }}
          avatar={user.avatarSrc ? <Avatar src={user.avatarSrc} /> : <Avatar />}
          title={<Typography>{user.name}</Typography>}
          subheader={SubHeader}
        />
        <CardContent>
          <Box component='form' noValidate autoComplete='off'>
            <TextField
              disabled={readOnly}
              label='Department'
              value={department}
              margin='normal'
              fullWidth
              InputProps={{ ...(readOnly && inputProps) }}
              onChange={(e) => {
                setChanged((currentValue) =>
                  new Set(currentValue).add('department')
                );
                setDepartment(e.target.value);
              }}
            />
            <br />
            <TextField
              disabled={readOnly}
              label='Occupation'
              value={occupation}
              margin='normal'
              fullWidth
              InputProps={{ ...(readOnly && inputProps) }}
              onChange={(e) => {
                setChanged((currentValue) =>
                  new Set(currentValue).add('occupation')
                );
                setOccupation(e.target.value);
              }}
            />
            <br />
            <TextField
              disabled={readOnly}
              label='Phone Number'
              value={phoneNumber}
              margin='normal'
              fullWidth
              InputProps={{ ...(readOnly && inputProps) }}
              onChange={(e) => {
                setChanged((currentValue) =>
                  new Set(currentValue).add('phoneNumber')
                );
                setPhoneNumber(e.target.value);
              }}
            />
            <br />
            {!readOnly && (
              <Button
                variant='contained'
                onClick={saveInfoOnClick}
                sx={{ float: 'right', mt: 2, mb: 2, mr: 1 }}
              >
                Save
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export { UserInfoDetails };
