import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type InviteUserDialogProps = {
  open: boolean;
  title: string;
  description: string;
  onInvite: (email: string, name: string) => void;
  onClose: () => void;
};

function InviteUserDialog({
  open,
  title,
  description,
  onInvite,
  onClose,
}: InviteUserDialogProps): JSX.Element {
  const [email, setEmail] = useState<string>();
  const [name, setName] = useState<string>();
  const [showAlert, setShowAlert] = useState(false);

  const handleInvite = (): void => {
    if (!email || !name) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
      onInvite(email, name);
      handleClose(); // use handleClose to clear current value before close
    }
  };

  const handleClose = (): void => {
    setEmail(undefined);
    setName(undefined);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        {showAlert && (
          <p style={{ color: 'red' }}>Please enter both email and user name.</p>
        )}
        <TextField
          autoFocus
          margin='dense'
          id='email'
          label='Email Address'
          type='email'
          fullWidth
          variant='standard'
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <TextField
          margin='dense'
          id='name'
          label='Name'
          type='text'
          fullWidth
          variant='standard'
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleInvite}>Invite</Button>
      </DialogActions>
    </Dialog>
  );
}

export { InviteUserDialog };
