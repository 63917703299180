import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    // mode: 'dark',
    primary: {
      main: '#263238',
    },
  },
  // @mui/material/styles/components.d.ts
  components: {
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
    },
  },
});

export { theme };
