import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  List,
  Link,
  ListItem,
  Typography,
  Avatar,
  Stack,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
} from '@mui/material';
import { collection, query, orderBy, deleteDoc, doc } from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.bubble.css';

import { useFirebase } from 'hooks/useFirebase';
import { IUser } from 'store/app/state/index';
import { CommentDocument, COMMENT_VARIANT } from 'store/models/comments';
import { VideoDocument } from 'store/models/videos';
import { formatSeconds } from 'store/shared/formatSeconds';

import { CommentForm } from './CommentForm';
import { ImageCarousel } from './ImageCarousel';

interface CommentType {
  comment: CommentDocument;
  isReplyDisabled?: boolean;
  isTimestampVisible?: boolean;
  mentionableUsers?: IUser[];
  showPreview?: (imageUrl: string) => void;
  videoTimestamp?: number | null;
  variant?: COMMENT_VARIANT;
  linkableVideos?: VideoDocument[] | undefined;
}

function Comment({
  comment,
  isReplyDisabled = false,
  isTimestampVisible = true,
  mentionableUsers,
  showPreview,
  videoTimestamp = null,
  variant = COMMENT_VARIANT.DEFAULT,
  linkableVideos,
}: CommentType): JSX.Element {
  const { db } = useFirebase();
  const quillRef = useRef<ReactQuill>(null);

  const [replyComments, _replyCommentsLoading, _replyCommentsError] =
    useCollectionData(
      query(
        collection(db, 'comments', comment.id, 'comments'),
        orderBy('timestamp', 'asc')
      )
    );
  const [isReplying, setIsReplying] = useState(false);
  const [isDeleteCommentOpen, setIsDeleteCommentOpen] = useState(false);

  const handleClick = (): void => {
    setIsReplying(true);
  };

  const handleSubmit = (): void => {
    setIsReplying(false);
  };

  const handleOpenDeleteComment = (): void => {
    setIsDeleteCommentOpen(true);
  };

  const handleCloseDeleteComment = (): void => {
    setIsDeleteCommentOpen(false);
  };

  const handleDeleteComment = async (): Promise<void> => {
    if (comment.parentId)
      await deleteDoc(
        doc(db, 'comments', comment.parentId, 'comments', comment.id)
      );
    else await deleteDoc(doc(db, 'comments', comment.id));
    setIsDeleteCommentOpen(false);
  };

  // for readOnly, remove padding styles
  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      editor.root.style.padding = '0';
    }
  }, [quillRef]);

  const replySection = isReplying ? (
    <CommentForm
      containerComponent='Paper'
      containerProps={{ elevation: 0 }}
      isReplying={true}
      linkableVideos={linkableVideos}
      mentionableUsers={mentionableUsers as IUser[]}
      onSubmitCompleted={handleSubmit}
      parentId={comment.id}
      variant={variant}
    />
  ) : (
    <Link onClick={handleClick} style={{ cursor: 'pointer' }}>
      <Typography sx={{ mt: 1, mb: 0 }} variant='subtitle2'>
        Reply
      </Typography>
    </Link>
  );

  return (
    <>
      <Box sx={{ display: 'flex', width: 1 }}>
        <Stack>
          <Avatar src={comment.userAvatar || ''} />
          {isTimestampVisible && videoTimestamp !== null && (
            <Typography sx={{ mt: 1, color: '#0074cc' }} align='center'>
              {formatSeconds(videoTimestamp.toString())}
            </Typography>
          )}
        </Stack>
        <Stack direction='column' sx={{ pl: 1.5, width: 1 }}>
          <Stack direction='row' justifyContent='space-between'>
            <Typography sx={{ flexGrow: 1, pr: 3 }} variant='subtitle2'>
              {comment.userName}
            </Typography>
            <Box
              sx={{ height: 20 }}
              style={{ cursor: 'pointer' }}
              onClick={handleOpenDeleteComment}
            >
              <ClearIcon sx={{ fontSize: 20 }} />
            </Box>
          </Stack>
          <Stack direction='column' sx={{ width: 1 }}>
            <ReactQuill
              readOnly
              ref={quillRef}
              theme='bubble'
              value={comment?.comment}
            />
            {(comment.image_paths || []).length > 0 && (
              <ImageCarousel
                imagePaths={comment.image_paths || []}
                showPreview={showPreview}
              />
            )}
            <Typography
              sx={{ color: 'text.secondary' }}
              variant='caption'
              gutterBottom
            >
              {moment(comment.timestamp.toDate()).calendar()}
            </Typography>
            <List sx={{ translate: '-20px' }} disablePadding>
              {replyComments?.map((replyComment) => (
                <ListItem key={replyComment.id} sx={{ px: 0, pt: 2 }}>
                  <Comment
                    comment={replyComment as CommentDocument}
                    mentionableUsers={mentionableUsers as IUser[]}
                    showPreview={showPreview}
                  />
                </ListItem>
              ))}
            </List>
            <Box sx={{ width: 1 }}>
              {!isReplyDisabled && !comment.parentId && replySection}
            </Box>
          </Stack>
        </Stack>
      </Box>
      <Dialog open={isDeleteCommentOpen} onClose={handleCloseDeleteComment}>
        <DialogContent>
          Are you sure you want to delete this comment?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteComment}>Cancel</Button>
          <Button onClick={handleDeleteComment}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export { Comment };
