import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { Button } from '@mui/material';

import React, { useState } from 'react';

import { InviteUserDialog } from 'components/InviteUserDialog';
import { useFirebase } from 'hooks/useFirebase';
import { InviteDocument } from 'store/models/invites';
import { OrganizationDocument } from 'store/models/organizations';
import { useStore } from 'hooks/useStore';
import { useSnackbar } from 'notistack';

interface UseInviteUserType {
  InviteUserButtonComponent: () => JSX.Element;
  InviteUserDialogComponent: (
    props: InviteUserDialogComponentType
  ) => JSX.Element;
}

interface InviteUserDialogComponentType {
  organization: OrganizationDocument;
}

function useInviteUser(): UseInviteUserType {
  const {
    state: { user },
  } = useStore();
  const { db } = useFirebase();

  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const handleOpenInviteDialog = (): void => setIsInviteDialogOpen(true);
  const handleCloseInviteDialog = (): void => setIsInviteDialogOpen(false);

  function InviteUserButtonComponent(): JSX.Element {
    return (
      <Button variant='contained' onClick={handleOpenInviteDialog}>
        Invite
      </Button>
    );
  }

  function InviteUserDialogComponent({
    organization,
  }: InviteUserDialogComponentType): JSX.Element {
    const { enqueueSnackbar } = useSnackbar();
    const handleInvite = async (email: string, name: string): Promise<void> => {
      const inviteRef = doc(db, 'invites', email);
      const inviteData: InviteDocument = {
        email,
        name,
        organization_id: organization.id as string,
        inviter_name: user.name,
        created_at: serverTimestamp(),
      };
      await setDoc(inviteRef, inviteData);
      enqueueSnackbar('User invited', {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    };

    return (
      <InviteUserDialog
        open={isInviteDialogOpen}
        title='Send Invite'
        description={`Invite user to ${organization.name}`}
        onClose={handleCloseInviteDialog}
        onInvite={handleInvite}
      />
    );
  }

  return {
    InviteUserButtonComponent,
    InviteUserDialogComponent,
  };
}

export { useInviteUser };
