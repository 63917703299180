import React, { useState } from 'react';
import { VideoDocument } from 'store/models/videos';
import {
  Avatar,
  Box,
  Container,
  Stack,
  TextField,
  Typography,
  Card,
  Button,
} from '@mui/material';
import { serverTimestamp, collection, setDoc, doc } from 'firebase/firestore';

import { SandBox } from 'components/clipping/Sandbox';
import { useStore } from 'hooks/useStore';
import { ClipDocument } from 'store/models/videos';
import { useFirebase } from 'hooks/useFirebase';
import { useSnackbar } from 'notistack';
import { usePlayer } from 'hooks/usePlayer';

interface ClippingType {
  video: VideoDocument;
  videoTimestamp: number;
  onSubmitCompleted: (clip: ClipDocument) => Promise<void>;
}

const ENGAGEMENT_PANEL_SCRIM_INDEX = 600;
const START_RANGE = 30; // seconds

function Clipping({
  video,
  videoTimestamp,
  onSubmitCompleted,
}: ClippingType): JSX.Element {
  const {
    state: { organization, user },
  } = useStore();
  const { db } = useFirebase();
  const { enqueueSnackbar } = useSnackbar();
  const playerContext = usePlayer();

  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [start, setStart] = useState(Math.max(playerContext.playerTime, 0));
  const [end, setEnd] = useState(
    Math.min(
      playerContext.playerTime + START_RANGE,
      Number(video.metadata?.duration)
    )
  );

  const handleChangeText = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.value.length <= 140) {
      setComment(event.target.value);
    }
  };

  const handleSubmitClip = async (): Promise<void> => {
    if (!user.id || !organization.id || !video.id) {
      return;
    }
    try {
      await setIsLoading(true);
      const collectionRef = collection(db, 'clips');
      const docRef = doc(collectionRef);

      const metadata = {
        description: comment,
        startTime: start,
        endTime: end,
      };

      const payload: ClipDocument = {
        id: docRef.id,
        organization_id: organization.id,
        user_id: user.id,
        video_id: video.id,
        metadata,
        is_download_requested: false,
        created_at: serverTimestamp(),
      };

      await setDoc(docRef, payload);
      onSubmitCompleted(payload);
    } catch (e) {
      console.log(e);
      enqueueSnackbar('Error generating clip', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }

    await setIsLoading(false);
  };

  const containerSx = {
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'column',
    maxWidth: 768,
    height: 'fit-content',
    pt: 0.5,
    pb: 0.5,
    pr: 2,
    pl: 2,
    overflow: 'visible',
    zIndex: ENGAGEMENT_PANEL_SCRIM_INDEX + 1,
  };

  const submitText = 'Share Clip';
  const commentHeaderText = 'Create clip';
  const helperText = `${comment.length} / 140`;

  const canRenderSandBox = Boolean(video?.metadata?.duration);

  return (
    <Container component={Card} disableGutters sx={containerSx}>
      <Stack direction='column' spacing={1}>
        <Typography variant='overline'>{commentHeaderText}</Typography>
        <Stack direction='row'>
          <Box>
            <Avatar src={user.avatarSrc || ''} alt={user.name?.slice(0, 1)} />
          </Box>
          <Box sx={{ flex: 1, pl: 2 }}>
            <TextField
              helperText={helperText}
              label='Add a description'
              multiline
              onChange={handleChangeText}
              value={comment}
              sx={{ width: '100%' }}
            />
          </Box>
        </Stack>
        <Box>
          {canRenderSandBox && (
            <SandBox
              end={end}
              previewThumbnails={video.preview_thumbnails}
              setEnd={setEnd}
              setStart={setStart}
              start={start}
              videoDuration={Number(video.metadata?.duration)}
              videoTimestamp={videoTimestamp}
            />
          )}
        </Box>
      </Stack>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          disabled={comment.length === 0 || isLoading}
          variant='contained'
          onClick={handleSubmitClip}
          sx={{ my: 1 }}
        >
          {submitText}
        </Button>
      </Box>
    </Container>
  );
}

export { Clipping };
