import {
  Business,
  ChevronLeft,
  ChevronRight,
  Logout,
  Settings,
} from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import QueueIcon from '@mui/icons-material/Queue';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  ButtonBase,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import {
  BrowserRouter,
  Link,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { useFirebase } from 'hooks/useFirebase';
import { useStore } from 'hooks/useStore';
import { AdminDashboard } from 'pages/Admin/AdminDashboard';
import { CreateOrganization } from 'pages/Admin/Organizations/CreateOrganization';
import { OrganizationCollection } from 'pages/Admin/Organizations/OrganizationCollection';
import { AdminOrganizationDetail } from 'pages/Admin/Organizations/AdminOrganizationDetail';
import { OrganizationDetail } from 'pages/Organization/OrganizationDetail';
import { EditOrganizationDetail } from 'pages/Organization/EditOrganizationDetail';
import { ProcessVideo } from 'pages/Admin/ProcessVideos/ProcessVideo';
import { VideoCollection as AdminVideoCollection } from 'pages/Admin/ProcessVideos/VideoCollection';
import { LoginPage } from 'pages/LoginPage';
import { VideoCollection } from 'pages/Video/VideoCollection';
import { MyVideoCollection } from 'pages/Video/MyVideoCollection';
import { VideoDetail } from 'pages/Video/VideoDetail';
import { VideoUpload } from 'pages/Video/VideoUpload';
import { UserInfoDetails } from 'pages/UserInfo/UserInfoDetails';
import { UserDetail } from 'pages/UserDetail';
import { IUser } from 'store/app/state/index';
import {
  logoutAction,
  setAuthAction,
  setUserDataAction,
  setUserOrgDataAction,
} from 'store/actions';
import { OrganizationDocument } from 'store/models/organizations';
import { useAppBarHeight } from 'hooks/useAppBarHeight';
import { Landing } from 'Landing';
import { CreatePendingVideo } from 'pages/Video/CreatePendingVideo';
import { EditOrganizationLogo } from 'pages/Organization/EditOrganizationLogo';
import { Notifications } from 'components/Notifications';

import './App.css';

const DRAWER_MIN_WIDTH = 80;
const DRAWER_MAX_WIDTH = 240;

interface SideBarType {
  isSidebarExpanded: boolean;
  setIsSidebarExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}
function SideBar({
  isSidebarExpanded,
  setIsSidebarExpanded,
}: SideBarType): JSX.Element {
  const {
    state: { user, organization },
    dispatch,
  } = useStore();
  const { auth } = useFirebase();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const logOutOnClick = async (): Promise<void> => {
    try {
      signOut(auth);
      dispatch(logoutAction());
      navigate('/login');
    } catch (e) {
      enqueueSnackbar('Error signing out', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      console.error('error signing out');
    }
  };

  const toDashboardUrl = user.is_admin ? '/admin/dashboard' : '/';
  const toggleSideBar = (): void => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  const drawerSx = {
    width: DRAWER_MAX_WIDTH,
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: {
      width: DRAWER_MAX_WIDTH,
      boxSizing: 'border-box',
    },
  };
  const listSx = { display: 'flex', justifyContent: 'center' };

  // minimized
  if (!isSidebarExpanded) {
    return (
      <Drawer
        variant='permanent'
        sx={{
          ...drawerSx,
          [`& .MuiDrawer-paper`]: { width: DRAWER_MIN_WIDTH + 1 },
          width: DRAWER_MIN_WIDTH,
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto', flexGrow: 1, width: DRAWER_MIN_WIDTH }}>
          <List>
            {user.is_admin && (
              <Tooltip title='Dashboard'>
                <ListItem
                  button
                  component={Link}
                  divider
                  sx={{ ...listSx, height: 57 }}
                  to={toDashboardUrl}
                >
                  <DashboardIcon />
                </ListItem>
              </Tooltip>
            )}
            <Tooltip title='Organization'>
              <ListItem
                button
                divider
                sx={{ ...listSx, height: 70.1708 }}
                component={Link}
                to={`/organizations/${organization.name}/details`}
              >
                <Business />
              </ListItem>
            </Tooltip>
            <Tooltip title='Video Content'>
              <ListItem
                button
                divider
                sx={{ ...listSx, height: 70.1708 }}
                component={Link}
                to={'/videos'}
              >
                <VideoLibraryIcon />
              </ListItem>
            </Tooltip>
          </List>
          <List>
            <Tooltip title='Logout'>
              <ListItem sx={listSx}>
                <IconButton onClick={logOutOnClick} sx={{ float: 'center' }}>
                  <Logout />
                </IconButton>
              </ListItem>
            </Tooltip>
          </List>
        </Box>
        <Box sx={{ ...listSx, p: 1 }}>
          <Tooltip title='Expand'>
            <IconButton onClick={toggleSideBar} sx={{ float: 'center' }}>
              <ChevronRight />
            </IconButton>
          </Tooltip>
        </Box>
      </Drawer>
    );
  }

  return (
    <Drawer variant='permanent' sx={drawerSx}>
      <Toolbar />
      <Box sx={{ overflow: 'auto', flexGrow: 1 }}>
        <List>
          {user.is_admin && (
            <>
              <ListItem component={Link} disablePadding to={toDashboardUrl}>
                <ListItemButton sx={{ height: 56 }}>
                  <ListItemText
                    primary='Dashboard Overview'
                    primaryTypographyProps={{
                      color: 'rgb(0,0,0)',
                      fontWeight: 'medium',
                      variant: 'body2',
                    }}
                    sx={{ textAlign: 'right' }}
                  />
                </ListItemButton>
                <Tooltip title='Settings'>
                  <IconButton
                    size='large'
                    sx={{
                      '&:after': {
                        content: `''`,
                        position: 'absolute',
                        height: '80%',
                        display: 'block',
                        left: 0,
                        width: '1px',
                        bgcolor: 'divider',
                      },
                    }}
                  >
                    <Settings sx={{ color: 'black' }} />
                  </IconButton>
                </Tooltip>
              </ListItem>
              <Divider />
            </>
          )}
          <ListItem
            button
            disabled={!organization.id}
            divider
            component={Link}
            to={`/organizations/${organization.name}/details`}
          >
            <ListItemText
              primary={'Organization'}
              secondaryTypographyProps={{
                fontSize: 12,
              }}
              secondary={'team members, broadcasts, etc...'}
            />
          </ListItem>
          <ListItem button divider component={Link} to={'/videos'}>
            <ListItemText
              primary={'Video Content'}
              secondaryTypographyProps={{
                fontSize: 12,
              }}
              secondary={'uploads, analysts, metrics, etc...'}
            />
          </ListItem>
        </List>
        <List>
          <ListItem sx={listSx}>
            <Button variant='contained' onClick={logOutOnClick}>
              Logout
            </Button>
          </ListItem>
        </List>
      </Box>
      <Box sx={{ p: 1 }}>
        <Tooltip title='Shrink'>
          <IconButton onClick={toggleSideBar} sx={{ float: 'right' }}>
            <ChevronLeft />
          </IconButton>
        </Tooltip>
      </Box>
    </Drawer>
  );
}

function App(): JSX.Element {
  const {
    state: { authenticated, user, organization },
    dispatch,
  } = useStore();
  const { auth, db } = useFirebase();
  const { appBarHeight } = useAppBarHeight();
  const [isAuthCheckReady, setIsAuthCheckReady] = useState(false);

  const userDoc = authenticated ? doc(db, `/users/${user.id}`) : null;
  const [userData] = useDocumentData(userDoc);
  const orgDoc = userData?.organization_id
    ? doc(db, `/organizations/${userData.organization_id}`)
    : null;
  const [orgData] = useDocumentData(orgDoc);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        dispatch(setAuthAction(true));
        dispatch(
          setUserDataAction({
            id: authUser.uid,
            name: authUser.displayName,
            email: authUser.email,
            avatarSrc: authUser.photoURL,
          })
        );
      }
      setIsAuthCheckReady(true);
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // store updates to retain data across app to prevent requerying.
  useEffect(() => {
    if (userData) {
      // NOTE: It's impossible to get user name from email provider in auth onCreate trigger,
      // so we try to update user name from auth value here if it doesn't have one yet.
      let newUserData = userData;
      if (userDoc && !userData.name) {
        newUserData = { ...userData, name: auth.currentUser?.displayName };
        setDoc(userDoc, newUserData);
      }
      dispatch(setUserDataAction(newUserData as IUser));
    }
    if (orgData) {
      dispatch(setUserOrgDataAction(orgData as OrganizationDocument));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, orgData]); // intended, prevent infinite loop

  if (!isAuthCheckReady) {
    return <>Loading...</>;
  }

  const handleResetOrganization = (): void => {
    dispatch(setUserOrgDataAction(orgData as OrganizationDocument));
  };

  // waiting for data to load. user will always query,
  const isUserDataReady = Boolean(userData && userData.id === user.id);
  const isOrgDataReady = Boolean(userData?.organization_id ? true : !orgData);

  return (
    <BrowserRouter>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100vh',
        }}
      >
        <CssBaseline />
        <AppBar
          position='fixed'
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <Stack direction='row' spacing={1}>
              <ButtonBase component={Link} to={'/'}>
                <Typography variant='h6' noWrap component='div'>
                  FloDri
                </Typography>
              </ButtonBase>
              {user.organization_id &&
                organization.id &&
                user.organization_id !== organization.id && (
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Typography variant='h6'>{organization.name}</Typography>

                    <IconButton
                      aria-label='exit organization'
                      onClick={handleResetOrganization}
                    >
                      <HighlightOffIcon color='success' />
                    </IconButton>
                  </Stack>
                )}
            </Stack>
            <Stack direction='row' spacing={1} sx={{ marginLeft: 'auto' }}>
              {authenticated && (
                <>
                  {userData?.organization_id && (
                    <Tooltip title='Upload'>
                      <IconButton
                        component={Link}
                        color='inherit'
                        aria-label='create'
                        size='large'
                        to='/pending-videos/upload'
                        sx={{ p: 0 }}
                      >
                        <QueueIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Notifications />
                  <IconButton
                    sx={{ p: 0, display: 'flex', marginLeft: 'auto' }}
                  >
                    <Link to='/user-info'>
                      {user.avatarSrc ? (
                        <Avatar src={user.avatarSrc} />
                      ) : (
                        <Avatar />
                      )}
                    </Link>
                  </IconButton>
                </>
              )}
            </Stack>
          </Toolbar>
        </AppBar>

        {authenticated && (
          <SideBar
            isSidebarExpanded={isSidebarExpanded}
            setIsSidebarExpanded={setIsSidebarExpanded}
          />
        )}

        <Box
          sx={{
            width: authenticated
              ? `calc(100% - ${isSidebarExpanded ? '240' : '80'}px)`
              : '100%',
            height: `calc(100vh - ${appBarHeight}px)`,
          }}
        >
          <Toolbar />
          <Routes>
            {authenticated && (
              <>
                <Route
                  path='/admin/dashboard/organizations'
                  element={<OrganizationCollection />}
                />
                <Route
                  path='/admin/dashboard/organizations/create'
                  element={<CreateOrganization />}
                />
                <Route
                  path='/admin/dashboard/pending-videos/:videoId'
                  element={<ProcessVideo />}
                />
                <Route
                  path='/admin/dashboard/pending-videos'
                  element={<AdminVideoCollection />}
                />
                <Route path='/admin/dashboard' element={<AdminDashboard />} />
                <Route path='/user-info' element={<UserInfoDetails />} />
                <Route path='/videos/:videoId' element={<VideoDetail />} />
                <Route
                  path='/admin/dashboard/organizations/:organizationName'
                  element={<AdminOrganizationDetail />}
                />
                <Route
                  path='/organizations/:organizationName/details'
                  element={<OrganizationDetail />}
                />
                <Route
                  path='/organizations/:organizationName/edit'
                  element={<EditOrganizationDetail />}
                />
                <Route
                  path='/organizations/:organizationName/edit/logo'
                  element={<EditOrganizationLogo />}
                />
                <Route
                  path='/user/details/:userName'
                  element={<UserDetail />}
                />
                <Route
                  path='/pending-videos/:pendingVideoId/upload'
                  element={<VideoUpload />}
                />
                <Route
                  path='/pending-videos/upload'
                  element={<CreatePendingVideo />}
                />
                <Route path='/videos' element={<VideoCollection />} />
                <Route
                  path='/videos/my-videos'
                  element={<MyVideoCollection />}
                />
                <Route
                  path='/videos/my-videos/:videoId'
                  element={<ProcessVideo />}
                />
                <Route
                  path='/videos/:videoId/edit'
                  element={<ProcessVideo />}
                />
              </>
            )}
            <Route path='/login' element={<LoginPage />} />
            <Route
              path='*'
              element={
                <Landing
                  isAuthCheckReady={isAuthCheckReady}
                  isUserDataReady={isUserDataReady}
                  isOrgDataReady={isOrgDataReady}
                />
              }
            />
          </Routes>
        </Box>
      </Box>
    </BrowserRouter>
  );
}

export { App };
