import { Box } from '@mui/material';
import React from 'react';

import { useThumbnail } from 'hooks/useThumbnail';

function ImageCarouselImage({ imagePath }: { imagePath: string }): JSX.Element {
  const { thumbnailSrc, height } = useThumbnail(imagePath, {
    height: 100,
  });
  return (
    <Box
      component='img'
      sx={{
        maxWidth: '100%',
        maxHeight: height,
        m: 1,
      }}
      style={{
        cursor: 'pointer',
      }}
      alt='Error loading image'
      src={thumbnailSrc}
    />
  );
}

function ImageCarousel({
  imagePaths,
  showPreview,
}: {
  imagePaths: string[];
  showPreview?: (imageUrl: string) => void;
}): JSX.Element {
  const handleClick = ({ target }: { target: any }): void => {
    if (target.tagName === 'IMG') {
      const imageUrl = target.getAttribute('src');
      showPreview && showPreview(imageUrl);
    }
  };

  return (
    <Box
      className='custom-box'
      sx={{
        width: '100%',
        display: 'flex',
        overflowX: 'auto',
        overflowY: 'hidden',
      }}
      onClick={(event) => handleClick(event)}
    >
      {imagePaths?.map((imagePath, index) => {
        return <ImageCarouselImage imagePath={imagePath} key={index} />;
      })}
    </Box>
  );
}

export { ImageCarousel };
