import ApprovalIcon from '@mui/icons-material/Approval';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Card,
  Checkbox,
  Stack,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
} from '@mui/material';
import {
  collection,
  doc,
  DocumentData,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore';
import {
  useDocumentDataOnce,
  // useCollectionDataOnce,
} from 'react-firebase-hooks/firestore';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { CommentCollection } from 'components/comments/CommentCollection';
import { Clipping } from 'components/clipping/Clipping';
import { useFirebase } from 'hooks/useFirebase';
import { PlayerProvider, usePlayer } from 'hooks/usePlayer';
import { useStore } from 'hooks/useStore';
import { USER_ROLE_TYPE } from 'store/app/state/index';
import { VideoDocument, ClipDocument } from 'store/models/videos';
import { COMMENT_VARIANT, CommentDocument } from 'store/models/comments';

import './reactPlayer.css';
import { VideoPlayer } from './VideoPlayer';
import { ClipDetail } from './ClipDetail';

import './reactPlayer.css';

const ENGAGEMENT_PANEL_SCRIM_INDEX = 600;

const backgroundSx = {
  backgroundColor: 'rgba(0, 0,0, 0.8)',
  zIndex: ENGAGEMENT_PANEL_SCRIM_INDEX,
  width: '100%',
  height: '100vh',
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
};
const commentContainerSx = {
  flex: 1,
  height: 'fit-content',
  pt: 0,
  width: '25%',
  zIndex: ENGAGEMENT_PANEL_SCRIM_INDEX + 1,
};
const containerSx = {
  width: '90%',
  height: 'inherit',
  mt: 5,
  ml: 'auto',
  mr: 'auto',
  display: 'flex',
};
const contentContainerSx = {
  display: 'flex',
  flexDirection: 'column',
  width: '70%',
  maxWidth: 1280,
  pr: 1,
};
const playerContainerSx = {
  position: 'relative',
  maxWidth: 1280,
  maxHeight: 760,
  height: 'fit-content',
  zIndex: ENGAGEMENT_PANEL_SCRIM_INDEX + 1,
};

/** container so we can inject PlayerContext */
function VideoDetailContainer(): JSX.Element {
  return (
    <PlayerProvider>
      <VideoDetail />
    </PlayerProvider>
  );
}

function VideoDetail(): JSX.Element {
  const {
    state: { user },
  } = useStore();
  const { videoId } = useParams();
  const { db, storage } = useFirebase();
  const navigate = useNavigate();
  const [video] = useDocumentDataOnce(doc(db, `/videos/${videoId}`));
  const { enqueueSnackbar } = useSnackbar();

  const [searchParams] = useSearchParams();
  const initialTime = Number(searchParams.get('t')) || 0;

  const videoContext = usePlayer();
  const [userData] = useDocumentDataOnce(
    video?.user_id && doc(db, `users/${video?.user_id}`)
  );

  const [selectedClipId, setSelectedClipId] = useState(searchParams.get('c'));
  const [selectedClip] = useDocumentData(
    selectedClipId ? doc(db, `clips/${selectedClipId}`) : null
  );
  const [comments] = useCollectionData(
    query(
      collection(db, 'comments'),
      where('parentId', '==', null),
      where('page', '==', `videos/${videoId}`),
      orderBy('timestamp', 'desc')
    )
  );
  const bookmarks = useMemo(
    () =>
      ((comments || []) as CommentDocument[])
        .filter(({ videoTimestamp }) => videoTimestamp)
        .map((bookmark) => ({
          timestamp: Number(bookmark.videoTimestamp),
          comment: bookmark.comment,
          userName: `${bookmark.userName}`,
        })),
    [comments]
  );

  const [isClipModalOpen, setIsClipModalOpen] = useState(false);

  /* downloadUrl for video */
  const [url, setUrl] = useState('');

  const [isClippingMode, setIsClippingMode] = useState(false);
  const [commentMode, setCommentMode] = useState<COMMENT_VARIANT>(
    COMMENT_VARIANT.SIMPLE
  );
  const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState(false);
  const [isArchiveVideoDialogOpen, setIsArchiveVideoDialogOpen] =
    useState(false);
  const [isArchiveAgreementChecked, setIsArchiveAgreementChecked] =
    useState(false);

  // utility function
  const getVideoUrl = useCallback(
    async (document: VideoDocument) => {
      const storageRef = await ref(storage, document.storage_path);
      const downloadUrl = await getDownloadURL(storageRef);

      return downloadUrl;
    },
    [storage]
  );

  // on video doc loaded, get downloadable url to render video
  useEffect(
    function fetchUrl() {
      async function fetchDownloadableUrl(video: DocumentData): Promise<void> {
        const downloadUrl = await getVideoUrl(video as VideoDocument);
        setUrl(downloadUrl);
      }
      video && fetchDownloadableUrl(video);
    },
    [getVideoUrl, video]
  );

  /** if download is requested, must be succeeded */
  useEffect(
    function () {
      async function fetchDownload(path: string): Promise<void> {
        try {
          const storageRef = await ref(storage, path);
          const downloadUrl = await getDownloadURL(storageRef);
          const response = await fetch(downloadUrl);
          const blob = await response.blob();
          const url = URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', storageRef.name);
          link.setAttribute('target', '_self');
          link.click();

          const docRef = doc(db, `clips/${selectedClipId}`);
          await setDoc(
            docRef,
            { is_download_requested: false },
            { merge: true }
          );
          enqueueSnackbar('Download Started', {
            variant: 'success',
            anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
          });
        } catch (e) {
          enqueueSnackbar('Error Downloading... Try Again', {
            variant: 'error',
            anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
          });
        }
      }

      if (
        selectedClip &&
        selectedClip.is_download_requested &&
        selectedClip.storage_path
      ) {
        console.log('selectedClip.storagePath: ', selectedClip.storage_path);
        fetchDownload(selectedClip.storage_path);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedClip, selectedClipId]
  );

  if (!url) {
    return <>...Loading</>;
  }

  // custom action bar related to comments/video
  const handleUnfocusClick = (): void => {
    if (isClipModalOpen) {
      return;
    }
    // in this order because hiding animation
    isClippingMode && setIsClippingMode(false);
    selectedClipId && setSelectedClipId(null);
    setCommentMode(COMMENT_VARIANT.SIMPLE);
  };

  const handleClippingClick = (): void => {
    setIsClippingMode(true);
  };

  const handleTimestampClick = (): void => {
    setCommentMode(
      commentMode !== COMMENT_VARIANT.TIMESTAMP
        ? COMMENT_VARIANT.TIMESTAMP
        : COMMENT_VARIANT.SIMPLE
    );
  };

  const handleSubmitClipCompleted = async (
    clip: ClipDocument
  ): Promise<void> => {
    await Promise.all([
      setSelectedClipId(clip.id),
      setIsClippingMode(false),
      setIsClipModalOpen(true),
    ]);
  };

  const handleCloseClipModal = (): void => {
    setIsClipModalOpen(false);
  };

  const handleWatchFullVideo = (): void => {
    setSelectedClipId(null);
  };

  const handleDownloadVideo = async (): Promise<void> => {
    const docRef = doc(db, `clips/${selectedClipId}`);
    await setDoc(docRef, { is_download_requested: true }, { merge: true });
  };

  const selectedClipUrl =
    video &&
    selectedClipId &&
    `${process.env.REACT_APP_PLATFORM_URL}/videos/${video.id}?c=${selectedClipId}`;
  const handleCopyToClipBoard = async (): Promise<void> => {
    try {
      await navigator.clipboard.writeText(selectedClipUrl || '');
      enqueueSnackbar('Link copied to clipboard', {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    } catch (e) {
      enqueueSnackbar('Error. Try again.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
  };

  const isVideoLoadable = !!video;
  if (selectedClipId !== null) {
    isVideoLoadable == isVideoLoadable && selectedClip;
  }

  const handleEditVideoDetailsClick = (): void => {
    navigate(`/videos/${videoId}/edit`);
  };

  const handleMoreOptionsClick = (): void => {
    setIsMoreOptionsOpen(!isMoreOptionsOpen);
  };

  const handleOpenArchiveVideoDialog = (): void => {
    setIsArchiveVideoDialogOpen(true);
    setIsMoreOptionsOpen(false);
  };

  const handleArchiveVideo = (): void => {
    updateDoc(doc(db, `/videos/${videoId}`), {
      is_archived: true,
    });
    setIsArchiveVideoDialogOpen(false);
    navigate('/videos');
  };

  const handleCloseArchiveVideoDialog = (): void => {
    setIsArchiveVideoDialogOpen(false);
    setIsArchiveAgreementChecked(false);
  };

  const handleIsArchiveAgreementCheckedToggle = (): void => {
    setIsArchiveAgreementChecked(!isArchiveAgreementChecked);
  };

  return (
    <>
      <Box sx={containerSx}>
        {(selectedClip ||
          isClippingMode ||
          COMMENT_VARIANT.TIMESTAMP === commentMode) && (
          <ButtonBase
            disableRipple
            onClick={handleUnfocusClick}
            sx={backgroundSx}
          />
        )}
        <Box sx={contentContainerSx}>
          <Box sx={playerContainerSx}>
            {isVideoLoadable && (
              <VideoPlayer
                bookmarks={bookmarks}
                clip={selectedClip as ClipDocument}
                initialTime={initialTime}
                onWatchFullVideo={handleWatchFullVideo}
                previewThumbnails={video?.preview_thumbnails}
                url={url}
                video={video as VideoDocument}
              />
            )}
          </Box>

          <Box sx={{ pt: 1 }}>
            <Stack direction='row' justifyContent='space-between'>
              <Stack direction='column'>
                <Typography variant='h5'>{video?.metadata?.title}</Typography>
                <Typography
                  sx={{ color: 'text.secondary', mt: 0.5, mb: 1.5 }}
                  gutterBottom
                >
                  {video?.metadata?.description}
                </Typography>
              </Stack>
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Button
                  onClick={handleClippingClick}
                  startIcon={<ContentCutIcon />}
                >
                  Clip
                </Button>
                <Button
                  onClick={handleTimestampClick}
                  startIcon={<ApprovalIcon />}
                >
                  Timestamp
                </Button>
                {(user.id === video?.user_id ||
                  user.role === USER_ROLE_TYPE.PROGRAM_MANAGER ||
                  user.is_admin) && (
                  <>
                    <Button
                      sx={{ ml: 1 }}
                      onClick={handleEditVideoDetailsClick}
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                    <IconButton sx={{ ml: 1 }} onClick={handleMoreOptionsClick}>
                      <MoreVertIcon fontSize='small' />
                    </IconButton>
                    {isMoreOptionsOpen && (
                      <Card
                        sx={{
                          zIndex: 1,
                          overflow: 'visible',
                          position: 'absolute',
                          top: 38,
                          right: 0,
                        }}
                      >
                        <List>
                          <ListItem disablePadding>
                            <ListItemButton
                              onClick={handleOpenArchiveVideoDialog}
                            >
                              <ListItemText primary='Archive Video' />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </Card>
                    )}
                  </>
                )}
              </Box>
            </Stack>

            <Box sx={{ display: 'flex', width: '100%' }}>
              <Box>
                <Avatar
                  alt={userData?.name}
                  src={userData?.photo_url}
                  sx={{ width: 48, height: 48 }}
                />
              </Box>
              <Box
                sx={{
                  pl: 2,
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography sx={{ color: 'text.secondary' }}>
                  {user?.name}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  {moment(video?.created_at.toDate()).calendar()}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Divider sx={{ mt: 2, mb: 2 }} />
        </Box>

        <Box sx={commentContainerSx}>
          {!isClippingMode && !selectedClip && (
            <CommentCollection
              isReplyDisabled
              comments={comments as CommentDocument[]}
              onSeek={videoContext.handleSeekCallback}
              onSubmitCompleted={handleUnfocusClick}
              page={`videos/${videoId}`}
              previewThumbnails={video?.preview_thumbnails}
              variant={commentMode}
              videoAuthorId={video?.user_id}
              videoId={video?.id}
              videoTimestamp={videoContext.playerTime}
              videoTitle={video?.metadata.title}
            />
          )}
          {isClippingMode &&
            !isClipModalOpen &&
            !selectedClip &&
            videoContext.playerRef &&
            video && (
              <Clipping
                video={video as VideoDocument}
                onSubmitCompleted={handleSubmitClipCompleted}
                videoTimestamp={videoContext.playerTime}
              />
            )}
          {selectedClip && (
            <ClipDetail
              clip={selectedClip as ClipDocument}
              onWatchFullVideo={handleWatchFullVideo}
              onDownloadVideo={handleDownloadVideo}
            />
          )}
        </Box>
      </Box>
      <Dialog open={isClipModalOpen} onClose={handleCloseClipModal}>
        <DialogTitle>Share</DialogTitle>
        <DialogContent>
          <Stack
            direction='row'
            justifyContent='space-between'
            sx={{
              backgroundColor: 'rgb(24,24, 24)',
              borderColor: 'rgba(255, 255, 255, 0.1)',
              borderWidth: '1px',
              borderRadius: '2px',
              pt: 1,
              pb: 1,
              pl: 1.5,
              pr: 1.5,
            }}
          >
            <Typography sx={{ color: 'white' }}>{selectedClipUrl}</Typography>
            <Button
              sx={{ color: 'rgb(62, 166, 255)' }}
              onClick={handleCopyToClipBoard}
            >
              COPY
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isArchiveVideoDialogOpen}
        onClose={handleCloseArchiveVideoDialog}
      >
        <DialogTitle>Archive this video?</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              onChange={handleIsArchiveAgreementCheckedToggle}
              checked={isArchiveAgreementChecked}
            />
            <Typography>I am sure that I want to archive this video</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseArchiveVideoDialog}>Close</Button>
          <Button
            onClick={handleArchiveVideo}
            disabled={!isArchiveAgreementChecked}
          >
            Archive
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export { VideoDetailContainer as VideoDetail };
