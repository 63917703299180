export function formatSeconds(input: string): string {
  const seconds = Math.floor(Number(input));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const mDisplay = m < 10 ? `0${m}` : m;
  const sDisplay = s < 10 ? `0${s}` : s;

  if (!h && !m) {
    return `0:${sDisplay}`;
  } else if (!h) {
    return `${m}:${sDisplay}`;
  }
  return `${h}:${mDisplay}:${sDisplay}`;
}
